import { schemaAthos, schemaTalkPush, schemaGreenHouse } from 'schemas';
import { VacancyTypes } from '@lib/vacancies-data';


export const getProviderConfiguration = () => ({
  [VacancyTypes.athos]: {
    schema: schemaAthos,
    filename: process.env.ATHOS_FILE_NAME,
  },
  [VacancyTypes.talkPush]: {
    schema: schemaTalkPush,
    filename: process.env.TALK_PUSH_FILE_NAME,
  },
  [VacancyTypes.greenHouse]: {
    schema: schemaGreenHouse,
    filename: process.env.GREEN_HOUSE_FILE_NAME,
  },
  [VacancyTypes.unknown]: { schema: null, filename: null }
});
