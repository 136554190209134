import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
import { Image as IImage } from '@graphql/types';
import { Image as StorybookImage } from '@components';
import { IImageProps } from '@componentTypes';
import { MOBILE_BREAKPOINT } from '@constants';


type Props = {
  contentData: IImage;
};

const ImageComponent = ({ contentData }: Props) => {
  const {
    image,
    mobileImage,
  } = contentData;

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_BREAKPOINT}px)` });
  const imageData = (isMobile && mobileImage) ? mobileImage : image;
  const imageJSX = imageData && imageData.url ? (
    <Image
      src={imageData?.url || ''}
      alt={imageData?.title || ''}
      layout="fill"
      objectFit="cover"
      loader={({ width }) => `${imageData?.url}?w=${width}&fm=webp`}
      placeholder="blur"
      blurDataURL={`${image?.url}?w=10&q=25`}
    />
  ) : undefined;

  return (
    <StorybookImage
      contentData={contentData as IImageProps}
      imageComponent={imageJSX}
    />
  );
};

export default ImageComponent;
