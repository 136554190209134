/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetHeroTitleQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetHeroTitleQuery = { __typename?: 'Query', heroTitle?: { __typename?: 'HeroTitle', title?: string | null, subtitle?: string | null, textIconPrefixClass?: string | null, backgroundColor?: string | null, button?: { __typename?: 'InternalLinkWithIcon', linkText?: string | null, openLinkInNewBrowserTab?: boolean | null, iconClass?: string | null, style?: string | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, openLinkInNewBrowserTab?: boolean | null, iconClass?: string | null, style?: string | null } | null } | null };


export const GetHeroTitleDocument = gql`
    query getHeroTitle($id: String!, $preview: Boolean) {
  heroTitle(id: $id, preview: $preview) {
    title
    subtitle
    textIconPrefixClass
    backgroundColor
    button {
      ... on UrlWithIcon {
        linkText
        linkUrl
        openLinkInNewBrowserTab
        iconClass
        style
      }
      ... on InternalLinkWithIcon {
        linkText
        openLinkInNewBrowserTab
        iconClass
        style
        linkDestination {
          slug
        }
      }
    }
  }
}
    `;
export type GetHeroTitleQueryResult = Apollo.QueryResult<GetHeroTitleQuery, GetHeroTitleQueryVariables>;