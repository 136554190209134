import React from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import { Video as IVideo } from '@graphql/types';
import styles from './video.module.scss';
import { ComponentHeading } from '@components';


type Props = {
  contentData: IVideo
}

export const getVideoID = (url: string) => {
  if (url.length === 11) return url;

  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : '';
};

export const getListID = (url: string) => {
  const regExp = /[&?]list=([^&]+)/i;
  const match = url.match(regExp);
  return (match && match[1].length) ? match[1] : '';
};

export const Video = ({ contentData }: Props) => {
  const { title, youtubeEmbedUrl } = contentData;
  const videoId = youtubeEmbedUrl ? getVideoID(youtubeEmbedUrl) : '';
  const listId = youtubeEmbedUrl ? getListID(youtubeEmbedUrl) : '';

  const opts: YouTubeProps['opts'] = {
    height: '100%',
    width: '100%',
    host: 'https://www.youtube-nocookie.com',
  };

  if (listId.length) {
    opts.playerVars = {
      listType: 'playlist',
      list: listId,
    };
  }

  return (
    <div className={[
      styles['text--dark'],
      styles['bg--white'],
    ].join(' ')}>
      <div className={styles.wrapper}>
        {title && (
          <div className={styles.heading__wrapper}>
            <ComponentHeading title={title}/>
          </div>
        )}
        <YouTube
          videoId={videoId}
          id="youtube"
          opts={opts}
          className={styles.video}
        />
      </div>
    </div>
  );
};
