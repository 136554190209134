import { HeroTitle as IHeroTitle, HeroImage as IHeroImage } from 'graphql/types';
import { HeroImage as HeroImageComponent, ApplyButton } from 'components';


type Props = {
  hero: IHeroTitle;
  buttonComponent?: JSX.Element;
  mainHeader?: boolean;
}

export const HeroTitle = ({
  hero,
  buttonComponent,
  mainHeader
}: Props) => {
  const heroButton = hero?.button;
  const defaultButtonComponent = heroButton ? (
    <ApplyButton
      button={heroButton}
    />
  ) : undefined;

  return (
    <HeroImageComponent
      hero={hero as IHeroImage}
      buttonComponent={buttonComponent ? buttonComponent : defaultButtonComponent}
      mainHeader={mainHeader}
      backgroundColor={hero?.backgroundColor?.toLowerCase()}
    />
  );
};
