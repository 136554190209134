import React, { useMemo }  from 'react';
import Image from 'next/image';
import {
  ImageWrapper as ImageComponent,
  HeroCarousel,
  HeroImageWrapper,
  CardGrid,
  TextImage2Column,
  TextBlock,
  TextOnly2Column,
  Accordion,
  Video,
  Map,
  HeroVideo,
  BlogGrid
} from '@components';
import { IBlogCard, ITextBlock } from '@componentTypes';
import {
  InteractiveMap as IInteractiveMap,
  Accordion as IAccordion,
  CardGrid as ICardGrid,
  HeroCarousel as IHeroCarousel,
  HeroImage as IHeroImage,
  Image as IImage,
  TextImage2Column as ITextImage2Column,
  TextOnly2Column as ITextOnly2Column,
  Video as IVideo,
  HeroVideo as IHeroVideo,
  BlogGrid as IBlogGrid,
  HeroTitle as IHeroTitle
} from '@graphql/types';
import { IContentComponentFunction } from './interfaces';
import { filterDraftItems } from '@utils';
import { HeroTitle } from 'components/molecules/HeroTitle';


export const contentComponents: Record<string, IContentComponentFunction> = {
  'Text': (item, idx) => (
    <TextBlock key={idx} contentData={item as ITextBlock} />
  ),
  'TextOnly2Column': (item, idx) => {
    const textOnly2Column = item as ITextOnly2Column;
    return <TextOnly2Column
      key={idx}
      textLeft={textOnly2Column.textLeft as ITextBlock}
      textRight={textOnly2Column.textRight as ITextBlock}
    />;
  },
  'TextImage2Column': (item, idx) => {
    const { text, image, imagePosition, socialBlock } = item as ITextImage2Column;
    const ImgComponent = image && image.url ? (<Image
      data-testid="image"
      src={image?.url || ''}
      alt={image?.title || ''}
      layout="fill"
      objectFit="cover"
      loader={({ width }) => `${image?.url}?w=${width}&fm=webp`}
      sizes="(min-width: 768px) 50vw, 100vw"
      placeholder="blur"
      blurDataURL={`${image?.url}?w=10&q=25`}
    />) : null;

    return <TextImage2Column
      key={idx}
      text={text as ITextBlock}
      image={ImgComponent || ''}
      imagePosition={imagePosition || ''}
      socialBlock={socialBlock}
    />;
  },
  'Video': (item, idx) => (
    <Video key={idx} contentData={item as IVideo} />
  ),
  'Image': (item, idx) => (
    <ImageComponent key={idx} contentData={item as IImage} />
  ),
  'CardGrid': (item, idx) => {
    const cardGrid = item as ICardGrid;
    const sizes = cardGrid?.numberOfColumns === 2 ? '(min-width: 1024px) 50vw, 100vw' : '(min-width: 1024px) 33vw, 100vw';
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const cardsCollection = useMemo(() => (
      cardGrid.cardsCollection?.items.map((item) => {
        const image = item?.backgroundImage && item?.backgroundImage.url && (
          <Image
            src={item?.backgroundImage?.url || ''}
            alt={item?.backgroundImage?.title || ''}
            layout="fill"
            objectFit="cover"
            loader={({ width }) => `${item?.backgroundImage?.url}?w=${width}&fm=webp`}
            sizes={sizes}
            placeholder="blur"
            blurDataURL={`${item?.backgroundImage?.url}?w=10&q=25`}
          />
        );
        return { ...item, imageComponent: image };
      })),
    [cardGrid.cardsCollection?.items]);

    return <CardGrid
      key={idx}
      numberOfColumns={cardGrid.numberOfColumns || 2}
      cardsCollection={{ items: cardsCollection }}
      title={cardGrid.title}
    />;
  },
  'HeroCarousel': (item, idx, mainHeader) => (
    <HeroCarousel
      key={idx}
      contentData={item as IHeroCarousel}
      mainHeader={mainHeader}
    />
  ),
  'Accordion': (item, idx) => (
    <Accordion key={idx} contentData={item as IAccordion} />
  ),
  'HeroImage': (item, idx, mainHeader) => (
    <HeroImageWrapper
      key={idx}
      hero={item as IHeroImage}
      mainHeader={mainHeader}
    />
  ),
  'InteractiveMap': (item, idx) => {
    const map = item as IInteractiveMap;
    return <Map
      key={idx}
      pins={filterDraftItems(map?.pinsCollection?.items)}
      title={map.title}
      fullWidth={map.fullWidth}
    />;
  },
  'HeroVideo': (item, idx, mainHeader) => (
    <HeroVideo
      key={idx}
      hero={item as IHeroVideo}
      mainHeader={mainHeader}
    />
  ),
  'BlogGrid': (item, idx) => (
    <BlogGrid
      key={idx}
      cards={((item as IBlogGrid)?.cardsCollection?.items || []) as IBlogCard[]}
    />
  ),
  'HeroTitle': (item, idx, mainHeader) => (
    <HeroTitle
      key={idx}
      hero={item as IHeroTitle}
      mainHeader={mainHeader}
    />
  )
};

export const unknownComponent: IContentComponentFunction = (item, idx) => (
  <div key={idx}> Unknown component {item?.__typename || ''}</div>
);