import { ApolloClient, InMemoryCache } from '@apollo/client';
import { GetEventLandingPagesDocument } from '@graphql/queries/getEventLandingPages.generated';
import { safeQuery } from '@lib/apollo-client';
import { IEventLandingContent } from './interfaces';
import { ALL_CATEGORY, ROWS_PER_PAGE_EVENT } from '@constants';
import { ISlugItem } from '@contentful';
import { convertToSlug, getArrayItemFromEnd } from '@utils';
import { IEventCard } from '@componentTypes';
import { GetEventLandingPageEventsDocument } from '@graphql/queries/getEventLandingPageEvents.generated';


export const getEventLandingPagesContent = async (
  client: ApolloClient<InMemoryCache>,
  preview: boolean,
  env?: string
): Promise<IEventLandingContent[]> => {
  const { data, error } = await safeQuery(client, GetEventLandingPagesDocument, {
    preview
  }, env);

  if (error) {
    return [];
  }

  return (
    await Promise.all(
      data.eventLandingPageCollection.items.map(async (page: any) => {
        if (!page?.sys?.id) {
          return null;
        }

        const { data: eventPageData, error: eventPageError } = await safeQuery(
          client,
          GetEventLandingPageEventsDocument,
          {
            id: page.sys.id,
            preview
          },
          env
        );

        if (eventPageError) {
          return null;
        }

        return eventPageData.eventLandingPage;
      })
    )
  ).filter((blogPage) => !!blogPage) as IEventLandingContent[];
};

export const calculateEventLandingPagesTotal = (upcomingTotal: number, pastTotal: number) => {
  return Math.ceil((upcomingTotal + pastTotal / 3) / ROWS_PER_PAGE_EVENT);
};

export const getEventLandingPageSlug = (slugs: ISlugItem[], id: string, pageSlug: string[]) => {
  const landingPageSlugData = slugs.find((slugData: ISlugItem) => (
    slugData.id === id &&
    getArrayItemFromEnd(slugData.params.slug, 1) === getArrayItemFromEnd(pageSlug, 1) &&
    getArrayItemFromEnd(slugData.params.slug, 2) === getArrayItemFromEnd(pageSlug, 2) //additional check for pages with topic, so that they will not be mistaken for base blog landing
  ));

  if (!landingPageSlugData) {
    return [];
  }

  return landingPageSlugData.params.slug;
};

export const getLandingEventPageNumber = (slug: string[]) => {
  const suffix = parseInt(getArrayItemFromEnd(slug, 1) || ''); //get last slug part to check if its a number
  if (isNaN(suffix)) {
    return 1;
  }

  return suffix;
};

export const sortEventCards = (cards: IEventCard[]) => {
  cards.sort((a, b) => {
    return (a.startDate || '') < (b.startDate || '') ? 1 :
      ((a.startDate || '') > (b.startDate || '') ? -1 : 0);
  });

  return cards;
};

export const prepareEventCardPagination = (
  page: number,
  cards: IEventCard[],
  featured: IEventCard[]
) => {
  const upcomingWithPagination: IEventCard[] = [];
  const pastWithPagination: IEventCard[] = [];
  const perPage = ROWS_PER_PAGE_EVENT * 3;
  let paginationStart = (page - 1) * perPage;
  let paginationEnd = page * perPage;
  let currentPosition = 0;

  const maxFeaturedPosition = featured.length * 3;
  if (paginationStart <= maxFeaturedPosition) {
    featured.forEach((card) => {
      if (currentPosition < paginationStart) {
        currentPosition += 3;
        return;
      }

      if (currentPosition + 3 <= paginationEnd) {
        upcomingWithPagination.push(card);
        currentPosition += 3;
      }
    });
  } else {
    currentPosition = maxFeaturedPosition;
  }

  cards.forEach((card) => {
    if (currentPosition < paginationStart) {
      currentPosition += 1;
      return;
    }

    if (currentPosition + 1 <= paginationEnd) {
      pastWithPagination.push(card);
      currentPosition += 1;
    }
  });

  return {
    upcoming: upcomingWithPagination,
    past: pastWithPagination
  };
};

export const getEventLandingCards = (pageData: any) => {
  const now = new Date();
  const categories: { [key: string]: string[] } = {};
  const past: IEventCard[] = [];
  const upcoming: IEventCard[] = [];
  const landingPageSlug = getEventLandingPageSlug(pageData.slugs, pageData.sys.id, pageData.synonymousSlugs || []);
  const pageNumber = getLandingEventPageNumber(landingPageSlug);

  //remove page number from slug if its present
  if (pageNumber > 1) {
    landingPageSlug.splice(-1, 1);
  }

  (pageData.items || []).forEach((event: any) => {
    if (!event) {
      return;
    }
    const eventSlug = pageData.slugs.find((slug: ISlugItem) => (
      slug.contentId === event.sys.id &&
      slug.params.slug.slice(0, -2).every((slugPart: string) => landingPageSlug.includes(slugPart))
    ));
    // Removing cards without pages or cards not connected to this landing page
    if (!eventSlug || eventSlug.params.slug.length === 1) {
      return;
    }

    if (!categories[event.topic]) {
      categories[event.topic] = eventSlug.params.slug.slice(0, -1);
    }

    // Filter for specific topic of events
    if (
      getArrayItemFromEnd(landingPageSlug, 1) !== getArrayItemFromEnd(eventSlug.params.slug, 3) &&
      getArrayItemFromEnd(landingPageSlug, 1) !== convertToSlug(event.topic || '')
    ) {
      return;
    }

    const card: IEventCard = {
      page: {
        slug: `/${eventSlug.params.slug.join('/')}`
      },
      ...(event?.card || {}),
      topic: event.topic,
      startDate: event.startDate,
      hybrid: event.hybrid || false,
      location: event.location,
      locationCity: event.locationCity
    };
    const date = new Date(event.startDate);
    date > now ? upcoming.push(card) : past.push(card);
  });

  const total = calculateEventLandingPagesTotal(upcoming.length, past.length);

  const categoryKeys = Object.keys(categories);
  const filterAll = {
    title: ALL_CATEGORY,
    slug: categoryKeys[0] ? categories[categoryKeys[0]].slice(0, -1) : ['/']
  };

  return {
    data: {
      ...prepareEventCardPagination(pageNumber, sortEventCards(past), sortEventCards(upcoming)),
      filter: [
        filterAll,
        ...categoryKeys.map((key) => ({
          title: key,
          slug: categories[key]
        }))
      ],
      pagination: {
        currentPage: pageNumber,
        total,
        baseSlug: landingPageSlug
      }
    }
  };
};