/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetBlogLandingPageBlogsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetBlogLandingPageBlogsQuery = { __typename?: 'Query', blogLandingPage?: { __typename?: 'BlogLandingPage', feedCard?: { __typename?: 'FeedCard', internalName?: string | null } | null, pagesCollection?: { __typename?: 'BlogLandingPagePagesCollection', items: Array<{ __typename?: 'BlogPage', categoryFeatured?: boolean | null, landingFeatured?: boolean | null, category?: string | null } | null> } | null, sys: { __typename?: 'Sys', id: string } } | null };


export const GetBlogLandingPageBlogsDocument = gql`
    query getBlogLandingPageBlogs($id: String!, $preview: Boolean) {
  blogLandingPage(id: $id, preview: $preview) {
    feedCard {
      internalName
    }
    pagesCollection(limit: 1000) {
      items {
        categoryFeatured
        landingFeatured
        category
      }
    }
    sys {
      id
    }
  }
}
    `;
export type GetBlogLandingPageBlogsQueryResult = Apollo.QueryResult<GetBlogLandingPageBlogsQuery, GetBlogLandingPageBlogsQueryVariables>;