import { getHeader, IContentTypeQuery } from '@contentful';
import { GetErrorPageDocument } from '@graphql/queries/getErrorPage.generated';


export type { IErrorLayout, IErrorSys } from './interfaces';

export const errorPage: IContentTypeQuery = {
  query: GetErrorPageDocument,
  returnParam: 'errorPage',
  type: 'ErrorPage',
  params: {
    headerSys: {
      returnParam: 'header',
      customTransform: async (data: any) => {
        const { error, header } = await getHeader(data.client, data.sys.id, data.preview, data.slugs, data.env);
        return {
          error,
          data: header
        };
      }
    },
    contentSys: {
      returnParam: 'content'
    },
    footerSys: {
      returnParam: 'footer'
    }
  }
};