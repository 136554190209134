export const GOOGLE_CALENDAR_LINK = 'https://calendar.google.com/calendar/render';
export const GOOGLE_CALENDAR_TEXT = 'Google Calendar';
export const OUTLOOK_CALENDAR_LINK = 'https://outlook.live.com/calendar/0/deeplink/compose';
export const OUTLOOK_CALENDAR_TEXT = 'Outlook Live';
export const ICALENDAR_TEXT = 'iCalendar';
export const ICS_FILE_TEXT = `BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
PRODID:Ocado Careers
BEGIN:VEVENT
SUMMARY:__SUMMARY__
DTSTAMP:__DATESTAMP__
DTSTART:__DATESTART__
DTEND:__DATEEND__
LOCATION:__LOCATION__
STATUS:CONFIRMED
BEGIN:VALARM
TRIGGER:-PT10M
DESCRIPTION:__SUMMARY__ Reminder
ACTION:DISPLAY
END:VALARM
UID:__UID__
END:VEVENT
END:VCALENDAR`;