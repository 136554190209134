import * as Sentry from '@sentry/react';


interface ISentryLogger {
    (message?: any, ...optionalParams: any[]): void;
}

interface Logger {
    info: ISentryLogger;
    warn: ISentryLogger;
    error: ISentryLogger;
}

interface ILogger {
    (): Logger;
}

const isSentryEnabled = () => Sentry.getCurrentHub().getClient();

const sentryLogger: ILogger = () => ({
  error: (message: string) => {
    if (isSentryEnabled()) {
      Sentry.captureException(new Error(message));
    }
  },
  warn: (message: string) => {
    if (isSentryEnabled()) {
      Sentry.captureMessage(message);
    }
  },
  info: () => {},
});

export const logger = sentryLogger();
