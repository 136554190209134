import styles from './headerMenu.module.scss';
import { Icon } from '@components';
import { IHeaderLink } from '@contentful';


type Props = {
  data: IHeaderLink;
  className?: any
  onClose: () => void
}

const HeaderMenu = ({ data, className = '', onClose }: Props) => {
  const isNavigationLinksOnly = !(
    (data?.subLinks || [])
      .some((link: IHeaderLink) => link.type !== 'NavigationLink')
  );

  const getLink = (link: IHeaderLink) => {
    if (link.type === 'NavigationLink') {
      const linkStyle = isNavigationLinksOnly ?
        styles.headerMenu__container__links__item__link :
        styles.headerMenu__container__links__item__header;

      return (
        <div key={link.linkText} className={styles.headerMenu__container__links__item}>
          <a
            data-testid="nav-link-2-level"
            className={[linkStyle, styles.link].join(' ')}
            title={link.linkText || ''}
            onClick={onClose}
            href={link?.linkDestination?.slug || '/'}
          >
            {link.linkText || ''}
          </a>
        </div>
      );
    }

    return (
      <div key={link.linkText} className={styles.headerMenu__container__links__item}>
        <div className={[styles.headerMenu__container__links__item__header, styles.link].join(' ')}>
          <a
            title={link.linkText || ''}
            onClick={onClose}
            data-testid="nav-link-2-level"
            href={link?.linkDestination?.slug || '/'}
          >
            {link.linkText || ''}
          </a>
        </div>
        {(link?.subLinks || []).map((item: IHeaderLink) => (
          <div key={item.linkText} className={[styles.headerMenu__container__links__item__link, styles.link].join(' ')}>
            <a
              title={item.linkText || ''}
              onClick={onClose}
              data-testid="nav-link-3-level"
              href={item?.linkDestination?.slug || '/'}
            >
              {item.linkText || ''}
            </a>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={[styles.headerMenu, className].join(' ')}>
      <div className={styles.headerMenu__container}>
        <div className={[styles.headerMenu__container__title, styles.link].join(' ')}>
          {data.linkDestination && (
            <a
              data-testid="nav-link-2-level"
              title={data.linkText || ''}
              onClick={onClose}
              href={data.linkDestination?.slug || '/'}
            >
              {data.linkText || ''}
            </a>
          )}
        </div>
        <div className={styles.headerMenu__container__links}>
          {(data?.subLinks || []).map((item: IHeaderLink) => getLink(item))}
        </div>
        <div className={styles.headerMenu__container__close}>
          <span className={styles.headerMenu__container__close__icon} onClick={onClose}>
            <Icon icon={['fas', 'close']} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu;
