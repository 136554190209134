import React, { useEffect, useState, MouseEvent } from 'react';
import { JobDescriptionProps } from './interfaces';
import { ApplyButton, Content, HeroImage, Map, RichText, GreenhouseForm } from '@components';
import { HeroImage as IHeroImage, HeroImageButton } from '@graphql/types';

import { VacancyTypes } from '@lib/vacancies-data';

import styles from './jobDescription.module.scss';
import {
  addSearchUrlParams,
  combineUrlParams,
  getVacancyTypeById,
  verifyOneTrustCookies,
  stringToHtml,
} from '@utils';
import { useTracking } from 'contexts/TrackingContext';
import useUtmTags from '@hooks/useUtmTags';
import { isEmpty } from 'lodash';
import { IGlobalSettings } from '@contentful';


export const JobDescription = ({ job, id, pageLayout }: JobDescriptionProps) => {
  const { geolocation, title, city, description = '', country, district } = job || {};
  const [latStr, lngStr] = (geolocation || '').split(',');
  const lat = parseFloat(latStr);
  const lng = parseFloat(lngStr);
  const vacancyType = getVacancyTypeById(id);
  const isGreenHouse = vacancyType === VacancyTypes.greenHouse;
  const shouldShowMap = !(isGreenHouse) && lat && lng;

  const [showGreenhouseForm, setShowGreenhouseForm] = useState<boolean>(false);

  const getApplyUrl = () => {
    if (job && !isGreenHouse) {
      return job?.applyUrl || '';
    }

    return '';
  };

  const { heroImage, applyButton, contentTop, contentBottom, companyInformation, globalSettings } = pageLayout;
  const { greenhouseBoardToken = null } = globalSettings as IGlobalSettings;
  const [applyUrl, setApplyUrl] = useState<string>(getApplyUrl());

  const { logEvent } = useTracking();
  const { getItems } = useUtmTags();


  const updateUrl = (oneTrustGroups: string) => {
    // Greenhouse does not use apply url
    if (isGreenHouse) {
      return;
    }

    // Without consent, remove tracking parameters
    if (!verifyOneTrustCookies(oneTrustGroups)) {
      setApplyUrl(getApplyUrl());
      return;
    }

    let params = '';
    const items = getItems();
    const { search } = window.location;
    const urlParams = Object.fromEntries(new URLSearchParams(search));

    let applyButtonUrl = applyUrl;
    const analytics: { [key: string]: string } = {
      ...urlParams,
      ...(typeof items === 'boolean' ? {} : items)
    };

    if (!isEmpty(analytics)) {
      params = combineUrlParams(analytics);
    }

    if (params) {
      applyButtonUrl = addSearchUrlParams(applyUrl, params);
    }

    setApplyUrl(applyButtonUrl);
  };

  const applyListenerToOneTrust = () => {
    if (window.OneTrust) {
      updateUrl(window.OnetrustActiveGroups);
      window.OneTrust.OnConsentChanged(() => {
        updateUrl(window.OnetrustActiveGroups);
      });
      return;
    }

    setTimeout(applyListenerToOneTrust, 200);
  };

  useEffect(() => {
    setTimeout(applyListenerToOneTrust, 200);
  }, []);


  const location = [city, district, country]
    .filter((locationPart) => !!locationPart)
    .join(', ');

  const heroButton = {
    iconClass: !isGreenHouse && heroImage?.button?.iconClass,
    linkText: applyButton,
    linkUrl: applyUrl,
    __typename: 'UrlWithIcon',
    style: heroImage?.button?.style,
    openLinkInNewBrowserTab: true,
  } as HeroImageButton;

  const jobDescriptionHero = {
    button: heroButton,
    image: heroImage?.image,
    imageHeight: heroImage?.imageHeight || 'Medium',
    subtitle: location,
    textIconPrefixClass: heroImage?.textIconPrefixClass,
    title: title
  } as IHeroImage;

  const buttonComponent = heroButton && (
    <ApplyButton
      button={heroButton}
      applyUrl={applyUrl}
      onClick={(event) => onApply(event, 'top')}
      data-testid="job-description-top-button"
    />
  );

  const onApply = (event: MouseEvent, position: string) => {
    if (isGreenHouse) {
      event.preventDefault();
      setShowGreenhouseForm(true);
    }

    logEvent({
      event: 'Vacancy Apply',
      category: 'Job description',
      action: 'apply_button',
      label: position
    });
  };

  // const onLocation = () => {
  //   logEvent({
  //     event: 'Vacancy Location',
  //     category: 'Job description',
  //     action: 'location_click'
  //   });
  // };

  return (
    <div className={styles.jobDescription}>
      <HeroImage
        hero={jobDescriptionHero}
        buttonComponent={buttonComponent as JSX.Element}
        mainHeader={true}
      />
      {contentTop && (
        <Content content={contentTop} />
      )}
      <div className={styles.container}>
        {description && (
          <div
            className={styles.container__description}
            dangerouslySetInnerHTML={{ __html: isGreenHouse ? stringToHtml(description) : description }}
          />
        )}
        {shouldShowMap ? (
          <div className={styles.container__location} data-testid="jobGeolocation">
            <h2>Location</h2>
            <Map
              fullWidth={true}
              directions={true}
              pins={[{
                text: jobDescriptionHero.title,
                lat: lat,
                lng: lng
              }]}
            />
          </div>
        ) : ''}
        {companyInformation && (
          <div className={styles.container__company} data-testid="aboutOcado">
            <h2>{companyInformation.title}</h2>
            <RichText richText={companyInformation.text?.json || ''}/>
          </div>
        )}
        <div className={styles.container__apply}>
          <span className={styles.container__apply__button}>
            <ApplyButton
              button={heroButton}
              theme="secondary"
              applyUrl={applyUrl}
              onClick={(event) => onApply(event, 'bottom')}
              data-testid="job-description-bottom-button"
            />
          </span>
        </div>
      </div>
      {contentBottom && (
        <Content content={contentBottom} />
      )}
      {isGreenHouse && greenhouseBoardToken && (
        <GreenhouseForm
          jobId={id}
          visibility={showGreenhouseForm}
          jobBoardToken={greenhouseBoardToken}
          onClose={() => setShowGreenhouseForm(false)}
        />
      )}
    </div>
  );
};

