import { IPage } from '@componentTypes';

export const FALLBACK_ERROR_PAGE_PROPS = {
  type: 'ErrorPage',
  header: {
    __typename: 'Header',
    logoScreenReaderText: 'Link to home page',
    logoImage: {
      url: 'https://images.ctfassets.net/jqdjbsgbxn3y/1dtuV7bdSNurVlNDJOeuRJ/18527c9fb9c1d187f2f4d1db8f93b9cb/cws-logo-black.svg',
      title: 'Ocado Careers',
      width: 131,
      height: 48,
    },
    skipToMainContentLinkText: 'Skip to main content',
  },
  content: [
    {
      __typename: 'HeroImage',
      title: 'Oh no! 500 Internal Server Error',
      titleColor: 'Dark',
      backgroundColor: 'White',
      subtitle: null,
      textIconPrefixClass: null,
      image: null,
      mobileImage: null,
      imageHeight: 'Medium',
      button: {
        __typename: 'InternalLinkWithIcon',
        linkText: 'Home page',
        openLinkInNewBrowserTab: false,
        iconClass: 'fa-solid fa-house',
        style: 'Secondary',
        linkDestination: { __typename: 'Page', slug: '/' },
      },
    },
  ],
  pageError: null,
  footer: {
    copyrightNotice:
      'Copyright © Ocado Group [CURRENT_YEAR]. All rights reserved.',
    footerLinksCollection: {
      items: [
        {
          linkText: 'Terms and conditions',
          linkUrl: 'https://www.ocadogroup.com/terms-use',
          openLinkInNewBrowserTab: true,
        },
        {
          linkText: 'Privacy policy',
          linkUrl: 'https://www.ocadogroup.com/privacy-policy',
          openLinkInNewBrowserTab: true,
        },
        {
          linkText: 'Contact us',
          linkUrl: 'https://www.ocadogroup.com/media/contact-us',
          openLinkInNewBrowserTab: true,
        },
        {
          linkText: 'Cookie Policy',
          linkUrl: 'https://www.ocadogroup.com/cookie-policy',
          openLinkInNewBrowserTab: true,
        },
      ],
    },
    footerBlocksCollection: {
      items: [
        {
          __typename: 'LogoBlock',
          screenReaderText: 'Link to Ocado careers website',
          image: {
            url: 'https://images.ctfassets.net/jqdjbsgbxn3y/3EbG62IATIqNAzBUYdmR7J/868eb13a7d365a1c83cb34bc5abefe98/Ocado_group_footer.svg',
            title: 'Ocado group footer',
          },
        },
        {
          __typename: 'SocialBlock',
          title: 'Follow our social channels',
          allSocialChannelsLink: {
            linkText: 'All social channels',
            linkUrl: 'https://www.ocadogroup.com/media/social-channels',
            openLinkInNewBrowserTab: true,
          },
          socialLinksCollection: {
            items: [
              {
                __typename: 'UrlWithIcon',
                iconClass: 'fa-brands fa-linkedin',
                linkUrl: 'https://ocadogroup.co/FooterLinkedIn',
                linkText: 'LinkedIn',
                openLinkInNewBrowserTab: true,
              },
              {
                __typename: 'UrlWithIcon',
                iconClass: 'fa-brands fa-facebook-square',
                linkUrl: 'https://ocadogroup.co/FooterFacebook',
                linkText: 'Facebook',
                openLinkInNewBrowserTab: true,
              },
              {
                __typename: 'UrlWithIcon',
                iconClass: 'fa-brands fa-twitter-square',
                linkUrl: 'https://ocadogroup.co/FooterTwitter',
                linkText: 'Twitter',
                openLinkInNewBrowserTab: true,
              },
              {
                __typename: 'UrlWithIcon',
                iconClass: 'fa-brands fa-youtube-square',
                linkUrl: 'https://ocadogroup.co/FooterYouTube',
                linkText: 'YouTube',
                openLinkInNewBrowserTab: true,
              },
            ],
          },
        },
        {
          __typename: 'LinkBlock',
          linkUrl: 'https://www.ocadogroup.com/email-alerts',
          text: 'Enter your email address to receive the latest updates from Ocado Group',
          title: 'Sign up for email alerts',
          linkScreenReaderText: 'Link to sign up for email alerts',
          openLinkInNewBrowserTab: true,
        },
        {
          __typename: 'LinkBlock',
          linkUrl: 'https://www.ocadogroup.com',
          text: 'Ocado Group is a technology-led global software and robotics platform business.',
          title: 'Discover Ocadogroup.com',
          linkScreenReaderText: 'Link to Ocado Group web site',
          openLinkInNewBrowserTab: true,
        },
        {
          __typename: 'LinkBlock',
          linkUrl: 'https://www.ocado.com',
          text: 'In the UK you can shop for groceries online with Ocado.com',
          title: 'Shop with Ocado.com',
          linkScreenReaderText: 'Link to shop with Ocado.com',
          openLinkInNewBrowserTab: true,
        },
      ],
    },
  },
  seo: {
    __typename: 'Seo',
    name: '500 Server Error Page - Compose: SEO',
    title: '500 Internal Server Error',
    description: null,
    keywords: null,
    noIndex: null,
    noFollow: null,
  },
  slug: '500',
} as any as IPage;
