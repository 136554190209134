import React from 'react';
import styles from '@styles/button.module.scss';
import { Icon } from '@components';
import { IButtonProps } from './interfaces';


export const ButtonLink = ({
  theme = 'default',
  label,
  external = false,
  hasIcon = 'none',
  icon = ['fas', 'arrow-right'],
  outline = false,
  large = false,
  className,
  ...props
}: IButtonProps) => {
  const classes = [
    styles.button,
    styles[`button--${theme}`],
    styles[`button--icon-${hasIcon}`],
    styles[outline ? 'button--outline' : ''],
    styles[large ? 'button--large' : ''],
    className,
  ].join(' ').trim();

  const content = (
    <>
      {hasIcon === 'left' && <Icon icon={icon} />}
      {hasIcon !== 'only' && label}
      {(hasIcon === 'right' || hasIcon === 'only') && <Icon icon={icon} />}
    </>
  );

  return (
    <>
      {external ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={classes}
          {...props}>
          {content}
        </a>
      ) : (
        <a className={classes}
          {...props}>
          {content}
        </a>
      )}
    </>
  );
};
