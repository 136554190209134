import { useEffect } from 'react';


export const useClickOutside = (ref: any, callback: any) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const currentRef = ref?.current;
      const target = event.target as HTMLElement;
      if (currentRef && !currentRef.contains(event.target) && target.className !== 'dropdown-link') {
        callback();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', (e) => handleClickOutside(e));
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

};
