export const convertDate = (date: string, params?: any) => {
  return new Date(date).toLocaleDateString([], { day: '2-digit', year: 'numeric', month: 'long', ...params });
};

export const convertDateTime = (date: string, params?: any) => {
  return new Date(date).toLocaleString([], { day: '2-digit', year: 'numeric', month: 'long', ...params });
};

export const convertTime = (date: string, params?: any) => {
  return new Date(date).toLocaleTimeString([], { ...params });
};
