import { NextRouter } from 'next/router';


function getUrlParameter(path: string, name: string) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(path);
  return results ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : '';
}

export function authCheck(router: NextRouter) {
  const accessTokens = process.env.ACCESS_TOKENS?.split(' ') || [];
  const { asPath } = router;

  if (asPath && asPath.includes('404')) {
    return true;
  }

  const token = getUrlParameter(asPath, 'access_token') || localStorage.getItem('access_token') || '';

  if (!accessTokens.length || accessTokens.some((validToken: string) => validToken === token)) {
    if (token) {
      localStorage.setItem('access_token', token);
    }

    return true;
  }

  return false;
}
