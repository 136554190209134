import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';


const sentryDsn = process.env.SENTRY_DSN;
const sentryEnv = process.env.SENTRY_ENVIRONMENT;

sentryDsn && Sentry.init({
  dsn: sentryDsn,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: sentryEnv,
});
