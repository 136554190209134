export const SEARCH_PAGE_SLUG = '/jobs';
export const PAGE_NOT_FOUND_SLUG = '404';
export const INTERNAL_SERVER_ERROR_PAGE_SLUG = '500';
export const PAGE_NOT_FOUND_URL = `/${PAGE_NOT_FOUND_SLUG}`;
export const INTERNAL_SERVER_ERROR_PAGE_URL = `/${INTERNAL_SERVER_ERROR_PAGE_SLUG}`;

export const SEARCH = 'search';
export const SEARCH_LINK = `${process.env.CLOUDSEARCH_API}/${SEARCH}`;

// Suggestions
export const SUGGESTIONS_DISABLED = process.env.SEARCH_SUGGESTIONS_DISABLED === '1';
export const SUGGEST = 'suggest';
export const TITLE = 'title';
export const CITY = 'city';
export const COUNTRY = 'country';
export const AREA = 'area';
export const SUGGEST_LINK = `${process.env.CLOUDSEARCH_API}/${SUGGEST}`;
export const DELAY = 500;

// Sort
export const RELEVANCE = '_score desc';
export const NEWEST = 'date desc';
export const OLDEST = 'date asc';
export const NEAREST = 'distance asc';

// Filter
export const FILTERS_LABEL = 'FILTERS';
export const SORTING_LABEL = 'ORDER BY';
export const NO_RESULTS = 'No results';
export const SEARCH_ERROR = 'There was an error fetching search results, please try again';
export const REMOTE_LOCATION = 'Remote';

// Search params
export const matchallQueryParams = {
  q: 'matchall',
  'q.parser': 'structured'
};

export const defaultSearchSize = 10;

export const searchableIndexes = ['title', 'city', 'country', 'area', 'district'];

// Button Label
export const MORE_DETAILS = 'MORE DETAILS';

// SessionStorage key 
export const SCROLL = 'scroll';
export const SIZE = 'size';

// Browser events name
export const RELOAD = 'reload';
export const NAVIGATION = 'navigation';