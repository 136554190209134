import React from 'react';
import { Icon } from '@components';

import styles from './Loading.module.scss';


const NUM_OF_CIRCLES = 3;

export const Loading = () => {
  const getCircles = () => {
    const circles = [];

    for (let num = 0; num < NUM_OF_CIRCLES; num++) {
      circles.push(
        <Icon key={`loader-${num}`} icon={['fas', 'circle']} />
      );
    }
    return circles;
  };

  return (
    <div className={styles.loading}>{ getCircles() }</div>
  );
};
