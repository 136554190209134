import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button, Icon } from '@components';
import { IEventInfoPanelProps } from './interfaces';
import styles from './eventInfoPanel.module.scss';
import { HYBRID_TEXT, REGISTER } from '@constants';
import { DateRange } from '../DateRange';
import { AddToCalendar } from '../AddToCalendar';

export const EventInfoPanel = ({
  startDate,
  endDate,
  location,
  locationMapUrl,
  hybrid,
  registrationUrl,
  title
}: IEventInfoPanelProps) => {
  return (
    <div className={styles.eventInfoPanel}>
      <div className={styles.container}>
        {startDate && endDate && (
          <span className={[styles.infoBlock, styles.fillMobile].join(' ')}>
            <Icon icon={['fas', 'calendar']} />
            <DateRange
              startDate={startDate}
              endDate={endDate}
            />
          </span>
        )}
        {location && (
          <span className={[styles.infoBlock, styles.location].join(' ')}>
            <Icon icon={['fas', 'location-dot']} />
            {locationMapUrl ? <a href={locationMapUrl} target="_blank"
              rel="noreferrer">{location}</a> : location}
          </span>
        )}
        {hybrid && (
          <span className={[
            styles.infoBlock
          ].join(' ')}>
            <Icon icon={'fa house-building' as IconProp} />
            {HYBRID_TEXT}
          </span>
        )}
        <span
          className={[
            styles.infoBlock,
            styles.right,
            styles.fillMobile
          ].join(' ')}
        >
          {registrationUrl && (
            <span>
              <a
                href={registrationUrl}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  label={REGISTER}
                  theme="secondary"
                  hasIcon="left"
                  icon="ticket"
                  outline
                  className={styles.register}
                />
              </a>
            </span>
          )}
          {startDate && (
            <span className={styles.calendar}>
              <AddToCalendar
                title={title || ''}
                startDate={startDate}
                endDate={endDate}
                location={location}
              />
            </span>
          )}
        </span>
      </div>
    </div>
  );
};