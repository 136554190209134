import {
  ONE_TRUST_ANALYTICS_AND_THIRD_PARTY,
  ONE_TRUST_ANALYTICS_AND_THIRD_PARTY_COOKIES,
  ONE_TRUST_THIRD_PARTY,
  ONE_TRUST_THIRD_PARTY_COOKIES
} from '@constants';

export const verifyOneTrustCookies = (oneTrustGroups: string) => {
  return (
    oneTrustGroups &&
    (
      oneTrustGroups.includes(ONE_TRUST_ANALYTICS_AND_THIRD_PARTY) ||
      oneTrustGroups.includes(ONE_TRUST_THIRD_PARTY) ||
      oneTrustGroups.includes(ONE_TRUST_ANALYTICS_AND_THIRD_PARTY_COOKIES) ||
      oneTrustGroups.includes(ONE_TRUST_THIRD_PARTY_COOKIES)
    )
  );
};