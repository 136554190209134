import Script from 'next/script';
import { ILinkedInTagProps } from './interfaces';


export const LinkedInTag = ({ trackingId }: ILinkedInTagProps) => (
  <>
    <Script
      id="litag-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        _linkedin_partner_id = "${trackingId}";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        `
      }}
    />
    <Script
      id="litag2-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        (function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})(window.lintrk);
        `
      }}
    />
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        alt=""
        src={`https://px.ads.linkedin.com/collect/?pid=${trackingId}&fmt=gif`}
      />
    </noscript>
  </>
);
