import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon } from '@components';
import styles from './chip.module.scss';


interface ChipProps {
  label: string;
  hasIcon?: boolean;
  icon?: IconProp;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Chip = ({
  label,
  hasIcon = false,
  icon = 'circle-xmark',
}: ChipProps) => {
  return (
    <span className={styles.chip} >
      {label}
      {hasIcon && <Icon icon={icon} />}
    </span>
  );
};
