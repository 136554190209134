export const schemaNewRelicVars = {
  'type': 'object',
  'properties': {
    'accountID': { 'type': 'string' },
    'trustKey': { 'type': 'string' },
    'agentID': { 'type': 'string' },
    'licenseKey': { 'type': 'string' },
    'applicationID': { 'type': 'string' }
  },
  'required': [ 'accountID', 'trustKey', 'agentID', 'licenseKey', 'applicationID' ]
};