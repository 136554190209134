import { IGlobalSettings } from '@contentful';
import { ProvidersIntegrationStatus, VacancyTypes } from '@lib/vacancies-data';


export const getProvidersIntegrationStatus = (globalSettings: IGlobalSettings | null, overrides?: any): ProvidersIntegrationStatus => {
  const talkPushEnabled = globalSettings?.talkPushEnabled || false;
  const greenhouseEnabled = globalSettings?.greenhouseEnabled || false;

  const settings = {
    athosEnabled: true, // Always enabled
    talkPushEnabled,
    greenhouseEnabled,
  };

  if (overrides) {
    for (const [provider, isEnabled] of Object.entries(overrides)) {
      // @ts-ignore
      settings[provider] = isEnabled;
    }
  }

  return settings;
};

export const isIntegrationEnabledForType = (jobType: VacancyTypes | string, providersIntegrationStatus: ProvidersIntegrationStatus): boolean => {
  if (jobType === VacancyTypes.athos) {
    return providersIntegrationStatus.athosEnabled;
  }

  if (jobType === VacancyTypes.talkPush) {
    return providersIntegrationStatus.talkPushEnabled;
  }

  if (jobType === VacancyTypes.greenHouse) {
    return providersIntegrationStatus.greenhouseEnabled;
  }

  return false;
}


