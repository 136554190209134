import { ApolloClient, InMemoryCache } from '@apollo/client';
import { safeQuery } from '@lib/apollo-client';
import { GetAssetCollectionDocument } from '@graphql/queries/getAssetCollection.generated';
import { IAssetCollectionResponse } from './interfaces';


export async function getAssetCollection(
  client: ApolloClient<InMemoryCache>,
  ids: string[],
  preview: boolean,
  env?: string,
): Promise<IAssetCollectionResponse> {

  const { data, error } = await safeQuery(client, GetAssetCollectionDocument, {
    ids, preview
  }, env);

  if (error || !data) {
    return {
      items: [],
      error
    };
  }

  return { items: data.assetCollection.items };
}
