export const schemaAthos = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    title: { type: 'string' },
    city: { type: 'string' },
    country: { type: 'string' },
    geolocation: { type: ['string', 'null'] },
    url: { type: 'string' },
    reference: { type: ['string', 'null'] },
    status: { type: ['string', 'null'] },
    type: { type: 'string' },
    contract: { type: ['string', 'null'] },
    shortDescription: { type: ['string', 'null'] },
    contractedHours: { type: ['string', 'null'] },
    salary: { type: ['string', 'null'] },
    video: { type: ['string', 'null'] },
    image: { type: ['string', 'null'] },
    description: { type: 'string' }
  }
};
