import React from 'react';
import Head from 'next/head';

import {
  Content,
  BlogCard,
  BlogGrid
} from '@components';
import { IBlogLandingLayout } from '@contentful';
import styles from './BlogLandingPage.module.scss';
import { IBlogCard } from 'components/molecules/interfaces';
import { Pagination } from 'components/atoms';
import { CategoryFilter } from 'components/atoms/CategoryFilter';


type PageProps = {
  page: IBlogLandingLayout
}

export const BlogLandingPage = ({ page }: PageProps) => {
  const { content, hero, seo, blogs } = page;

  return (
    <div className={styles.blogLandingPage}>
      <Head>
        <title>{seo?.title}</title>
        <meta name="description" content={seo?.description ?? ''} />
      </Head>
      <main id="mainContent">
        {hero && (
          <Content content={[hero]} mainHeader={true} />
        )}
        <CategoryFilter items={blogs.filter} />
        {blogs.featured && blogs.featured.map((card: IBlogCard) => (
          <div
            data-testid="featured"
            key={card.title}
            className={styles.featured}
          >
            <BlogCard card={card} />
          </div>
        ))}
        <BlogGrid cards={blogs.cards}/>
        {blogs.pagination &&
          <Pagination pagination={blogs.pagination} />
        }
        {content && (
          <Content content={content} />
        )}
      </main>
    </div>
  );
};
