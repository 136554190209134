import React, { useEffect, useState } from 'react';
import Script from 'next/script';
import { Modal } from '@components';
import { GH_SCRIPT_URL, GH_ID_PARAM, GH_ID_REGEX } from '@constants';
import styles from './Greenhouse.module.scss';


type Props = {
  jobId: string,
  visibility: boolean,
  jobBoardToken: string | null,
  onClose: Function,
}

const useGreenhouseParams = (id: string) => {
  const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')();

  useEffect(() => {
    if (isBrowser && id) {
      // @ts-ignore
      const Grnhse = window?.Grnhse || { Settings: {} };
      const searchParams = new URLSearchParams(window.location.search);
      const ghId = searchParams.get(GH_ID_PARAM);

      Grnhse.Settings.autoload = false;

      if (!ghId) {
        searchParams.append(GH_ID_PARAM, id.replace(GH_ID_REGEX, ''));
        const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
      }
    }
  }, []);
};

const GreenhouseForm = ({ jobId, visibility, jobBoardToken, onClose }: Props) => {
  useGreenhouseParams(jobId);

  useEffect(() => {
    // @ts-ignore
    const ghIframe = window?.Grnhse?.Iframe;
    jobBoardToken && ghIframe && ghIframe.load();
  }, [visibility]);

  const [shouldShowError, setShouldShowError] = useState(false);
  const GH_SCRIPT_SRC = `${GH_SCRIPT_URL}${jobBoardToken}`;

  return (
    <Modal
      visibility={visibility}
      onClose={() => onClose()}
    >
      <div className={styles.applyModal}>
        <div id="grnhse_app" className={styles.applyForm}>
          {shouldShowError && (
            <p>
              <h2>Oops!</h2>
              There was an error opening your application form.
              Please reload the page and try again.
            </p>
          )}
        </div>
        <Script
          id="grnhse-script"
          strategy="afterInteractive"
          src={GH_SCRIPT_SRC}
          onLoad={() => {
            // @ts-ignore
            if (!window?.Grnhse?.iframe || !jobBoardToken) {
              setShouldShowError(true);
            }
          }}
          onError={() => { setShouldShowError(true); }}
        />
      </div>
    </Modal>
  );
};

export default GreenhouseForm;
