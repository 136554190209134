/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetHeroVideoQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetHeroVideoQuery = { __typename?: 'Query', heroVideo?: { __typename: 'HeroVideo', title?: string | null, iconClass?: string | null, subtitle?: string | null, videoHeight?: string | null, video?: { __typename?: 'Asset', url?: string | null, contentType?: string | null } | null, button?: { __typename?: 'InternalLinkWithIcon', linkText?: string | null, openLinkInNewBrowserTab?: boolean | null, iconClass?: string | null, style?: string | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, openLinkInNewBrowserTab?: boolean | null, iconClass?: string | null, style?: string | null } | null } | null };


export const GetHeroVideoDocument = gql`
    query getHeroVideo($id: String!, $preview: Boolean) {
  heroVideo(id: $id, preview: $preview) {
    __typename
    title
    iconClass
    subtitle
    video {
      url
      contentType
    }
    videoHeight
    button {
      ... on UrlWithIcon {
        linkText
        linkUrl
        openLinkInNewBrowserTab
        iconClass
        style
      }
      ... on InternalLinkWithIcon {
        linkText
        openLinkInNewBrowserTab
        iconClass
        style
        linkDestination {
          slug
        }
      }
    }
  }
}
    `;
export type GetHeroVideoQueryResult = Apollo.QueryResult<GetHeroVideoQuery, GetHeroVideoQueryVariables>;