import React, { useCallback } from 'react';
import { ButtonLink, ComponentHeading } from '@components';
import styles from './cardGrid.module.scss';
import Image from 'next/image';
import { Icon } from 'components/atoms';


type Props = {
  cardsCollection: any,
  numberOfColumns: number,
  title?: string,
}

export const CardGrid = ({ cardsCollection, numberOfColumns, title }: Props) => {
  const { items } = cardsCollection;

  const sliceIntoChunks = useCallback((arr: [], chunkSize: number) => {
    if(!arr) {
      return [];
    }

    const arrLength = arr.length;
    if(arrLength < chunkSize) {
      return [arr];
    }

    const res = [];
    for (let i = 0; i < arrLength; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      if (chunk.length === chunkSize) {
        res.push(chunk);
      }
    }
    return res;
  }, []);

  const cards = sliceIntoChunks(items, numberOfColumns);

  const renderIcon = (card: any) => {
    if (numberOfColumns != 2 || (
      !card.icon && !card.iconClass
    )) {
      return <></>;
    }

    return (
      <div className={styles.icon}>
        {card?.icon ? (
          <Image
            src={card?.icon?.url || ''}
            alt={card?.icon?.title || ''}
            layout="fill"
            objectFit="cover"
            loader={({ width }) => `${card?.icon?.url}?w=${width}&fm=webp`}
            placeholder="blur"
            blurDataURL={`${card?.icon?.url}?w=10&q=25`}
          />
        ) : (
          <Icon icon={card.iconClass} />
        )}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      {title && <ComponentHeading title={title}/>}
      {cards.length && cards.map((item, index) => <div
        key={`card-row-${index}`}
        className={styles.row}
        data-testid="card-grid">
        {item.map((item: any, index: number) =>
          <div
            key={item?.title}
            className={[
              styles.block,
              numberOfColumns === 2 && styles.halfWidth,
              styles[`bg--${item?.backgroundColour?.toLowerCase()}`],
              styles[`text--${item?.textColour?.toLowerCase()}`],
            ].join(' ')}>
            {item?.imageComponent && <div className={styles.image} data-testid={`card-image-${index}`}>
              {item?.imageComponent}
            </div>}
            {renderIcon(item)}
            <div className={styles.block__container}>
              <h3 className={styles.title}>{item?.title}</h3>
              <div className={styles.container}>
                <p className={styles.column}>{item?.text}</p>
                {item?.link && <ButtonLink
                  label={item.link.linkText}
                  theme={item?.textColour === 'Light' ? 'dark' : 'light'}
                  hasIcon="left"
                  external={item.link.openLinkInNewBrowserTab}
                  data-testid={`card-button-${index}`}
                  href={item.link.linkUrl || item.link?.linkDestination?.slug || '/'}
                  className={styles.button}
                  aria-label={`${item.link.linkText} about ${item?.title}`}
                />}
              </div>
            </div>
          </div>
        )}
      </div>)}
    </div>
  );
};
