import { ValidationError } from 'jsonschema';

export type ErrorType = {
  [key: string]: {
    path: any[];
    property: string;
    message: string;
    schema: {
      type: string
    };
    instance: number;
    name: string;
    argument: string[];
    stack: string;
  }
}

export type ValidationSchema = {
  type: string,
  properties: {
    [key: string]: { type: string | string[] }
  }
}

export type ProviderSettings = {
  schema: ValidationSchema,
  filename: string,
};

export enum VacancyTypes {
  athos = 'athos',
  talkPush = 'talkPush',
  greenHouse = 'greenHouse',
  unknown = 'unknown',
}

export type DataResultType = {
  error?: ErrorType | Error | ValidationError[],
  result?: Vacancy[],
}

export interface AthosVacancy {
  id: string;
  title: string;
  city: string;
  country: string;
  district: string;
  geolocation?: string;
  url: string;
  applyUrl: string,
  reference?: string;
  status?: string;
  type: string;
  contract: null | string;
  shortDescription: null | string;
  contractedHours: null | string;
  salary: null | string;
  video: null | string;
  image: null | string;
  description: string;
  area: string;
}

export type AthosVacancyQuestion = {
  id: string;
  value: string;
  type: string;
  required: boolean,
  answer: null | string;
  options: number[],
  comment: null | string;
}

export interface WorkDayVacancy {
  id: string;
  title: string;
  description: string;
  city: string;
  district: string;
  country: string;
  geolocation?: string;
  url: string;
  date: string;
  applyUrl: string;
  company?: string;
  jobPosting?: string;
  department?: string;
  supervisoryOrg?: string;
  updatedDate?: string;
  stream?: string;
  locations?: string;
  companyCode?: string;
  area: string;
}

export interface TalkPushVacancy {
  id: string;
  title: string;
  city: string;
  district: string | null;
  country: string;
  geolocation: string;
  url: string;
  area: string;
  applyUrl: string,
  shortDescription: string | null,
  date: string;
  description: string,
}

export interface GreenHouseVacancy {
  id: string;
  title: string;
  city: string;
  district: string | null;
  country: string;
  geolocation: string | null;
  area: string;
  description: string | null;
  applyUrl: string | null;
  url: string;
}

export type Vacancy = AthosVacancy | WorkDayVacancy | TalkPushVacancy | GreenHouseVacancy;

export interface ProvidersIntegrationStatus {
  athosEnabled: boolean;
  talkPushEnabled: boolean;
  greenhouseEnabled: boolean;
}
