/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetJobSearchResultsFilterAndSortSettingsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetJobSearchResultsFilterAndSortSettingsQuery = { __typename?: 'Query', jobSearchResultsFilterAndSortSettings?: { __typename?: 'JobSearchResultsFilterAndSortSettings', numberOfJobsFoundText?: string | null, filterButtonNumberSelectedText?: string | null, selectedFiltersTitle?: string | null, filtersLabel?: string | null, sortingLabel?: string | null, locationFilter?: { __typename?: 'JobSearchResultsLocationFilterSettings', locationFilterButtonText?: string | null, locationFilterTitle?: string | null, allRegionsTabText?: string | null, remoteTabText?: string | null, countryGroupsCollection?: { __typename?: 'JobSearchResultsLocationFilterSettingsCountryGroupsCollection', items: Array<{ __typename?: 'CountryGroup', name?: string | null, countryList?: Array<string | null> | null, countriesMappingCollection?: { __typename?: 'CountryGroupCountriesMappingCollection', items: Array<{ __typename?: 'MappingCountryName', displayName?: string | null, filterName?: string | null, mappingNames?: Array<string | null> | null } | null> } | null } | null> } | null } | null, areaOfWorkFilter?: { __typename?: 'JobSearchResultsAreaOfWorkFilterSettings', areaFilterButtonText?: string | null, areaFilterTitle?: string | null } | null, sorting?: { __typename?: 'JobSearchResultsSortSettings', relevanceDescendingSortItemText?: string | null, datePostedAscendingSortItemText?: string | null, datePostedDescendingSortItemText?: string | null, distanceClosestSortItemText?: string | null, errorTextWhenUserDeclinesLocationPermission?: string | null } | null } | null };


export const GetJobSearchResultsFilterAndSortSettingsDocument = gql`
    query getJobSearchResultsFilterAndSortSettings($id: String!, $preview: Boolean) {
  jobSearchResultsFilterAndSortSettings(id: $id, preview: $preview) {
    locationFilter {
      locationFilterButtonText
      locationFilterTitle
      allRegionsTabText
      remoteTabText
      countryGroupsCollection {
        items {
          name
          countryList
          countriesMappingCollection {
            items {
              displayName
              filterName
              mappingNames
            }
          }
        }
      }
    }
    areaOfWorkFilter {
      areaFilterButtonText
      areaFilterTitle
    }
    sorting {
      relevanceDescendingSortItemText
      datePostedAscendingSortItemText
      datePostedDescendingSortItemText
      distanceClosestSortItemText
      errorTextWhenUserDeclinesLocationPermission
    }
    numberOfJobsFoundText
    filterButtonNumberSelectedText
    selectedFiltersTitle
    filtersLabel
    sortingLabel
  }
}
    `;
export type GetJobSearchResultsFilterAndSortSettingsQueryResult = Apollo.QueryResult<GetJobSearchResultsFilterAndSortSettingsQuery, GetJobSearchResultsFilterAndSortSettingsQueryVariables>;