import React, { useEffect, useRef } from 'react';

import { SortButton } from '@components';


type SortDropdownProps = {
  selectedSortOption: { title: string, sort: string };
  setIsOpen: (arg0: boolean) => void;
  isOpen: boolean;
  sortButtonItems:{ title: string, sort: string }[];
  handleSort: (sortOption: { title: string, sort: string }) => void;
}

const SortDropdown = ({
  selectedSortOption,
  setIsOpen,
  isOpen,
  sortButtonItems,
  handleSort,
}: SortDropdownProps) => {

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {

      if (isOpen && ref.current && !ref?.current?.contains(e?.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div ref={ref}>
      <SortButton
        selectedSortOption={selectedSortOption}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        sortButtonItems={sortButtonItems}
        handleSort={handleSort}
      />
    </div>
  );
};

export default SortDropdown;