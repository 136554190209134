/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetNavigationLinkWithSubMenuQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetNavigationLinkWithSubMenuQuery = { __typename?: 'Query', navigationLinkWithSubMenu?: { __typename?: 'NavigationLinkWithSubMenu', linkText?: string | null, type: 'NavigationLinkWithSubMenu', linkDestination?: { __typename?: 'Page', slug?: string | null } | null, childLinksCollection?: { __typename?: 'NavigationLinkWithSubMenuChildLinksCollection', items: Array<{ __typename?: 'NavigationLink', linkText?: string | null, type: 'NavigationLink', linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'NavigationLinkGroup', title?: string | null, type: 'NavigationLinkGroup', sys: { __typename?: 'Sys', id: string } } | null> } | null } | null };


export const GetNavigationLinkWithSubMenuDocument = gql`
    query getNavigationLinkWithSubMenu($id: String!) {
  navigationLinkWithSubMenu(id: $id) {
    type: __typename
    linkText
    linkDestination {
      slug
    }
    childLinksCollection {
      items {
        ... on NavigationLink {
          type: __typename
          linkDestination {
            slug
          }
          linkText
        }
        ... on NavigationLinkGroup {
          type: __typename
          title
          sys {
            id
          }
        }
      }
    }
  }
}
    `;
export type GetNavigationLinkWithSubMenuQueryResult = Apollo.QueryResult<GetNavigationLinkWithSubMenuQuery, GetNavigationLinkWithSubMenuQueryVariables>;