/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetBlogLandingPagesQueryVariables = Types.Exact<{
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetBlogLandingPagesQuery = { __typename?: 'Query', blogLandingPageCollection?: { __typename?: 'BlogLandingPageCollection', items: Array<{ __typename?: 'BlogLandingPage', sys: { __typename?: 'Sys', id: string } } | null> } | null };


export const GetBlogLandingPagesDocument = gql`
    query getBlogLandingPages($preview: Boolean) {
  blogLandingPageCollection(preview: $preview) {
    items {
      sys {
        id
      }
    }
  }
}
    `;
export type GetBlogLandingPagesQueryResult = Apollo.QueryResult<GetBlogLandingPagesQuery, GetBlogLandingPagesQueryVariables>;