import { Button } from '@components';
import styles from './toTopButton.module.scss';


export const ToTopButton = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={styles.toTopButton}>
      <Button
        theme={'primary'}
        type="button"
        onClick={()=> scrollToTop()}
        icon={['fas', 'arrow-up-long']}
        large={true}
        hasIcon="only"
        data-testid="ToTopButton"
        label="back to top"
      />
    </div>
  );
};
