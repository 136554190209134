import { useEffect, useState } from 'react';
import { Asset } from '@graphql/types';
import { EMBEDDED_ASSET_BLOCK, fetchAssetsCollection, getItemsFromCollection } from '@contentful';
import { logger } from '@utils';


type ContentProps = Array<{
  nodeType: string;
  data?: {
    target: {
      sys: {
        id: string;
      }
    }
  }
}>


export function usePrefetchAssets(content: ContentProps) {
  const [assets, setAssets] = useState<(Asset|null)[]>([]);

  useEffect(() => {
    async function prefetchAssets() {
      const assetIds = [];

      for (let node of content) {
        if (node.nodeType === EMBEDDED_ASSET_BLOCK) {
          const { id } = node?.data?.target?.sys || {};
          if (id) {
            assetIds.push(id);
          }
        }
      }

      if (assetIds.length > 0) {
        const collection = await fetchAssetsCollection(assetIds);

        if (!collection.error) {
          setAssets(getItemsFromCollection(collection));
        } else {
          logger.warn(`[usePrefetchAssets] Could not fetch assets due to an error: ${JSON.stringify(collection.error)}`);
        }
      }
    }

    prefetchAssets();
  }, []);

  return assets;
}
