import React, { useEffect, useState } from 'react';
import { Button } from '@components';
import { IAddToCalendarProps } from './interfaces';
import styles from './addToCalendar.module.scss';
import {
  ADD_TO_CALENDAR,
  GOOGLE_CALENDAR_LINK,
  GOOGLE_CALENDAR_TEXT,
  ICALENDAR_TEXT,
  ICS_FILE_TEXT,
  /* OUTLOOK_CALENDAR_LINK,
  OUTLOOK_CALENDAR_TEXT */
} from '@constants';
import { Icon } from '../Icon';
import { Dropdown } from '../Dropdown';
import { v4 as uuidv4 } from 'uuid';
import { escapeString } from '@utils';

const getGoogleCalendarLink = (title: string, startDate: string,  endDate?: string, location?: string) => {
  const formattedTitle = encodeURIComponent(title);
  const formattedStartDate = `${(new Date(startDate)).toISOString().replace(/(-|:|\.\d+Z)/g, '')}Z`;
  const formattedEndDate = endDate ? `${(new Date(endDate)).toISOString().replace(/(-|:|\.\d+Z)/g, '')}Z` : formattedStartDate;
  return `${GOOGLE_CALENDAR_LINK}?action=TEMPLATE&text=${formattedTitle}&dates=${formattedStartDate}/${formattedEndDate}&location=${location || ''}`;
};

/* const getOutlookCalendarLink = (title: string, startDate: string, endDate?: string, location?: string) => {
  const formattedStartDate = startDate.replace(/(-|:|\.\d+Z)/g, 'Z');
  const formattedEndDate = endDate ? endDate.replace(/(-|:|\.\d+Z)/g, 'Z') : formattedStartDate;
  return `${OUTLOOK_CALENDAR_LINK}?path=/calendar/action/compose&rru=addevent&startdt=${formattedStartDate}&enddt=${formattedEndDate}&subject=${title}&location=${location || ''}`;
}; */

const getICSFile = (fileUID: string, title: string, startDate: string, endDate?: string, location?: string) => {
  const formattedStartDate = `${(new Date(startDate)).toISOString().replace(/(-|:|\.\d+Z)/g, '')}Z`;
  const formattedEndDate = endDate ? `${(new Date(endDate)).toISOString().replace(/(-|:|\.\d+Z)/g, '')}Z` : formattedStartDate;
  const formattedDateStamp = `${(new Date()).toISOString().replace(/(-|:|\.\d+Z)/g, '')}Z`;
  const fileText = escapeString(
    ICS_FILE_TEXT
      .replace(/__SUMMARY__/g, title)
      .replace('__DATESTAMP__', formattedDateStamp)
      .replace('__DATESTART__', formattedStartDate)
      .replace('__DATEEND__', formattedEndDate)
      .replace('__LOCATION__', location || '')
      .replace('__UID__', fileUID)
  );

  const file = new window.Blob([fileText], { type: 'text/calendar' });
  return URL.createObjectURL(file);
};

export const AddToCalendar = ({
  startDate,
  endDate,
  location,
  title,
}: IAddToCalendarProps) => {
  const [icsFileUrl, setIcsFileUrl] = useState('');
  const [fileUID, setFileUID] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const googleCalendarLink = getGoogleCalendarLink(title, startDate, endDate, location);
  //const outlookCalendarLink = getOutlookCalendarLink(title, startDate, endDate, location);

  useEffect(() => {
    const id = uuidv4();
    setFileUID(id);
    setIcsFileUrl(getICSFile(id, title, startDate, endDate, location));
  }, []);

  return (
    <span
      className={styles.addToCalendar}
    >
      <Button
        label={ADD_TO_CALENDAR}
        theme="default"
        hasIcon="left"
        icon={['fas', 'calendar']}
        outline
        onClick={() => setPopupOpen(!popupOpen)}
      />
      {popupOpen && (
        <Dropdown
          setIsOpen={setPopupOpen}
          className={styles.addToCalendar__popup}
        >
          <a
            href={googleCalendarLink}
            className={styles.addToCalendar__popup__item}
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon={['fab', 'google']} />
            {GOOGLE_CALENDAR_TEXT}
          </a>
          <a
            href={icsFileUrl}
            download={`${fileUID}.ics`}
            target="_blank"
            rel="noreferrer"
            className={styles.addToCalendar__popup__item}
          >
            <Icon icon={['fab', 'apple']} />
            {ICALENDAR_TEXT}
          </a>
          {/* <a
            href={outlookCalendarLink}
            className={styles.addToCalendar__popup__item}
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon={['fab', 'microsoft']} />
            {OUTLOOK_CALENDAR_TEXT}
          </a> */}
        </Dropdown>
      )}
    </span>
  );
};