/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetNavigationLinkGroupQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetNavigationLinkGroupQuery = { __typename?: 'Query', navigationLinkGroup?: { __typename?: 'NavigationLinkGroup', title?: string | null, type: 'NavigationLinkGroup', linkDestination?: { __typename?: 'Page', slug?: string | null } | null, linksCollection?: { __typename?: 'NavigationLinkGroupLinksCollection', items: Array<{ __typename?: 'NavigationLink', linkText?: string | null, type: 'NavigationLink', linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | null> } | null } | null };


export const GetNavigationLinkGroupDocument = gql`
    query getNavigationLinkGroup($id: String!) {
  navigationLinkGroup(id: $id) {
    type: __typename
    title
    linkDestination {
      slug
    }
    linksCollection {
      items {
        ... on NavigationLink {
          type: __typename
          linkDestination {
            slug
          }
          linkText
        }
      }
    }
  }
}
    `;
export type GetNavigationLinkGroupQueryResult = Apollo.QueryResult<GetNavigationLinkGroupQuery, GetNavigationLinkGroupQueryVariables>;