import React from 'react';
import { Button, Dropdown } from '@components';
import styles from './sortButton.module.scss';


type SortButtonProps = {
  selectedSortOption: { title: string, sort: string };
  setIsOpen: (arg0: boolean) => void;
  isOpen: boolean;
  sortButtonItems:{ title: string, sort: string }[];
  handleSort: (sortOption: { title: string, sort: string }) => void;
}

export const SortButton = ({
  selectedSortOption,
  setIsOpen,
  isOpen,
  sortButtonItems,
  handleSort,
}: SortButtonProps) => {

  return (
    <div
      data-testid={'sort-button'}
    >
      <div className={styles.dropdownBlock}>
        <Button
          label={selectedSortOption.title}
          onClick={() => setIsOpen(!isOpen)}
          theme="light"
          outline={true}
          icon={['fas', 'caret-down']}
          hasIcon="right"
          className={styles.sortButton}
          iconColor="#9E9E9E"
          aria-expanded={isOpen}
          aria-controls="dropdown"
          data-testid="sort-button"
        />
        {isOpen && (
          <Dropdown
            items={sortButtonItems}
            onItemSelect={({ title, sort }) => handleSort({ title, sort: sort || '' })}
            setIsOpen={setIsOpen}
          />
        )}
      </div>
    </div>
  );
};