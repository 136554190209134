/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetAssetQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetAssetQuery = { __typename?: 'Query', asset?: { __typename?: 'Asset', title?: string | null, description?: string | null, contentType?: string | null, url?: string | null, width?: number | null, height?: number | null } | null };


export const GetAssetDocument = gql`
    query getAsset($id: String!, $preview: Boolean) {
  asset(id: $id, preview: $preview) {
    title
    description
    contentType
    url
    width
    height
  }
}
    `;
export type GetAssetQueryResult = Apollo.QueryResult<GetAssetQuery, GetAssetQueryVariables>;