import { GoogleMap, InfoWindow, Marker, useJsApiLoader, } from '@react-google-maps/api';
import { useState, useCallback } from 'react';
import styles from './map.module.scss';
import { Icon, ComponentHeading, RichText } from '@components';
import MapMouseEvent = google.maps.MapMouseEvent;
import { useMediaQuery } from 'react-responsive';
import { IPin } from './interfaces';
import { MOBILE_BREAKPOINT } from '@constants';


type Props = {
  pins: IPin[];
  title?: string;
  fullWidth?: boolean
  directions?: boolean
}

const Map = ({ pins, title, fullWidth, directions = false }: Props) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [activeMarker, setActiveMarker] = useState<string>('');
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_BREAKPOINT}px)` });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY || ''
  });

  const onMarkerOver = (e: MapMouseEvent, dirUrl: string) => {
    e.stop();
    setActiveMarker(dirUrl);
    infoWindowRefresh();
  };

  const infoWindowRefresh = () => {
    timer && clearTimeout(timer);
  };

  const onCloseButton = () => {
    setActiveMarker('');
  };

  const onInfoWindowClose = () => {
    setTimer(setTimeout(() => {
      setActiveMarker('');
    }, 500));
  };

  const mapInfoStyles = () => {
    const mapInfoStyles = [styles.info];
    directions && mapInfoStyles.push(styles.padding);
    return mapInfoStyles.join(' ');
  };

  const renderPinText = (text: any) => {
    if (typeof text === 'string') {
      return <h5>{text}</h5>;
    }

    if (typeof text === 'object') {
      return <RichText richText={text?.json || ''} />;
    }

    return false;
  };

  const renderPin = ({ lat, lng, text }: IPin) => {
    if(!lat || !lng) {
      return <></>;
    }

    const point = {
      lat: lat,
      lng: lng
    };

    const dirUrl = `https://www.google.com/maps/dir//${lat},${lng}`;

    return (
      <div data-testid="mapMarker" key={dirUrl}>
        <Marker
          position={point}
          onMouseOver={(e) => onMarkerOver(e, dirUrl)}
          onClick={(e) => isMobile && onMarkerOver(e, dirUrl)}
          onMouseOut={onInfoWindowClose}
        >
          {(text || directions) && activeMarker === dirUrl &&
            <InfoWindow
              position={point}
              onLoad={(infoWindow: any) => (infoWindow.shouldFocus = false)} // infoWindow is injected with shouldFocus property, but IInfoWindow interface does not reflect that
            >
              <div data-testid="mapInfo" className={mapInfoStyles()}>
                <div
                  className={styles.hover}
                  onMouseOver={infoWindowRefresh}
                  onMouseOut={onInfoWindowClose}
                >
                  <span className={styles.close} onClick={onCloseButton}/>
                  {renderPinText(text)}
                </div>
                <div className={styles.invisible_anchor}>
                  {renderPinText(text)}
                </div>
                {directions && (
                  <div className={styles.directions}>
                    <a
                      className={[styles.directions_link, styles.link].join(' ')}
                      href={dirUrl}
                      target="_blank"
                      rel="noreferrer"
                      onMouseOver={infoWindowRefresh}
                      onMouseOut={onInfoWindowClose}
                    >
                      open in Directions
                      <Icon icon={['fas', 'diamond-turn-right']} />
                    </a>
                  </div>
                )}
              </div>
            </InfoWindow>
          }
        </Marker>
      </div>
    );
  };

  const getHeader = () => {
    if(!title) {
      return false;
    }

    return fullWidth ? (
      <div className={styles.wrapper}>
        <ComponentHeading title={title} />
      </div>
    ) : (
      <ComponentHeading title={title} />
    );
  };

  const onLoad = useCallback(function callback(map) {
    if(pins.length <= 1) {
      return;
    }
    const bounds = new window.google.maps.LatLngBounds();
    pins.forEach(({ lat, lng }) => {
      if(lat && lng) {
        const point = new window.google.maps.LatLng(lat, lng);
        bounds.extend(point);
      }
    });

    map.fitBounds(bounds);
  }, []);

  let center;
  if(pins.length === 1) {
    center = {
      lat: pins[0].lat || 0,
      lng: pins[0].lng || 0
    };
  }

  return isLoaded ? (
    <div data-testid="mapGoogle" className={fullWidth ? undefined : styles.wrapper}>
      {getHeader()}
      <GoogleMap
        mapContainerClassName={styles.map}
        onLoad={onLoad}
        center={center}
        zoom={18}
      >
        {pins.map(renderPin)}
      </GoogleMap>
    </div>
  ) : <></>;
};

export default Map;
