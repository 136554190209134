import React from 'react';
import { HeroCarousel as IHeroCarousel, HeroImage as IHeroImage } from '@graphql/types';
import { Carousel } from 'react-responsive-carousel';
import { HeroImageWrapper } from '@components';
import styles from './HeroCarousel.module.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


type Props = {
  contentData: IHeroCarousel,
  mainHeader?: boolean
}

const HeroCarousel = ({ contentData, mainHeader }: Props) => {
  const { slidesCollection } = contentData;

  // Hero Carousel can include a maximum of 5 slides, rotating every 7 seconds
  const maxSlidesLimit = 5;
  const slides = slidesCollection && slidesCollection.items.length <= maxSlidesLimit ?
    slidesCollection?.items : slidesCollection?.items.slice(0, maxSlidesLimit);

  return (
    slides?.length ? <section
      className={styles.wrapper}
      data-testid="hero-carousel"
      aria-label="hero carousel">
      <Carousel
        autoPlay
        infiniteLoop
        interval={7000}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        renderIndicator={(onClickHandler, isSelected, index) => {
          if (isSelected && slides?.length > 1) {
            return (
              <li
                className={styles.active}
                data-testid="carousel-indicator"
                aria-label={`Go to slide ${index + 1}`}
                aria-current="true"
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
              />
            );
          }
          return (
            slides?.length > 1 && <li
              className={styles.indicator}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              data-testid="carousel-indicator"
              aria-label={`Go to slide ${index + 1}`}
              aria-current="false"
            />
          );
        }}
      >
        {slides?.map((item, idx) => {
          return (
            <div
              key={item?.image?.title}
              className={styles.container}
              role="group"
              aria-label={`slide ${idx + 1} of ${slides?.length}`}
            >
              <HeroImageWrapper
                hero={item as IHeroImage}
                mainHeader={mainHeader && idx === 0}
              />
            </div>
          );
        })}
      </Carousel>
    </section> : <div />
  );
};

export default HeroCarousel;
