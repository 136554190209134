import React from 'react';
import styles from './jobItem.module.scss';
import { IVacancy } from './interfaces';
import { Icon } from '@components';


type JobItemProps = {
  id: string;
  fields: IVacancy;
  moreDetailsButtonText: string;
  handleGoToJob?: ({ id, title }: { id: string, title: string }) => any;
  convertDate: (arg0: string) => string;
  updateSession: () => void;
}

export const JobItem = (
  {
    id,
    fields,
    moreDetailsButtonText,
    convertDate,
    updateSession
  }: JobItemProps) => {
  const getLocation = (city?: string, country?: string, district?: string) => {
    return <span>{`${city || ''}${city && district || city && country ? ', ' : ''}${district || ''}${district && country ? ', ' : ''}${country || ''}`}</span>;
  };

  const { url = '' } = fields;

  return (
    <a
      data-testid="jobListItem"
      key={id}
      className={styles.container}
      href={url}
      onClick={updateSession}
    >
      {fields.title && (
        <div
          data-testid="jobItemTitle"
          className={styles.title}
        >
          <h4>
            {fields.title}
          </h4>
        </div>
      )}
      <div className={styles.infoBlock}>
        <div className={styles.subTitle}>
          {fields.area &&
            <>
              <div
                data-testid="jobItemArea"
                className={styles.subTitle__item}>
                <div className={styles.icon}>
                  <Icon icon={['fas', 'bars-staggered']}/>
                </div>
                <span>{fields.area}</span>
              </div>
            </>}
          <>{(fields.city || fields.country) && <div className={styles.subTitle__item}>
            <div
              data-testid="jobItemLocation"
              className={styles.icon}>
              <Icon icon={['fas', 'location-dot']}/>
            </div>
            {getLocation(fields.city, fields.country, fields.district)}
          </div>
          }</>

          {fields.date && (
            <>
              <div
                data-testid="jobItemDate"
                className={styles.date}>
                <div className={styles.icon}>
                  <Icon icon={['fas', 'calendar-day']}/>
                </div>
                {convertDate(fields.date)}
              </div>
            </>
          )}
        </div>
        {moreDetailsButtonText && (<p className={styles.moreDetails}>
          {moreDetailsButtonText}
          <Icon icon={['fas', 'circle-chevron-right']}/>
        </p>)}
      </div>
    </a>
  );
};
