import React from 'react';
import styles from './pagination.module.scss';
import { Button } from '@components';
import { IPaginationProps } from './interfaces';
import { MAXIMUM_PAGES_DISPLAYED, NEXT, PREVIOUS } from '@constants';


export const Pagination = ({
  pagination,
  maximumPages = MAXIMUM_PAGES_DISPLAYED,
}: IPaginationProps) => {
  const { currentPage, total, baseSlug } = pagination;
  if (total === 1) {
    return <></>;
  }

  const baseUrl = `/${baseSlug.join('/')}`;

  const buildPageUrl = (page: number) => {
    if(page === 1) {
      return baseUrl;
    }

    return `${baseUrl}/${page}`;
  };

  const getPageButton = (page: number, key?: string, label?: string) => {
    if (page === 0) {
      return <span className={styles.pagination__divider}>...</span>;
    }

    const theme = page === currentPage ? 'secondary' : 'default';
    return (
      <a
        key={key || page}
        className={styles.pagination__link}
        href={buildPageUrl(page)}
      >
        <Button
          label={label || `${page}`}
          theme={theme}
          outline
        />
      </a>
    );
  };

  const preparePrevNextButtons = (pagesArray: JSX.Element[], isMobile: boolean) => {
    if(currentPage > 1) {
      pagesArray.splice(0, 0, getPageButton(currentPage - 1, PREVIOUS, isMobile ? '<' : PREVIOUS));
    }

    if (currentPage < total) {
      pagesArray.push(getPageButton(currentPage + 1, NEXT, isMobile ? '>' : NEXT));
    }

    return pagesArray;
  };

  const getPagesArray = (buttonLimit: number) => {
    const pages: JSX.Element[] = [];
    if (total < buttonLimit) {
      for(let i = 1; i <= total; i++) {
        pages.push(getPageButton(i));
      }

      return pages;
    }

    const pageSpacing = (buttonLimit / 2) | 0; // number of pages to display for both sides by default

    let startPage = currentPage - pageSpacing;
    let endPage = currentPage + pageSpacing;

    if (startPage < 1) {// if we are close to start, shift center page left
      endPage += 1 - startPage;
    }
    if (endPage > total) { // if we are close to end, shilft center page right
      startPage -= endPage - total;
      endPage = total;
    }
    if (startPage < 1) { // in case we are still off left side, normal it
      startPage = 1;
    }

    const pageNumbers = [];
    while (startPage <= endPage) {
      pageNumbers.push(startPage);
      startPage++;
    }

    if (pageNumbers[0] !== 1) {
      pageNumbers.splice(0, 1, ...[1, 0]); // in case currentPage is far from displaying 1 page, we are replacing first entry with page 1 and divider
    }

    if (endPage !== total) {
      pageNumbers.splice(-1, 1, ...[0, total]); // in case currentPage is far from displaying last page, we are replacing last entry with divider and last page
    }

    pageNumbers.forEach((pageNumber) => {
      pages.push(getPageButton(pageNumber));
    });

    return pages;
  };

  const paginationDesktop = preparePrevNextButtons(getPagesArray(maximumPages), false);
  const paginationMobile = preparePrevNextButtons(getPagesArray(3), true);

  return (
    <div className={styles.pagination}>
      <span className={[styles.wrapper, styles.mobile].join(' ')}>
        {paginationMobile}
      </span>
      <span className={[styles.wrapper, styles.desktop].join(' ')}>
        {paginationDesktop}
      </span>
    </div>
  );
};