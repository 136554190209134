import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
import { HeroImage as IHeroImage } from '@graphql/types';
import { HeroImage as HeroImageComponent, ApplyButton } from '@components';
import { MOBILE_BREAKPOINT } from '@constants';


type Props = {
  hero: IHeroImage;
  buttonComponent?: JSX.Element;
  imageComponent?: JSX.Element;
  mainHeader?: boolean;
}

const HeroImageWrapper = ({
  hero,
  buttonComponent,
  imageComponent,
  mainHeader
}: Props) => {

  const isMobile = useMediaQuery({ query:`(max-width: ${MOBILE_BREAKPOINT}px)` });
  const heroImage = (isMobile && hero?.mobileImage) ? hero?.mobileImage : hero?.image;
  const defaultImageComponent = heroImage && heroImage.url ? (
    <Image
      src={heroImage.url || ''}
      alt={heroImage.title || ''}
      layout="fill"
      objectFit="cover"
      loader={({ width }) => `${heroImage.url}?w=${width}&fm=webp`}
      sizes="100vw"
      placeholder="blur"
      blurDataURL={`${heroImage.url}?w=10&q=25`}
      priority={mainHeader}
    />
  ) : undefined;

  const heroButton = hero?.button;
  const defaultButtonComponent = heroButton ? (
    <ApplyButton
      button={heroButton}
    />
  ) : undefined;

  return (
    <HeroImageComponent
      hero={hero}
      imageComponent={imageComponent ? imageComponent : defaultImageComponent}
      buttonComponent={buttonComponent ? buttonComponent : defaultButtonComponent}
      mainHeader={mainHeader}
    />
  );
};

export default HeroImageWrapper;
