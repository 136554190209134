import { buildContentulEndpoint, toBoolean } from '@utils';
import { Asset } from '@graphql/types';
import { initApolloClient } from '@lib/apollo-client';
import { getAsset } from './asset';
import { getAssetCollection } from './collection';
import { IAssetCollectionResponse, IAssetResponse } from './interfaces';
import { IMAGE_ASSET_TYPES } from './constants';


const preview = toBoolean(process.env.PREVIEW);

const client = initApolloClient({
  uri: buildContentulEndpoint(process.env.CONTENTFUL_SPACE, process.env.CONTENTFUL_ENVIRONMENT),
  authHeader: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
  preview
});

export async function fetchAsset(id: string): Promise<IAssetResponse> {
  return await getAsset(client, id, preview);
}

export async function fetchAssetsCollection(ids: string[]): Promise<IAssetCollectionResponse> {
  return await getAssetCollection(client, ids, preview);
}

export function isAssetImage(asset: Asset) {
  const { contentType = '' } = asset;
  return IMAGE_ASSET_TYPES.includes(contentType);
}

export function getItemsFromCollection(collection: IAssetCollectionResponse) {
  const assets: any = [];
  const { items = [] } = collection;

  for (const item of items) {
    const { id = '' } = item?.sys || {};
    assets[id] = item;
  }

  return assets;
}