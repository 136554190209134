import React from 'react';
import { Icon } from '@components';
import { IBlogCard } from './interfaces';
import styles from './blogCard.module.scss';
import { convertDate } from '@utils';
import { MINUTES_READ } from '@constants';
import { InfoCard } from '../InfoCard';


type Props = {
  card: IBlogCard
}

export const BlogCard = ({ card }: Props) => {
  const {
    title = '',
    description,
    image,
    category,
    publishedDate,
    duration,
    page,
    featured
  } = card;

  return (
    <InfoCard
      title={title}
      description={description}
      image={image}
      chipText={category}
      link={page}
      featured={featured}
    >
      <>
        {publishedDate && (
          <span className={styles.published}>
            <Icon icon={['fas', 'calendar-day']} />
            {convertDate(publishedDate, {
              month: 'short'
            })}
          </span>
        )}
        {duration && (
          <span className={styles.duration}>
            <Icon icon={['fas', 'book-open-reader']} />
            {duration} {MINUTES_READ}
          </span>
        )}
      </>
    </InfoCard>
  );
};
