import React, { useEffect } from 'react';
import styles from '@styles/button.module.scss';
import { Icon } from '@components';
import { IButtonProps } from './interfaces';


export const Button = ({
  dataTestId,
  theme = 'default',
  disabled = false,
  label,
  hasIcon = 'none',
  icon = ['fas', 'arrow-right'],
  outline = false,
  large = false,
  type = 'button',
  className,
  iconColor,
  ...props
}: IButtonProps) => {
  useEffect(() => {}, []);
  return (
    <button
      data-testid={dataTestId}
      type={type}
      className={[
        styles.button,
        styles[`button--${theme}`],
        styles[`button--icon-${hasIcon}`],
        styles[outline ? 'button--outline' : ''],
        styles[large ? 'button--large' : ''],
        className,
      ].join(' ')}
      disabled={disabled}
      title={label}
      {...props}
    >
      {hasIcon === 'left' && <Icon icon={icon} color={iconColor} />}
      {hasIcon !== 'only' && label}
      {(hasIcon === 'right' || hasIcon === 'only') && <Icon icon={icon} color={iconColor}/>}
    </button>
  );
};
