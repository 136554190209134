interface IReplacement {
  [key: string]: string,
}

export const stringToHtml = (text: string): string => {
  const replacements: IReplacement = {
    '&lt;': '<',
    '&gt;': '>',
    '&amp;': '&',
    '&quot;': '"',
    '&#39;': '\'',
    '\\n': '<br />',
  };
  const searchRegex = /(&lt;|&gt;|&amp;|&quot;|&nbsp;|\\n)/g;

  const resultHtml = text.replace(searchRegex, (char: string) => replacements[char]);
  return resultHtml.replace(/(<script>|<\\\/script>)/g, '');
}
