import { IBlogCard } from '@componentTypes';
import React from 'react';
import { BlogCard } from '@components';
import styles from './blogGrid.module.scss';
import { FeedCard } from '../FeedCard';


type Props = {
  cards: IBlogCard[]
}

export const BlogGrid = ({ cards }: Props) => (
  <div className={styles.wrapper}>
    <div
      className={styles.blogGrid}
      data-testid="blog-grid">
      {cards.map((item: any, index: number) =>
        <div
          key={index}
          className={[
            styles.block,
          ].join(' ')}>
          {item.__typename === 'FeedCard' ?
            <FeedCard card={item} /> :
            <BlogCard card={item} />
          }
        </div>
      )}
    </div>
  </div>
);

