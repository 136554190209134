/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetEventLandingPagesQueryVariables = Types.Exact<{
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetEventLandingPagesQuery = { __typename?: 'Query', eventLandingPageCollection?: { __typename?: 'EventLandingPageCollection', items: Array<{ __typename?: 'EventLandingPage', sys: { __typename?: 'Sys', id: string } } | null> } | null };


export const GetEventLandingPagesDocument = gql`
    query getEventLandingPages($preview: Boolean) {
  eventLandingPageCollection(preview: $preview) {
    items {
      sys {
        id
      }
    }
  }
}
    `;
export type GetEventLandingPagesQueryResult = Apollo.QueryResult<GetEventLandingPagesQuery, GetEventLandingPagesQueryVariables>;