import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from '@components';
import styles from './shareButton.module.scss';
import {
  COPPIED_TEXT,
  COPY_TEXT,
  FACEBOOK_SHARE_URL,
  FACEBOOK_TEXT,
  LINKEDIN_SHARE_URL,
  LINKEDIN_TEXT,
  SHARE_TEXT,
  TWITTER_SHARE_URL,
  TWITTER_TEXT
} from '@constants';
import { Icon } from '../Icon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useRouter } from 'next/router';
import { Alert } from '../Alert';

export const ShareButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const [shareUrl, setShareUrl] = useState<string>('');
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    if(!window || !window.location.origin) {
      return;
    }

    setShareUrl(`${window.location.origin}${router.asPath}`);
  }, []);

  const copyToClipboard = async () => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(shareUrl);
    }

    return document.execCommand('copy', true, shareUrl);
  };

  const handleCopyClick = async () => {
    await copyToClipboard();
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <span
      className={styles.shareButton}
    >
      <Button
        label={SHARE_TEXT}
        theme="default"
        hasIcon="left"
        icon={'fa arrow-up-from-square' as IconProp}
        outline
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        aria-controls="dropdown"
      />
      {isOpen && (
        <Dropdown
          setIsOpen={setIsOpen}
          className={styles.shareButton__popup}
        >
          <div onClick={handleCopyClick}>
            <Icon icon={['fas', 'link']} />
            {COPY_TEXT}
          </div>
          <a
            target="_blank"
            href={`${FACEBOOK_SHARE_URL}${encodeURIComponent(shareUrl)}`}
            tabIndex={0} rel="noreferrer"
          >
            <Icon icon={['fab', 'facebook']} />
            {FACEBOOK_TEXT}
          </a>
          <a
            target="_blank"
            href={`${LINKEDIN_SHARE_URL}${encodeURIComponent(shareUrl)}`}
            tabIndex={0} rel="noreferrer"
          >
            <Icon icon={['fab', 'linkedin']} />
            {LINKEDIN_TEXT}
          </a>
          <a
            target="_blank"
            href={`${TWITTER_SHARE_URL}${encodeURIComponent(shareUrl)}`}
            tabIndex={0} rel="noreferrer"
          >
            <Icon icon={['fab', 'twitter']} />
            {TWITTER_TEXT}
          </a>
        </Dropdown>
      )}
      <Alert
        active={isCopied}
        theme="default"
        outline
      >
        {COPPIED_TEXT}
      </Alert>
    </span>
  );
};