/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetPageQueryVariables = Types.Exact<{
  slugs?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetPageQuery = { __typename?: 'Query', pageCollection?: { __typename?: 'PageCollection', items: Array<{ __typename?: 'Page', title?: string | null, name?: string | null, slug?: string | null, sys: { __typename?: 'Sys', id: string }, seo?: { __typename?: 'Seo', name?: string | null, title?: string | null, description?: string | null, keywords?: Array<string | null> | null, noIndex?: boolean | null, noFollow?: boolean | null } | null, contentSys?: { __typename?: 'BlogLandingPage', type: 'BlogLandingPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'BlogPage', type: 'BlogPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'ContentPage', type: 'ContentPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'ErrorPage', type: 'ErrorPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'EventLandingPage', type: 'EventLandingPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'EventPage', type: 'EventPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'HomePage', type: 'HomePage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'JobDescriptionPage', type: 'JobDescriptionPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'SearchResultsPage', type: 'SearchResultsPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'SitemapPage', type: 'SitemapPage', sys: { __typename?: 'Sys', id: string } } | null } | null> } | null };


export const GetPageDocument = gql`
    query getPage($slugs: [String!], $preview: Boolean) {
  pageCollection(where: {slug_in: $slugs}, preview: $preview) {
    items {
      sys {
        id
      }
      title
      name
      slug
      seo {
        name
        title
        description
        keywords
        noIndex
        noFollow
      }
      contentSys: content {
        type: __typename
        ... on HomePage {
          sys {
            id
          }
        }
        ... on SearchResultsPage {
          sys {
            id
          }
        }
        ... on JobDescriptionPage {
          sys {
            id
          }
        }
        ... on ContentPage {
          sys {
            id
          }
        }
        ... on ErrorPage {
          sys {
            id
          }
        }
        ... on SitemapPage {
          sys {
            id
          }
        }
        ... on BlogPage {
          sys {
            id
          }
        }
        ... on BlogLandingPage {
          sys {
            id
          }
        }
        ... on EventPage {
          sys {
            id
          }
        }
        ... on EventLandingPage {
          sys {
            id
          }
        }
      }
    }
  }
}
    `;
export type GetPageQueryResult = Apollo.QueryResult<GetPageQuery, GetPageQueryVariables>;