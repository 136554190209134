import React from 'react';
import { Icon, TextBlock } from '@components';
import styles from './TextImage2Column.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SocialBlock, UrlWithIcon, SocialBlockSocialLinksItem } from '@graphql/types';
import { ITextBlock } from '@componentTypes';
import { filterDraftItems } from '@utils';


type Props = {
  text: ITextBlock,
  image: JSX.Element | string,
  imagePosition: string,
  socialBlock?: SocialBlock
}

export const TextImage2Column = ({ text, image, imagePosition, socialBlock }: Props) => {
  const renderSocialLink = (link: SocialBlockSocialLinksItem) => {
    if (!link?.linkUrl) {
      return <></>;
    }
    const iconClass = (link as UrlWithIcon).iconClass || '';
    return <a key={link?.linkText} href={link?.linkUrl || ''}>
      <Icon icon={iconClass as IconProp} />
    </a>;
  };

  return (
    <div className={[
      styles.wrapper,
      styles[imagePosition?.toLowerCase() || '']
    ].join(' ')}>
      <div className={styles.copy}>
        {text && <TextBlock contentData={text} className="TextImage2Column" />}
        {socialBlock && <div className={styles.social} data-testid="social-block">
          <div className={styles.social_wrapper}>
            <div>{socialBlock?.title}</div>
            <div>
              {filterDraftItems(socialBlock?.socialLinksCollection?.items)
                .map(renderSocialLink)}
            </div>
          </div>
        </div>}
      </div>
      <div className={styles.image} data-testid="image">
        {image}
      </div>
    </div>
  );
};
