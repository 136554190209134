import React from 'react';
import styles from './nav.module.scss';


interface NavProps {
  selected?: string;
  size?: 'small' | 'medium' | 'large';
  items: string[];
  onChange?: (selectedItem: string) => void;
  style?: any;
}

export const Nav = ({
  selected,
  size = 'medium',
  items,
  onChange = () => {},
  style
}: NavProps) => {
  return (
    <div className={[styles.nav, styles[`nav--${size}`], style].join(' ')}>
      {items.map((item, idx: number) => (
        <div
          key={idx}
          className={[styles.nav__item, styles[`nav__item--${size}`],selected === item ? styles['nav__item--selected'] : ''].join(' ')}
          onClick={() => onChange(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};
