import { ApolloClient, InMemoryCache } from '@apollo/client';
import { safeQuery } from '@lib/apollo-client';

import {
  GetJobSearchResultsFilterAndSortSettingsDocument
} from '@graphql/queries/getSearchFilterAndSortingSettings.generated';

import { CountryGroup, MappingCountryName } from '@graphql/types';
import { IGlobalSettings } from '@contentful';

import { getAllVacanciesData, Vacancy } from '@lib/vacancies-data';

import { IArea, IRegion } from '@componentTypes';
import {
  getProvidersIntegrationStatus,
  isVacancyInternal,
  getVacancyArea,
} from '@utils';

import { IFilterDataResponse, ISobSearchResultsFilterAndSortSettingsResponse } from './interfaces';

import { REMOTE_LOCATION } from '@constants';


/**
 * Get available areas from vacancies
 *
 * @param vacancies
 * @param areaMappings
 */
export const getAvailableAreas = (vacancies: Vacancy[], areaMappings: any = []): IArea[] => {
  if (!vacancies.length) {
    return [];
  }

  const availableAreas = new Map<string, IArea>();
  for (const vacancy of vacancies) {
    // Skip internal vacancies
    if (isVacancyInternal(vacancy)) {
      continue;
    }

    const mappedArea = getVacancyArea(areaMappings, vacancy);
    if (mappedArea && !availableAreas.has(mappedArea)) {
      availableAreas.set(mappedArea, {
        id: mappedArea,
        value: mappedArea,
      })
    }
  }

  return Array.from(availableAreas.values());
}

/**
 * Get available locations from vacancies
 *
 * @param vacancies
 * @param regions
 */
export const getAvailableLocations = (vacancies: Vacancy[], regions: any = []): IRegion[] => {
  if (!vacancies.length) {
    return [];
  }

  const availableLocations = new Map<string, Set<string>>();
  const remoteLocations = new Set<string>();

  // Collect all countries and their aliases from regions
  const countryAliases = regions.reduce((countries: Record<string, string[]>, region: CountryGroup) => {
    const countryMappingsItems = region?.countriesMappingCollection?.items || [];
    for (const countryAlias of countryMappingsItems) {
      if (countryAlias.filterName && countryAlias.mappingNames) {
        countries[countryAlias.filterName] = countryAlias.mappingNames;
      }
    }

    return countries;
  }, {});

  // Get available locations from vacancies { [countryCode]: [cities] }
  for (const vacancy of vacancies) {
    // Skip internal vacancies
    if (isVacancyInternal(vacancy)) {
      continue;
    }

    // Get vacancy location data
    const city = vacancy?.city.trim();
    const country = vacancy?.country.trim();

    // Check if country exists in the mappings and should be shown in filter
    const [countryFilterName] = Object.entries(countryAliases).find(
      countryAliasesEntry => {
        const mappings = countryAliasesEntry[1] as string[];
        return mappings && mappings.includes(country)
      }
    ) || [];

    if (countryFilterName) {
      // Check if vacancy is from 'Remote' location
      if (city === REMOTE_LOCATION) {
        remoteLocations.add(countryFilterName);
      } else {
        const cities = availableLocations.get(countryFilterName) || new Set<string>();
        availableLocations.set(countryFilterName, cities.add(city));
      }
    }
  }

  // Transform into filter data in relation to Country Groups from Contentful
  return regions.map((region: CountryGroup) => {
    const countryList = region?.countriesMappingCollection?.items || [];

    // Get cities from available locations for the country
    const regionCountries = countryList.map((country: MappingCountryName) => {
      const countryFilterName = country.filterName || '';
      const cities = availableLocations.get(countryFilterName) || [];
      const locations = Array.from(cities).map(city => ({
          id: city,
          value: city,
          country: countryFilterName
        }))
        .sort((a,b) => a.value.localeCompare(b.value));

      // Check if country has remote positions available and add it at the end
      if (remoteLocations.has(countryFilterName)) {
        locations.push({
          id: `${REMOTE_LOCATION},${countryFilterName}`,
          value: REMOTE_LOCATION,
          country: countryFilterName,
        });
      }

      return { name: countryFilterName, locations }
    });

    return {
      name: region.name,
      countries: regionCountries.filter(country => country.locations.length > 0),
    };
  });
};

/**
 * Get Search Filter settings from Contentful
 *
 * @param client
 * @param id
 * @param preview
 */
export async function getSearchFilterAndSortingSettings(
  client: ApolloClient<InMemoryCache>,
  id: string,
  preview: boolean
): Promise<ISobSearchResultsFilterAndSortSettingsResponse> {
  const { data, error } = await safeQuery(client, GetJobSearchResultsFilterAndSortSettingsDocument, {
    id,
    preview
  });

  // Return null if there is no data or an error
  if (error || !data) {
    return {
      jobSearchResultsFilterAndSortSettings: null,
      error
    };
  }

  const { jobSearchResultsFilterAndSortSettings } = data;
  return {
    jobSearchResultsFilterAndSortSettings
  };
}

/**
 * Retrieve filter data from Contentful and transform it accordingly
 *
 * @param client
 * @param id
 * @param preview
 * @param globalSettings
 */
export async function getFilterData(
  client: ApolloClient<InMemoryCache>,
  id: string,
  preview: boolean,
  globalSettings: IGlobalSettings | null
): Promise<IFilterDataResponse> {
  const providersIntegrationStatus = getProvidersIntegrationStatus(globalSettings);
  const areaMappings = globalSettings?.athosLabelMappings || [];
  const { jobSearchResultsFilterAndSortSettings, error } = await getSearchFilterAndSortingSettings(client, id, preview);
  const vacancies = await getAllVacanciesData(providersIntegrationStatus);

  // Return empty filters if there is an error or no vacancies
  if (error || !vacancies.length) {
    return {
      jobSearchResultsFilterAndSortSettings: null,
      error: error || '[Search Filter] No vacancies were selected to base filters on',
    };
  }

  // If settings were selected but they are actually empty
  if (!jobSearchResultsFilterAndSortSettings) {
    return {
      jobSearchResultsFilterAndSortSettings: null,
      error: '[Search Filter] No settings could be selected',
    };
  }

  const { areaOfWorkFilter, locationFilter } = jobSearchResultsFilterAndSortSettings;
  return {
    jobSearchResultsFilterAndSortSettings: {
      ...jobSearchResultsFilterAndSortSettings,
      areaOfWorkFilter: {
        buttonText: areaOfWorkFilter?.areaFilterButtonText || '',
        title: areaOfWorkFilter?.areaFilterTitle || '',
        labels: getAvailableAreas(vacancies, areaMappings),
      },
      locationFilter: {
        buttonText: locationFilter?.locationFilterButtonText || '',
        title: locationFilter?.locationFilterTitle || '',
        allText: locationFilter?.allRegionsTabText || '',
        remoteText: locationFilter?.remoteTabText || '',
        regions: getAvailableLocations(vacancies, locationFilter?.countryGroupsCollection?.items)
      }
    }
  };
}
