import { EContentTypes, IContentTypeQuery } from '../interfaces';
import { contentPage } from './contentPage';
import { homePage } from './homePage';
import { jobDescriptionPage } from './jobDescriptionPage';
import { searchResultPage } from './searchResultPage';
import { errorPage } from './errorPage';
import { sitemapPage } from './sitemapPage';
import { blogPage } from './blogPage';
import { blogLandingPage } from './blogLandingPage';
import { eventPage } from './eventPage';
import { eventLandingPage } from './eventLandingPage';


export type { IContentLayout, IContentSys } from './contentPage';
export type { IErrorLayout, IErrorSys } from './errorPage';
export type { IHomeLayout, IHomeSys } from './homePage';
export type { IJobDescriptionLayout, IJobDescriptionSys } from './jobDescriptionPage';
export type { ISearchResultLayout, ISearchResultSys } from './searchResultPage';
export type { ISitemapLayout, ISitemapPage, ISytemapSys } from './sitemapPage';
export type { IBlogLayout, IBlogSys } from './blogPage';
export type { IBlogLandingLayout, IBlogLandingSys } from './blogLandingPage';
export type { IEventLayout, IEventSys } from './eventPage';
export type { IEventLandingLayout, IEventLandingSys } from './eventLandingPage';

export const contentTypes: { [key: string]: IContentTypeQuery } = {
  [EContentTypes.jobDescriptionPage]: jobDescriptionPage,
  [EContentTypes.homePage]: homePage,
  [EContentTypes.searchResultsPage]: searchResultPage,
  [EContentTypes.contentPage]: contentPage,
  [EContentTypes.errorPage]: errorPage,
  [EContentTypes.sitemapPage]: sitemapPage,
  [EContentTypes.blogPage]: blogPage,
  [EContentTypes.blogLandingPage]: blogLandingPage,
  [EContentTypes.eventPage]: eventPage,
  [EContentTypes.eventLandingPage]: eventLandingPage
};