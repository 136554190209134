import { getEventLandingCards, getHeader, IContentTypeQuery } from '@contentful';
import { GetEventLandingPageDocument } from '@graphql/queries/getEventLandingPageContent.generated';


export type { IEventLandingLayout, IEventLandingSys } from './interfaces';

export const eventLandingPage: IContentTypeQuery = {
  query: GetEventLandingPageDocument,
  returnParam: 'eventLandingPage',
  type: 'EventLandingPage',
  params: {
    headerSys: {
      returnParam: 'header',
      customTransform: async (data: any) => {
        const { error, header } = await getHeader(data.client, data.sys.id, data.preview, data.slugs, data.env);
        return {
          error,
          data: header
        };
      }
    },
    events: {
      returnParam: 'events',
      customTransform: async (pageData: any) => getEventLandingCards(pageData)
    },
    heroSys: {
      returnParam: 'hero',
    },
    contentSys: {
      returnParam: 'content'
    },
    footerSys: {
      returnParam: 'footer'
    }
  }
};