import React from 'react';
import Head from 'next/head';

import {
  Content,
} from '@components';
import { IEventLandingLayout } from '@contentful';
import styles from './EventLandingPage.module.scss';
import { IEventCard } from 'components/molecules/interfaces';
import { Pagination } from 'components/atoms';
import { CategoryFilter } from '../../atoms/CategoryFilter';
import { EventCard } from 'components/molecules';
import { PAST_TEXT, UPCOMING_TEXT } from 'constants/event';
import { EventGrid } from 'components/molecules/EventGrid';


type PageProps = {
  page: IEventLandingLayout
}

export const EventLandingPage = ({ page }: PageProps) => {
  const { content, hero, seo, events } = page;

  const renderUpcoming = (events: IEventCard[]) => {
    return events.map((event: IEventCard) => (
      <div
        data-testid="featured"
        key={event.title}
        className={styles.featured}
      >
        <EventCard card={event} featured />
      </div>
    ));
  };

  return (
    <div className={styles.eventLandingPage}>
      <Head>
        <title>{seo?.title}</title>
        <meta name="description" content={seo?.description ?? ''} />
      </Head>
      <main id="mainContent">
        {hero && (
          <Content content={[hero]} mainHeader={true} />
        )}
        <CategoryFilter items={events.filter} />
        {
          events.upcoming &&
          events.upcoming.length > 0 && (
            <div className={styles.upcoming}>
              <h2 className={styles.sectionTitle}>
                {UPCOMING_TEXT}
              </h2>
              {renderUpcoming(events.upcoming)}
            </div>
          )
        }
        {
          events.past &&
          events.past.length > 0 && (
            <div className={styles.past}>
              <h2 className={styles.sectionTitle}>
                {PAST_TEXT}
              </h2>
              <EventGrid cards={events.past} />
            </div>
          )
        }
        {events.pagination &&
          <Pagination pagination={events.pagination} />
        }
        {content && (
          <Content content={content} />
        )}
      </main>
    </div>
  );
};
