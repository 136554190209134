import React from 'react';
import Image from 'next/image';
import { Chip } from '@components';
import { IInfoCardProps } from './interfaces';
import styles from './infoCard.module.scss';
import { RichText } from 'components/atoms';

export const InfoCard = ({
  children,
  title = '',
  description,
  image,
  chipText,
  link,
  featured
}: IInfoCardProps) => {
  return (
    <a className={[styles.infoCard, featured && styles.featured].join(' ')} href={link?.slug || '/'}>
      {image && image.url && (
        <div className={styles.image} data-testid={'card-image'}>
          <Image
            src={image.url}
            alt={image?.title || ''}
            layout="fill"
            objectFit="cover"
            loader={({ width }) => `${image.url}?w=${width}&fm=webp`}
            placeholder="blur"
            blurDataURL={`${image.url}?w=10&q=25`}
          />
        </div>
      )}
      {chipText && (
        <span className={styles.category}>
          <Chip
            label={chipText.toUpperCase()}
          />
        </span>
      )}
      <div className={styles.container}>
        <h4 className={styles.container__title}>{title}</h4>
        {description && typeof description === 'object' ? ( // Support for plain text will be deprecated with BlogGrid removal as content option
          <RichText richText={description?.json || ''} className={styles.container__description}/>
        ) : (
          <p className={styles.container__description}>{description}</p>
        )}
        <div className={styles.container__infoBox}>
          {children}
        </div>
      </div>
    </a>
  );
};
