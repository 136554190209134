/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetTextImage2ColumnQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetTextImage2ColumnQuery = { __typename?: 'Query', textImage2Column?: { __typename: 'TextImage2Column', imagePosition?: string | null, text?: { __typename?: 'Text', title?: string | null, backgroundColour?: string | null, text?: { __typename?: 'TextText', json: any } | null, button?: { __typename?: 'InternalLinkWithIcon', linkText?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null } | null } | null, image?: { __typename?: 'Asset', url?: string | null, title?: string | null, width?: number | null, height?: number | null } | null, socialBlock?: { __typename?: 'SocialBlock', title?: string | null, socialLinksCollection?: { __typename?: 'SocialBlockSocialLinksCollection', items: Array<{ __typename?: 'Url' } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, openLinkInNewBrowserTab?: boolean | null, iconClass?: string | null } | null> } | null } | null } | null };


export const GetTextImage2ColumnDocument = gql`
    query getTextImage2Column($id: String!, $preview: Boolean) {
  textImage2Column(id: $id, preview: $preview) {
    __typename
    text {
      title
      backgroundColour
      text {
        json
      }
      button {
        ... on UrlWithIcon {
          linkText
          linkUrl
          iconClass
          style
          openLinkInNewBrowserTab
        }
        ... on InternalLinkWithIcon {
          linkText
          linkDestination {
            ... on Page {
              slug
            }
          }
          iconClass
          style
          openLinkInNewBrowserTab
        }
      }
    }
    image {
      url
      title
      width
      height
    }
    imagePosition
    socialBlock {
      title
      socialLinksCollection {
        items {
          ... on UrlWithIcon {
            linkText
            linkUrl
            openLinkInNewBrowserTab
            iconClass
          }
        }
      }
    }
  }
}
    `;
export type GetTextImage2ColumnQueryResult = Apollo.QueryResult<GetTextImage2ColumnQuery, GetTextImage2ColumnQueryVariables>;