import { DocumentNode } from 'graphql';
import { GlobalSettings, Seo } from '@graphql/types';
import { IContentLayout } from './contentTypes/contentPage/interfaces';
import { IErrorLayout, IErrorSys } from './contentTypes/errorPage/interfaces';
import { IHomeLayout, IHomeSys } from './contentTypes/homePage/interfaces';
import { IJobDescriptionLayout, IJobDescriptionSys } from './contentTypes/jobDescriptionPage/interfaces';
import { ISearchResultLayout, ISearchResultSys } from './contentTypes/searchResultPage/interfaces';


export interface ISlugItem {
  contentId?: string;
  id?: string;
  params: {
    slug: string[];
  }
}

export interface IContentSys {
  type: string;
  sys: {
    id: string;
  }
}

export interface IPageSys {
  seo: Seo;
  sys: {
    id: string;
  }
  title: string;
  name: string;
  slug: string;
  contentSys: IContentSys;
  childrenCollection: {
    items: {
      sys: {
        id: string;
      }
    }
  }
}

export type PageContent = IJobDescriptionSys | IHomeSys | ISearchResultSys | IContentSys | IErrorSys;

export interface ISharedAttributes {
  slug: string;
  globalSettings: GlobalSettings | null;
  seo: Seo;
  slugs: ISlugItem[];
  sys: {
    id: string;
  },
  synonymousSlugs: string[] | null;
}

export interface IPageTypeError {
  pageError: any;
}

export type PageTypeResponse = IJobDescriptionLayout | IHomeLayout | ISearchResultLayout
 | IContentLayout | IErrorLayout | IPageTypeError;

export enum EContentTypes {
  jobDescriptionPage = 'JobDescriptionPage',
  homePage = 'HomePage',
  searchResultsPage = 'SearchResultsPage',
  contentPage = 'ContentPage',
  errorPage = 'ErrorPage',
  sitemapPage = 'SitemapPage',
  blogPage = 'BlogPage',
  blogLandingPage = 'BlogLandingPage',
  eventPage = 'EventPage',
  eventLandingPage = 'EventLandingPage'
}

interface ICustomTransformResponse {
  error?: string;
  data: any;
}

export interface IContentParam {
  returnParam: string;
  customTransform?: (data: any) => Promise<ICustomTransformResponse>;
}

export interface IContentTypeQuery {
  query: DocumentNode;
  returnParam: string;
  type: string;
  params: {
    [key: string]: IContentParam
  }
}

export interface IPageSlug {
  slug: string;
  title: string;
  sys: {
    id: string;
  }
  seo: {
    disableInSitemap: boolean;
  }
  content: {
    type: string;
    category?: string;
    topic?: string;
    sys: {
      id: string
    }
  }
  childrenCollection: {
    items: {
      slug: string;
      sys: {
        id: string;
      }
    }[]
  } | null;
}

export interface IBlogPagginationMapping {
  featured: number;
  blogs: number;
}

export interface IEventPagginationMapping {
  upcoming: number;
  past: number;
}

export interface IFeedCard {
  backgroundColor: string;
  sectionsCollection: {
    items: IFeedSection[]
  }
}

export interface IFeedSection {
  title: string;
  link: {
    linkText: string;
    openLinkInNewBrowserTab: boolean;
    iconClass: string;
    style: string;
    linkDestination: {
      slug: string;
    }
  }
}