import React from 'react';
import Head from 'next/head';

import {
  BlogInfoPanel,
  Content,
} from '@components';
import { IBlogLayout } from '@contentful';
import { HeroCarousel as IHeroCarousel, HeroImage as IHeroImage } from '@graphql/types';


type PageProps = {
  page: IBlogLayout
}

export const BlogPage = ({ page }: PageProps) => {
  const { content, hero, seo, author, duration, publishedDate, category } = page;

  const ogImage = hero && (
    (hero as IHeroImage).image ?
      (hero as IHeroImage).image?.url :
      (hero as IHeroCarousel).slidesCollection?.items[0]?.image?.url
  );

  return (
    <div>
      <Head>
        <title>{seo?.title}</title>
        <meta name="description" content={seo?.description || ''} />
        <meta
          name="og:type"
          property="og:type"
          content="article"
        />
        <meta
          name="og:title"
          property="og:title"
          content={seo?.title || ''}
        />
        <meta
          name="og:description"
          property="og:description"
          content={seo?.description || ''}
        />
        {ogImage && <meta property="og:image" content={ogImage} />}
        <meta
          name="twitter:site"
          content="@ocado"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:title"
          content={seo?.title || ''}
        />
        <meta
          name="twitter:description"
          content={seo?.description || ''}
        />
        {ogImage && <meta name="twitter:image" content={ogImage} />}
      </Head>
      <main id="mainContent">
        {hero && (
          <Content content={[hero]} mainHeader={true} />
        )}
        <BlogInfoPanel
          author={author}
          category={category}
          publishedDate={publishedDate}
          duration={Math.trunc(duration || 0)}
        />
        {content && (
          <Content content={content} />
        )}
      </main>
    </div>
  );
};
