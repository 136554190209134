import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { JobSearchBox as SearchBoxType } from '@graphql/types';
import { getSuggestions } from '@lib/search-suggest';
import { ChangeEvent, InputProps, SuggestionsFetchRequestedParams } from 'react-autosuggest';
import { ISuggestion } from './interfaces';
import { parsedParams } from '@hooks/use-query-params';
import { useTracking } from 'contexts/TrackingContext';
import { SearchBar, SearchBoxSuggester } from '@components';
import {
  AREA,
  CITY,
  COUNTRY,
  DELAY,
  SEARCH_PAGE_SLUG,
  SUGGEST_LINK,
  SUGGESTIONS_DISABLED,
  TITLE
} from '@constants';
import { logger } from '@utils';
import { SearchBarTheme } from '@componentTypes';


type Props = {
  searchBox: SearchBoxType;
  standalone?: boolean;
  theme?: 'default' | 'primary' | 'secondary' | 'accent';
  onSearch?: (value?: string) => void;
  onValueChange?: (value?: string) => void;
}


const JobSearchBox = ({
  searchBox,
  onSearch = () => {},
  onValueChange = () => {}
}: Props) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);

  const { logEvent } = useTracking();

  const redirectPage = searchBox?.searchRedirect?.slug || SEARCH_PAGE_SLUG;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    value && logEvent({
      event: 'Vacancy Search',
      category: 'Search',
      action: 'search_submit',
      label: value
    });

    const slug = window.location.pathname;
    if (!slug || (slug !== redirectPage)) {
      const link = (value) ? (
        `${redirectPage}?q=${value}`
      ) : (
        redirectPage
      );

      window.location.href = `${window.location.origin}${link}`;
      return;
    }
    onSearch(value);
  };

  const handleSuggest = async (value: string) => {
    const trimmedValue = value.trim();
    const titleSuggestions = await getSuggestions(SUGGEST_LINK, trimmedValue, TITLE);
    const citySuggestions = await getSuggestions(SUGGEST_LINK, trimmedValue, CITY);
    const countrySuggestions = await getSuggestions(SUGGEST_LINK, trimmedValue, COUNTRY);
    const areaSuggestions = await getSuggestions(SUGGEST_LINK, trimmedValue, AREA);

    let suggestionsList;
    if (titleSuggestions?.error || citySuggestions?.error || countrySuggestions?.error || areaSuggestions?.error) {
      suggestionsList = undefined;
      logger.warn('[Search Suggestions] Error occurred on suggestions fetch request');
    } else {
      suggestionsList = [
        ...citySuggestions?.suggest?.suggestions,
        ...titleSuggestions?.suggest?.suggestions,
        ...countrySuggestions?.suggest?.suggestions,
        ...areaSuggestions?.suggest?.suggestions
      ];
    }

    suggestionsList?.sort((a,b) => {
      const fa = a.suggestion;
      const fb = b.suggestion;
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    suggestionsList ? setSuggestions(suggestionsList) : setSuggestions([]);
  };

  const debouncedSuggestion = useCallback(debounce((value: string) => {
    if (value.length > 1) return handleSuggest(value);
  }, DELAY), []);

  useEffect(() => {
    if (value?.length < 1) setSuggestions([]);
  }, [value]);

  useEffect(() => {
    return () => {
      debouncedSuggestion.cancel();
    };
  }, []);

  useEffect(() => {
    const slug = window.location.pathname;
    const { q } = parsedParams();
    if (slug && q && (
      (slug === redirectPage)
    )) {
      if (q.includes('|')) {
        setValue(q.split('|')[0]);
      } else setValue(q);
    }
  }, []);

  const onSuggestionsFetchRequested = ({ value }: SuggestionsFetchRequestedParams) => {
    value && debouncedSuggestion(value);
  };

  const getSuggestionValue = ({ suggestion }: ISuggestion) => {
    setValue(suggestion);
    onSearch(suggestion);
    return suggestion;
  };

  const renderSuggestion = (suggestion: ISuggestion) => (
    <div>
      {suggestion.suggestion}
    </div>
  );

  const onInputChange = (_event: FormEvent<HTMLElement>, params: ChangeEvent) => {
    setValue(params.newValue);
    onValueChange(params.newValue);
  };

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
    onValueChange(event.currentTarget.value);
  };

  const inputProps: InputProps<ISuggestion> = {
    placeholder: searchBox.placeholderText ?? '',
    value: value ?? '',
    'aria-label': 'Search bar input',
    onChange: onInputChange,
  };

  return (
    <>
      {SUGGESTIONS_DISABLED ?
        <SearchBar
          handleSubmit={handleSubmit}
          buttonLabel={searchBox.searchButtonText || ''}
          inputPlaceholder={searchBox.placeholderText || ''}
          inputValue={value}
          handleInputChange={handleInputChange}
          overlay={searchBox.overlay}
          theme={searchBox.backgroundColour?.toLowerCase() as SearchBarTheme}
          label={inputProps['aria-label']}
        />
        :
        <SearchBoxSuggester
          handleSubmit={handleSubmit}
          searchBox={searchBox}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          overlay={searchBox.overlay}
          theme={searchBox.backgroundColour?.toLowerCase() as SearchBarTheme}
        />}
    </>
  );
};

export default JobSearchBox;
