/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetHeaderQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetHeaderQuery = { __typename?: 'Query', header?: { __typename?: 'Header', logoScreenReaderText?: string | null, skipToMainContentLinkText?: string | null, logoImage?: { __typename?: 'Asset', url?: string | null, title?: string | null, width?: number | null, height?: number | null } | null, logoLinkDestination?: { __typename?: 'Page', slug?: string | null } | null, primaryLinksCollection?: { __typename?: 'HeaderPrimaryLinksCollection', items: Array<{ __typename?: 'NavigationLink', linkText?: string | null, type: 'NavigationLink', linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'NavigationLinkWithSubMenu', linkText?: string | null, type: 'NavigationLinkWithSubMenu', sys: { __typename?: 'Sys', id: string } } | null> } | null } | null };


export const GetHeaderDocument = gql`
    query getHeader($id: String!) {
  header(id: $id) {
    logoScreenReaderText
    logoImage {
      url
      title
      width
      height
    }
    logoLinkDestination {
      slug
    }
    skipToMainContentLinkText
    primaryLinksCollection {
      items {
        ... on NavigationLink {
          type: __typename
          linkDestination {
            slug
          }
          linkText
        }
        ... on NavigationLinkWithSubMenu {
          type: __typename
          linkText
          sys {
            id
          }
        }
      }
    }
  }
}
    `;
export type GetHeaderQueryResult = Apollo.QueryResult<GetHeaderQuery, GetHeaderQueryVariables>;