import React, { useCallback } from 'react';
import styles from './Accordion.module.scss';
import { RichText, TextOnly2Column, ComponentHeading } from '@components';
import {
  Accordion as ReactAccordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { IAccordion } from './interfaces';
import { ITextOnly2Column, ITextBlock } from '@componentTypes';
import { filterDraftItems } from '@utils';
import { Panel } from 'graphql/types';


type Props = {
  contentData: IAccordion,
}

const darkBgColors = {
  blue: 'Blue',
  black: 'Black',
};

const formatTitle = (str: string | undefined) => {
  if (!str) return undefined;
  return str.replace(/\s+/g, '+').toLowerCase();
};

export const Accordion = ({
  contentData: {
    panelsCollection,
    title,
    titleBackgroundColour,
  } }: Props) => {
  const getTextColor = useCallback(() => {
    if (titleBackgroundColour === darkBgColors.black || titleBackgroundColour === darkBgColors.blue) {
      return styles.color_white;
    }
    return styles.color_black;
  }, []);

  const renderPanelContent = (panel: Panel, index: number) => {
    const content = panel?.contentCollection?.items[0];

    return (
      <AccordionItem key={index} id={`${formatTitle(panel?.title)}`}>
        <div className={styles.collapsible__trigger}>
          <AccordionItemButton data-testid="accordion-button">
            <a href={`#${formatTitle(panel?.title)}`} tabIndex={-1}>{panel?.title}</a>
          </AccordionItemButton>
        </div>
        {content?.__typename === 'Text' ? (
          <AccordionItemPanel>
            <RichText richText={(content as ITextBlock).text?.json} />
          </AccordionItemPanel>
        ) : (
          <AccordionItemPanel>
            <TextOnly2Column
              textLeft={(content as ITextOnly2Column)?.textLeft}
              textRight={(content as ITextOnly2Column)?.textRight}
              usedAsAccordionContent
            />
          </AccordionItemPanel>
        )}
      </AccordionItem>
    );
  };

  const panels = filterDraftItems(panelsCollection?.items);

  return (
    <div data-testid="accordion" className={styles.accordion}>
      {title && (
        <div className={styles[`bg--${titleBackgroundColour?.toLowerCase()}`]}>
          <div
            data-testid="accordion-title"
            className={[
              getTextColor(),
              styles.container,
              styles.heading__wrapper,
            ].join(' ')}
          >
            {title && <ComponentHeading title={title}/>}
          </div>
        </div>
      )}
      {!!panels.length &&
        <div className={[styles.wrapper, styles.container].join(' ')} data-testid="accordion-panels">
          <ReactAccordion allowZeroExpanded={true}>
            {panels.map(renderPanelContent)}
          </ReactAccordion>
        </div>}
    </div>
  );
};
