import { ApolloClient, InMemoryCache } from '@apollo/client';
import { safeQuery } from '@lib/apollo-client';
import { GetHeaderDocument } from '@graphql/queries/getHeader.generated';
import { IHeaderResponse, ISubLinksRespinse } from './interfaces';
import { GetNavigationLinkWithSubMenuDocument } from '@graphql/queries/getNavigationLinkWithSubMenu.generated';
import { GetNavigationLinkGroupDocument } from '@graphql/queries/getNavigationLinkGroup.generated';
import { ISlugItem, transformLinkUrl } from '@contentful';
import { Header, HeaderPrimaryLinksItem } from '@graphql/types';
import { filterDraftItems } from '@utils';


async function getNavigationGroup(
  client: ApolloClient<InMemoryCache>,
  groupSys: any[],
  preview: boolean,
  slugs: ISlugItem[],
  env?: string
): Promise<ISubLinksRespinse> {
  let groupError: string | null = null;
  const groups = await Promise.all(
    groupSys.map(async (group: any) => {
      if (group.type === 'NavigationLink') {
        return transformLinkUrl({ group }, 'group', slugs);
      }

      const { data, error } = await safeQuery(client, GetNavigationLinkGroupDocument, {
        id: group.sys.id,
        preview
      }, env);

      if (error) {
        groupError = error;
        return data;
      }

      const { navigationLinkGroup } = data;

      const transformedLinks = filterDraftItems(navigationLinkGroup.linksCollection.items)
        .map((link: any) => {
          return transformLinkUrl({ link }, 'link', slugs);
        });

      const transformedLinkGroup = (transformLinkUrl({ navigationLinkGroup }, 'navigationLinkGroup', slugs));

      return {
        ...(transformLinkUrl({ navigationLinkGroup }, 'navigationLinkGroup', slugs)),
        linkText: transformedLinkGroup.title || '',
        subLinks: transformedLinks,
        linksCollection: null
      };
    })
  );

  return {
    links: groups,
    error: groupError || ''
  };
}

async function getLinks(
  client: ApolloClient<InMemoryCache>,
  linkSys: HeaderPrimaryLinksItem[],
  preview: boolean,
  slugs: ISlugItem[],
  env?: string
): Promise<ISubLinksRespinse> {
  let linkError: string | null = null;
  const links = await Promise.all(
    linkSys.map(async (link: HeaderPrimaryLinksItem) => {
      if (link.__typename === 'NavigationLink') {
        return transformLinkUrl({ link }, 'link', slugs);
      }

      const { data, error } = await safeQuery(client, GetNavigationLinkWithSubMenuDocument, {
        id: link.sys.id,
        preview
      }, env);

      if (error) {
        linkError = error;
        return data;
      }

      const { navigationLinkWithSubMenu } = data;
      const { links, error: groupError } = await getNavigationGroup(
        client,
        filterDraftItems(navigationLinkWithSubMenu?.childLinksCollection?.items),
        preview,
        slugs
      );

      if (groupError) {
        linkError = groupError;
        return data;
      }

      return {
        ...(transformLinkUrl({ navigationLinkWithSubMenu }, 'navigationLinkWithSubMenu', slugs)),
        subLinks: links,
        childLinksCollection: null
      };
    })
  );

  return {
    links,
    error: linkError || ''
  };
}

export async function getHeader(
  client: ApolloClient<InMemoryCache>,
  id: string,
  preview: boolean,
  slugs: ISlugItem[],
  env?: string,
): Promise<IHeaderResponse> {
  const { data, error } = await safeQuery(client, GetHeaderDocument, {
    id,
    preview
  }, env);

  if (error || !data) {
    return {
      header: null,
      error
    };
  }

  const { header } : {header: Header} = data;
  const { links, error: linksError } = await getLinks(
    client,
    filterDraftItems(header?.primaryLinksCollection?.items) as HeaderPrimaryLinksItem[],
    preview,
    slugs,
    env
  );

  if(linksError) {
    return {
      header: null,
      error: linksError
    };
  }

  return {
    header: {
      ...header,
      subLinks: links
    }
  };
}