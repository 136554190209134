/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetFooterQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetFooterQuery = { __typename?: 'Query', footer?: { __typename?: 'Footer', copyrightNotice?: string | null, footerLinksCollection?: { __typename?: 'FooterFooterLinksCollection', items: Array<{ __typename?: 'InternalLink', linkText?: string | null, openLinkInNewBrowserTab?: boolean | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'Url', linkText?: string | null, linkUrl?: string | null, openLinkInNewBrowserTab?: boolean | null } | null> } | null, footerBlocksCollection?: { __typename?: 'FooterFooterBlocksCollection', items: Array<{ __typename?: 'LinkBlock', linkUrl?: string | null, text?: string | null, title?: string | null, linkScreenReaderText?: string | null, openLinkInNewBrowserTab?: boolean | null } | { __typename?: 'LogoBlock', screenReaderText?: string | null, image?: { __typename?: 'Asset', url?: string | null, title?: string | null } | null, logoLinkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'SocialBlock', title?: string | null, allSocialChannelsLink?: { __typename?: 'Url', linkText?: string | null, linkUrl?: string | null, openLinkInNewBrowserTab?: boolean | null } | null, socialLinksCollection?: { __typename?: 'SocialBlockSocialLinksCollection', items: Array<{ __typename?: 'Url' } | { __typename?: 'UrlWithIcon', iconClass?: string | null, linkUrl?: string | null, linkText?: string | null, openLinkInNewBrowserTab?: boolean | null } | null> } | null } | null> } | null } | null };


export const GetFooterDocument = gql`
    query getFooter($id: String!) {
  footer(id: $id) {
    copyrightNotice
    footerLinksCollection {
      items {
        ... on Url {
          linkText
          linkUrl
          openLinkInNewBrowserTab
        }
        ... on InternalLink {
          linkText
          linkDestination {
            slug
          }
          openLinkInNewBrowserTab
        }
      }
    }
    footerBlocksCollection {
      items {
        ... on LogoBlock {
          screenReaderText
          image {
            url
            title
          }
          logoLinkDestination {
            slug
          }
        }
        ... on SocialBlock {
          title
          allSocialChannelsLink {
            linkText
            linkUrl
            openLinkInNewBrowserTab
          }
          socialLinksCollection {
            items {
              ... on UrlWithIcon {
                iconClass
                linkUrl
                linkText
                openLinkInNewBrowserTab
              }
            }
          }
        }
        ... on LinkBlock {
          linkUrl
          text
          title
          linkScreenReaderText
          openLinkInNewBrowserTab
        }
      }
    }
  }
}
    `;
export type GetFooterQueryResult = Apollo.QueryResult<GetFooterQuery, GetFooterQueryVariables>;