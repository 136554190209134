import { validate, ValidationError } from 'jsonschema';

import { getVacancyTypeById, isIntegrationEnabledForType, logger } from '@utils';
import { getDataFromBucket } from '@lib/s3-bucket';

import  { getProviderConfiguration } from './constants';
import { ProviderSettings, ProvidersIntegrationStatus, Vacancy, ValidationSchema } from './interfaces';


/**
 * Fetch data for a provider
 * @param providerSettings
 */
const fetchVacanciesData = async (providerSettings: ProviderSettings): Promise<Vacancy[]> => {
  const { filename, schema } = providerSettings;

  try {
    const { result, error } = await getDataFromBucket(filename);

    // If there is an explicit error during fetch, return an empty result
    if (error) {
      logger.error(`[Vacancies] Got an error trying to read the file: ${error}`);
      return [];
    }

    return validateVacancies(result, schema);
  } catch (error: any) {
    logger.error(`[Vacancies] Got an error trying to read the file: ${error || error.message}`);
    return [];
  }
};

/**
 * Validate provider vacancies according to the schema
 * @param vacancies
 * @param schema
 */
const validateVacancies = (vacancies: Vacancy[] = [], schema: ValidationSchema) => {
  const validationErrors: ValidationError[] = [];
  const validVacancies: Vacancy[] = [];

  vacancies.forEach((vacancy) => {
    const resultValidation = validate(vacancy, schema);
    if (resultValidation.errors && resultValidation.errors.length) {
      validationErrors.push(...JSON.parse(JSON.stringify(resultValidation.errors)));
      return false;
    }

    validVacancies.push(vacancy);
  });

  if (validationErrors.length) {
    logger.error(`[Vacancies] Got an error during vacancies validation:\n ${JSON.stringify(validationErrors)}`);
  }

  return validVacancies;
};

/**
 * Get all vacancies data
 */
export const getAllVacanciesData = async (
  providersIntegrationStatus: ProvidersIntegrationStatus
): Promise<Vacancy[]> => {
  const providerConfiguration = getProviderConfiguration();
  let vacancies: Vacancy[] = [];

  for (const [provider, settings] of Object.entries(providerConfiguration)) {
    const isEnabled = isIntegrationEnabledForType(provider, providersIntegrationStatus);

    if (isEnabled) {
      const data = await fetchVacanciesData(settings as ProviderSettings);

      // Merge vacancies data if it is not empty
      if (data && data.length > 0) {
        vacancies = [ ...vacancies, ...data ];
      }
    }
  }

  return vacancies;
};

/**
 * Retrieve single vacancy data

 * @param jobId
 * @param providersIntegrationStatus
 */
export const getSingleVacancyData = async (
  jobId: string, providersIntegrationStatus: ProvidersIntegrationStatus
): Promise<Vacancy|null> => {
  const jobType = getVacancyTypeById(jobId);
  const providerConfiguration = getProviderConfiguration();

  // If type is unknown or relevant provider integration is disabled
  if (!jobId || !providerConfiguration[jobType] || !isIntegrationEnabledForType(jobType, providersIntegrationStatus)) {
    return null;
  }

  const vacancies = await fetchVacanciesData(providerConfiguration[jobType] as ProviderSettings);
  return vacancies?.find((job: Vacancy) => job.id === jobId) || null;
};
