/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetSlugsQueryVariables = Types.Exact<{
  exclude?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetSlugsQuery = { __typename?: 'Query', pageCollection?: { __typename?: 'PageCollection', total: number, limit: number, skip: number, items: Array<{ __typename?: 'Page', slug?: string | null, title?: string | null, sys: { __typename?: 'Sys', id: string }, seo?: { __typename?: 'Seo', disableInSitemap?: boolean | null } | null, content?: { __typename?: 'BlogLandingPage', type: 'BlogLandingPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'BlogPage', category?: string | null, type: 'BlogPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'ContentPage', type: 'ContentPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'ErrorPage', type: 'ErrorPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'EventLandingPage', type: 'EventLandingPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'EventPage', topic?: string | null, type: 'EventPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'HomePage', type: 'HomePage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'JobDescriptionPage', type: 'JobDescriptionPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'SearchResultsPage', type: 'SearchResultsPage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'SitemapPage', type: 'SitemapPage', sys: { __typename?: 'Sys', id: string } } | null, childrenCollection?: { __typename?: 'PageChildrenCollection', items: Array<{ __typename?: 'Page', slug?: string | null, sys: { __typename?: 'Sys', id: string } } | null> } | null } | null> } | null };


export const GetSlugsDocument = gql`
    query getSlugs($exclude: [String], $preview: Boolean, $skip: Int) {
  pageCollection(where: {slug_not_in: $exclude}, preview: $preview, skip: $skip) {
    total
    limit
    skip
    items {
      slug
      title
      sys {
        id
      }
      seo {
        disableInSitemap
      }
      content {
        type: __typename
        ... on HomePage {
          sys {
            id
          }
        }
        ... on SearchResultsPage {
          sys {
            id
          }
        }
        ... on JobDescriptionPage {
          sys {
            id
          }
        }
        ... on ContentPage {
          sys {
            id
          }
        }
        ... on ErrorPage {
          sys {
            id
          }
        }
        ... on SitemapPage {
          sys {
            id
          }
        }
        ... on BlogPage {
          category
          sys {
            id
          }
        }
        ... on BlogLandingPage {
          sys {
            id
          }
        }
        ... on EventPage {
          topic
          sys {
            id
          }
        }
        ... on EventLandingPage {
          sys {
            id
          }
        }
      }
      childrenCollection {
        items {
          slug
          sys {
            id
          }
        }
      }
    }
  }
}
    `;
export type GetSlugsQueryResult = Apollo.QueryResult<GetSlugsQuery, GetSlugsQueryVariables>;