import React from 'react';
import { Button, Input } from '@components';
import styles from '@styles/SearchBar.module.scss';
import { ISearchBarProps } from './interfaces';


export const SearchBar = (
  {
    handleSubmit,
    buttonLabel,
    inputPlaceholder,
    inputValue,
    handleInputChange,
    overlay = true,
    theme = 'white',
    label = ''
  }: ISearchBarProps) => {
  return (
    <div className={styles[`searchbox--${overlay ? 'overlay' : theme}`]}>
      <form
        className={styles.wrapper}
        onSubmit={handleSubmit}
        data-testid="search-bar"
        aria-label="search bar"
      >
        <Input
          type="text"
          placeholder={inputPlaceholder}
          large={true}
          className={styles.input}
          hasIcon="left"
          value={inputValue}
          label={label}
          onChange={handleInputChange}
        />
        <Button
          label={buttonLabel || ''}
          theme="primary"
          large={true}
          type="submit"
          hasIcon="right"
          icon={['fas', 'magnifying-glass']}
          data-testid="search-button"
        />
      </form>
    </div>
  );
};
