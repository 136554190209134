import React from 'react';
import { Icon } from '@components';
import { convertDate } from '@utils';
import { IBlogInfoPanelProps } from './interfaces';
import styles from './blogInfoPanel.module.scss';
import { MINUTES_READ } from '@constants';
import { ShareButton } from '../ShareButton';


export const BlogInfoPanel = ({ author, duration, publishedDate, category }: IBlogInfoPanelProps) => {
  return (
    <div className={styles.blogInfoPanel}>
      <div className={styles.container}>
        {author && (
          <span className={styles.infoBlock}>
            <Icon icon={['fas', 'user']} />
            {author}
          </span>
        )}
        {publishedDate && (
          <span className={styles.infoBlock}>
            <Icon icon={['fas', 'calendar']} />
            {convertDate(publishedDate, {
              month: 'short'
            })}
          </span>
        )}
        {category && (
          <span className={styles.infoBlock}>
            <Icon icon={['fas', 'tag']} />
            {category}
          </span>
        )}
        {!!duration && (
          <span className={styles.infoBlock}>
            <Icon icon={['fas', 'book-open-reader']} />
            {`${duration} ${MINUTES_READ}`}
          </span>
        )}
        <span
          className={[
            styles.infoBlock,
            styles.right,
            styles.fillMobile
          ].join(' ')}
        >
          <ShareButton/>
        </span>
      </div>
    </div>
  );
};