/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetAccordionQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetAccordionQuery = { __typename?: 'Query', accordion?: { __typename: 'Accordion', title?: string | null, titleBackgroundColour?: string | null, panelsCollection?: { __typename?: 'AccordionPanelsCollection', items: Array<{ __typename?: 'Panel', title?: string | null, contentCollection?: { __typename?: 'PanelContentCollection', items: Array<{ __typename?: 'Text', title?: string | null, backgroundColour?: string | null, text?: { __typename?: 'TextText', json: any } | null } | { __typename: 'TextOnly2Column', textLeft?: { __typename?: 'Text', title?: string | null, backgroundColour?: string | null, text?: { __typename?: 'TextText', json: any } | null, button?: { __typename?: 'InternalLinkWithIcon', linkText?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null } | null } | null, textRight?: { __typename?: 'Text', title?: string | null, backgroundColour?: string | null, text?: { __typename?: 'TextText', json: any } | null, button?: { __typename?: 'InternalLinkWithIcon', linkText?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null } | null } | null } | null> } | null } | null> } | null } | null };


export const GetAccordionDocument = gql`
    query getAccordion($id: String!, $preview: Boolean) {
  accordion(id: $id, preview: $preview) {
    __typename
    title
    titleBackgroundColour
    panelsCollection(limit: 15) {
      ... on AccordionPanelsCollection {
        items {
          title
          contentCollection {
            items {
              ... on Text {
                title
                text {
                  json
                }
                backgroundColour
              }
              ... on TextOnly2Column {
                __typename
                textLeft {
                  title
                  text {
                    json
                  }
                  backgroundColour
                  button {
                    ... on UrlWithIcon {
                      linkText
                      linkUrl
                      iconClass
                      style
                      openLinkInNewBrowserTab
                    }
                    ... on InternalLinkWithIcon {
                      linkText
                      linkDestination {
                        ... on Page {
                          slug
                        }
                      }
                      iconClass
                      style
                      openLinkInNewBrowserTab
                    }
                  }
                }
                textRight {
                  title
                  text {
                    json
                  }
                  backgroundColour
                  button {
                    ... on UrlWithIcon {
                      linkText
                      linkUrl
                      iconClass
                      style
                      openLinkInNewBrowserTab
                    }
                    ... on InternalLinkWithIcon {
                      linkText
                      linkDestination {
                        ... on Page {
                          slug
                        }
                      }
                      iconClass
                      style
                      openLinkInNewBrowserTab
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type GetAccordionQueryResult = Apollo.QueryResult<GetAccordionQuery, GetAccordionQueryVariables>;