import { UTM_PERSISTENCE_PERIOD, UTM_UPDATED } from '@constants';

export const combineUrlParams = (params: {[key: string]: any}) => {
  let combinedParams = Object.keys(params)
    .filter((key: string) => (
      key !== UTM_UPDATED &&
      key !== UTM_PERSISTENCE_PERIOD
    ))
    .map((key: string) => `${key}=${params[key as string]}`)
    .join('&');

  const { source, utm_campaign } = params;
  if (!source && utm_campaign) {
    combinedParams += `&source=${utm_campaign}`;
  }

  return combinedParams;
};