import React from 'react';
import styles from './checkBox.module.scss';


interface CheckBoxProps {
  checked?: boolean;
  label: string;
  onChange?: () => void;
}

export const CheckBox = ({
  checked = false,
  label,
  onChange = () => {}
}: CheckBoxProps) => {

  const onEnter = (code: string) => {
    if (code === 'Enter') {
      onChange();
    }
  };

  return (
    <div
      className={styles.container}
      onClick={onChange}
      data-testid="checkbox-wrapper"
    >
      <input
        data-testid="checkbox"
        type="checkbox"
        role="checkbox"
        checked={checked}
        aria-checked={checked}
        aria-labelledby={label}
        onChange={onChange}
        onKeyPress={(e) => onEnter(e.code)} />
      <span className={styles.checkmark}></span>
      <span className={styles.label}>{label}</span>
    </div>
  );
};
