import { getHeader, IContentTypeQuery, getFilterData } from '@contentful';
import { GetHomePageDocument } from '@graphql/queries/getHomePage.generated';

export type { IHomeLayout, IHomeSys } from './interfaces';

export const homePage: IContentTypeQuery = {
  query: GetHomePageDocument,
  returnParam: 'homePage',
  type: 'HomePage',
  params: {
    headerSys: {
      returnParam: 'header',
      customTransform: async (data: any) => {
        const { error, header } = await getHeader(data.client, data.sys.id, data.preview, data.slugs, data.env);
        return {
          error,
          data: header
        };
      }
    },
    heroSys: {
      returnParam: 'hero',
    },
    jobSearchBoxSys: {
      returnParam: 'jobSearchBox'
    },
    searchFilterAndSortingSettingsSys: {
      returnParam: 'searchFilterAndSortingSettings',
      customTransform: async (data: any) => {
        const { error, jobSearchResultsFilterAndSortSettings } = await getFilterData(
          data.client,
          data.sys.id,
          data.preview,
          data.globalSettings
        );
        return {
          error,
          data: jobSearchResultsFilterAndSortSettings
        };
      }
    },
    searchResultsSettingsSys: {
      returnParam: 'searchResultsSettings'
    },
    contentSys: {
      returnParam: 'content'
    },
    footerSys: {
      returnParam: 'footer'
    }
  }
};