import { ApolloClient, InMemoryCache } from '@apollo/client';
import {
  IContentSys,
  IPageSys,
  ISharedAttributes,
  ISlugItem,
  IPageSlug,
  PageContent,
  PageTypeResponse,
  IBlogPagginationMapping,
  IEventPagginationMapping
} from './interfaces';
import { safeQuery } from '@lib/apollo-client';
import { GetPageDocument } from '@graphql/queries/getPage.generated';
import { GetSlugsDocument } from '@graphql/queries/getSlugs.generated';
import { calculateBlogLandingPagesTotal, calculateEventLandingPagesTotal, getBlogLandingPagesContent, getContentBlocks, getData, getEventLandingPagesContent, getGlobalSettings, IBlog, IEvent } from '@contentful';
import { contentTypes } from './contentTypes';
import { ALL_CATEGORY, HOME_PAGE_URL } from '@constants';
import { convertToSlug, logger } from '@utils';


function findFirstBasePage(pages: IPageSys[], slug: string[]) {
  for (let i = slug.length - 1; i >= 0; i--) {
    const page = pages.find((pageData: IPageSys) => pageData.slug === slug[i]);
    if (page) {
      return page;
    }
  }

  return pages[0];
}

export async function getPage(
  client: ApolloClient<InMemoryCache>,
  slug: string,
  preview: boolean,
  env?: string,
  synonymousSlugs: string[] | null = null // Required for blog category because we don't have them in contentful
) {
  const currentSlug = slug === HOME_PAGE_URL ? '/' : slug.split('/').pop();
  const { data, error } = await safeQuery(client, GetPageDocument, {
    slugs: [currentSlug, ...(synonymousSlugs || [])],
    preview
  }, env
  );

  if (error) {
    return {
      pageError: error
    };
  }

  const { globalSettings } = await getGlobalSettings(client, preview, env);
  const page: IPageSys = data.pageCollection.items.find((pageData: IPageSys) => pageData.slug === currentSlug) ||
    findFirstBasePage(data.pageCollection.items, synonymousSlugs || []);

  if (!page) {
    return {
      pageError: 'Page not found'
    };
  }

  const slugs = await getSlugs(client, preview, env, true);

  const { contentSys, seo, sys } = page;
  return getPageType(client, contentSys, preview, { seo, slug, globalSettings, slugs, sys, synonymousSlugs }, env);
}

async function getPageType(
  client: ApolloClient<InMemoryCache>,
  content: IContentSys,
  preview: boolean,
  sharedAttributes: ISharedAttributes,
  env?: string
): Promise<PageTypeResponse> {
  const contentType = contentTypes[content?.type];

  if (!contentType) {
    return {
      pageError: 'Unknown page type',
      ...sharedAttributes
    };
  }

  const { query, returnParam, params, type } = contentType;

  const {
    data,
    error: contentError
  } = await safeQuery(client, query, {
    id: content.sys.id,
    preview
  }, env);

  let pageError;
  if (contentError) {
    logger.error(`[Page] Error occurred during content request: ${JSON.stringify(contentError, null, 2)}`);
    pageError = contentError;
    return {
      ...sharedAttributes,
      pageError: pageError || null
    } as PageTypeResponse;
  }

  const layout: PageContent = data[returnParam];
  const returnObject: { [key: string]: any } = {
    type
  };
  for(const [sysKey, sysData] of Object.entries(layout)) {
    const param = params[sysKey];
    if (!param) {
      returnObject[sysKey] = sysData;
      continue;
    }
    const { customTransform, returnParam } = params[sysKey];
    if (!sysData) {
      returnObject[returnParam] = null;
    } else if (customTransform) {
      const { data, error } = await customTransform({
        client,
        preview,
        env,
        ...sharedAttributes,
        ...sysData,
        contentId: content.sys.id,
        pageLayout: layout
      });
      error && (pageError = error);
      returnObject[returnParam] = data || null;
    } else if (sysData.items) {
      const { blocks, error } = await getContentBlocks(client, sysData.items, preview, sharedAttributes.slugs, env);
      error && (pageError = error);
      returnObject[returnParam] = blocks || [];
    } else {
      const { queryResult, error } = await getData(
        client,
        sysData?.sys?.id || '',
        preview,
        sysData.type,
        sharedAttributes.slugs,
        env
      );
      error && (pageError = error);
      returnObject[returnParam] = queryResult || null;
    }
  }

  return {
    ...returnObject,
    ...sharedAttributes,
    pageError: pageError || null
  } as PageTypeResponse;
}

export function getFullSlugs({ slug, sys }: IPageSlug, slugs: IPageSlug[], suffix: string[]): string[][] {
  const params: string[][] = [];
  const newSuffix = [slug, ...suffix];

  let childrenCollection;
  slugs.forEach((page) => {
    childrenCollection = page.childrenCollection;
    if (!childrenCollection) {
      return;
    }

    childrenCollection.items.forEach((child) => {
      if (child && child.sys.id === sys.id) {
        params.push(...getFullSlugs(page, slugs, newSuffix));
      }
    });
  });

  if (params.length === 0) {
    params.push(newSuffix);
  }

  return params;
}

export function getBlogPaginationMappings(blogs: IBlog[]) {
  const blogPaginationMappings: { [key: string]: IBlogPagginationMapping } = {
    [ALL_CATEGORY]: {
      featured: 0,
      blogs: 0
    }
  };
  blogs.forEach((blog) => {
    if (!blog || !blog.category) {
      return;
    }

    if (!blogPaginationMappings[blog.category]) {
      blogPaginationMappings[blog.category] = {
        featured: 0,
        blogs: 0
      };
    }

    blog.landingFeatured ?
      blogPaginationMappings[ALL_CATEGORY].featured += 1 :
      blogPaginationMappings[ALL_CATEGORY].blogs += 1;

    blog.categoryFeatured ?
      blogPaginationMappings[blog.category].featured += 1 :
      blogPaginationMappings[blog.category].blogs += 1;
  });

  return blogPaginationMappings;
}

export function getEventsPaginationMappings(events: IEvent[]) {
  const now = new Date();
  const eventPaginationMappings: { [key: string]: IEventPagginationMapping } = {
    [ALL_CATEGORY]: {
      upcoming: 0,
      past: 0
    }
  };
  events.forEach((event) => {
    if (
      !event ||
      !event.startDate ||
      !event.topic
    ) {
      return;
    }

    const startDate = new Date(event.startDate);

    if (!eventPaginationMappings[event.topic]) {
      eventPaginationMappings[event.topic] = {
        upcoming: 0,
        past: 0
      };
    }

    if (startDate > now) {
      eventPaginationMappings[event.topic].upcoming += 1;
      eventPaginationMappings[ALL_CATEGORY].upcoming += 1;
      return;
    }

    eventPaginationMappings[event.topic].past += 1;
    eventPaginationMappings[ALL_CATEGORY].past += 1;
  });

  return eventPaginationMappings;
}

export async function getSlugs(
  client: ApolloClient<InMemoryCache>,
  preview: boolean,
  env?: string,
  withContentId: boolean = false,
): Promise<ISlugItem[]> {
  const { data, error } = await safeQuery(client, GetSlugsDocument, {
    exclude: ['/', '404', '500'],
    preview,
    skip: 0
  }, env);

  if (error) {
    return [];
  }

  const { items: basePage, total, limit } = data.pageCollection;
  const items = [...basePage];
  if(total > limit) {
    const requests = Math.floor(total / limit);

    for(let i = 0; i < requests; i++) {
      const { data: nextPageData, error: nextPageError } = await safeQuery(client, GetSlugsDocument, {
        exclude: ['/', '404', '500'],
        preview,
        skip: (i + 1) * limit
      }, env);

      if (!nextPageError) {
        items.push(...nextPageData.pageCollection.items);
      }
    }
  }

  const blogLandingPages = await getBlogLandingPagesContent(client, preview, env);
  const eventLandingPages = await getEventLandingPagesContent(client, preview, env);

  //const { items } = data.pageCollection;
  const paramArray: ISlugItem[] = [];

  const pushToParams = (item: IPageSlug, paramsSlug: string[]) => paramArray.push({
    ...(withContentId && {
      contentId: item?.content?.sys?.id || '',
      id: item?.sys?.id || ''
    }),
    params: {
      slug: [...paramsSlug]
    }
  });

  items.forEach((item: IPageSlug) => {
    getFullSlugs(item, items, []).forEach((slug) => {
      if (
        item.content?.type === 'BlogLandingPage' &&
        item.content?.sys?.id
      ) {
        const content = blogLandingPages.find((page) => page.sys.id === item.content.sys.id);
        const blogs = content?.pagesCollection?.items || [];

        const blogPaginationMappings = getBlogPaginationMappings(blogs);

        Object.entries(blogPaginationMappings).forEach(([category, mapping]) => {
          const pages = calculateBlogLandingPagesTotal(mapping.featured, mapping.blogs, !!content?.feedCard);
          const categoryPageSlug = category === ALL_CATEGORY ? slug : [...slug, convertToSlug(category || '')];
          pushToParams(item, categoryPageSlug);

          for(let i = 2; i <= pages; i++) {
            pushToParams(item, [...categoryPageSlug, `${i}`]);
          }
        });
      }

      if (item.content?.type === 'BlogPage' && slug.length > 1) {
        const category = convertToSlug(item.content.category || '');
        if(!category) {
          pushToParams(item, slug);
          return;
        }

        slug.splice(-1, 0, category);
        pushToParams(item, slug);
        return;
      }

      if (item.content?.type === 'EventPage' && slug.length >= 1) { //Replace >= to > after event landing implementation
        const topic = convertToSlug(item.content.topic || '');
        if (!topic) {
          pushToParams(item, slug);
          return;
        }

        slug.splice(-1, 0, topic);
        pushToParams(item, slug);
        return;
      }

      if (
        item.content?.type === 'EventLandingPage' &&
        item.content?.sys?.id
      ) {
        const content = eventLandingPages.find((page) => page.sys.id === item.content.sys.id);
        const events = content?.eventsCollection?.items || [];

        const eventsPaginationMappings = getEventsPaginationMappings(events);

        Object.entries(eventsPaginationMappings).forEach(([topic, mapping]) => {
          const pages = calculateEventLandingPagesTotal(mapping.upcoming, mapping.past);
          const topicPageSlug = topic === ALL_CATEGORY ? slug : [...slug, convertToSlug(topic || '')];
          pushToParams(item, topicPageSlug);

          for (let i = 2; i <= pages; i++) {
            pushToParams(item, [...topicPageSlug, `${i}`]);
          }
        });
      }

      pushToParams(item, slug);
    });
  });

  return paramArray;
}
