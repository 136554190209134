import Head from 'next/head';
import { useRouter } from 'next/router';
import { OCADO_ORIGIN } from './constants';


const CanonicalTag = () => {
  const router = useRouter();
  const canonicalUrl = (OCADO_ORIGIN + (router.asPath === '/' ? '': router.asPath)).split('?')[0];

  return (
    <Head>
      <link rel="canonical" href={canonicalUrl} />
    </Head>
  );
};

export default CanonicalTag;
