/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetHeroCarouselQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetHeroCarouselQuery = { __typename?: 'Query', heroCarousel?: { __typename: 'HeroCarousel', title?: string | null, slidesCollection?: { __typename?: 'HeroCarouselSlidesCollection', items: Array<{ __typename?: 'HeroImage', imageHeight?: string | null, subtitle?: string | null, textIconPrefixClass?: string | null, title?: string | null, button?: { __typename?: 'InternalLinkWithIcon', linkText?: string | null, openLinkInNewBrowserTab?: boolean | null, iconClass?: string | null, style?: string | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, openLinkInNewBrowserTab?: boolean | null, iconClass?: string | null, style?: string | null } | null, image?: { __typename?: 'Asset', url?: string | null, title?: string | null, width?: number | null, height?: number | null } | null, mobileImage?: { __typename?: 'Asset', url?: string | null, title?: string | null, width?: number | null, height?: number | null } | null } | null> } | null } | null };


export const GetHeroCarouselDocument = gql`
    query getHeroCarousel($id: String!, $preview: Boolean) {
  heroCarousel(id: $id, preview: $preview) {
    __typename
    title
    slidesCollection {
      items {
        ... on HeroImage {
          button {
            ... on UrlWithIcon {
              linkText
              linkUrl
              openLinkInNewBrowserTab
              iconClass
              style
            }
            ... on InternalLinkWithIcon {
              linkText
              openLinkInNewBrowserTab
              iconClass
              style
              linkDestination {
                slug
              }
            }
          }
          image {
            url
            title
            width
            height
          }
          mobileImage {
            url
            title
            width
            height
          }
          imageHeight
          subtitle
          textIconPrefixClass
          title
        }
      }
    }
  }
}
    `;
export type GetHeroCarouselQueryResult = Apollo.QueryResult<GetHeroCarouselQuery, GetHeroCarouselQueryVariables>;