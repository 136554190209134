import { ApolloClient, DefaultOptions, DocumentNode, from, HttpLink, InMemoryCache, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import fetch from 'cross-fetch';
import { IClientConfig, IContentfulErrorExtension } from './interfaces';
import { GraphQLErrors } from '@apollo/client/errors';
import { logger } from '@utils';
import { StatusCodes } from 'http-status-codes';
import { QUERY_ERROR_START, RATE_LIMIT_EXCEEDED } from 'constants/contentful-gql';
import { buildContentulEndpoint } from '@utils';


let client: any = null;
let criticalError: GraphQLErrors | Error | null | undefined = null;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache'
  },
  query: {
    fetchPolicy: 'no-cache'
  }
};

export function initApolloClient({ uri, authHeader, preview }: IClientConfig) {
  const link = new HttpLink({
    uri: uri, fetch, headers: {
      'Authorization': authHeader || ''
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    const gqlFilteredError = graphQLErrors?.filter((error) => !error.message.startsWith(QUERY_ERROR_START))[0];
    criticalError = gqlFilteredError || networkError;
    if (gqlFilteredError) {
      logger.error(
        `[Apollo][GraphQL error][${operation.operationName}]: Message: ${gqlFilteredError.message}, Full error: ${JSON.stringify(gqlFilteredError.extensions, null, 2)}`
      );
      if ((gqlFilteredError.extensions?.contentful as IContentfulErrorExtension)?.code === RATE_LIMIT_EXCEEDED) {
        criticalError = null;
        forward(operation);
      }
    }
    if (networkError) {
      if ((networkError as ServerError).statusCode === StatusCodes.TOO_MANY_REQUESTS) {
        logger.error(
          `[Apollo][Server error][${operation.operationName}][Too many requests]: Message: ${networkError.message}. Full error: ${JSON.stringify(networkError, null, 2)}`
        );
      }
      logger.error(
        `[Apollo][Server error][${operation.operationName}]: Message: ${networkError.message}. Full error: ${JSON.stringify(networkError, null, 2)}`
      );
    }
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 500,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error
    }
  });

  const config: any = {
    cache: new InMemoryCache(),
    link: {
      ...from([retryLink, errorLink, link]),
      options: link.options,
    },
  };

  if (preview) {
    config.defaultOptions = defaultOptions;
  }

  client = new ApolloClient(config);

  return client;
}

export async function safeQuery(
  client: ApolloClient<InMemoryCache>,
  query: DocumentNode,
  variables?: any,
  env?: any,
) {
  const context = env ? {
    uri: buildContentulEndpoint(process.env.CONTENTFUL_SPACE, env),
  } : {};

  try {
    const { data, error } = await client.query({
      query,
      variables,
      context,
      errorPolicy: 'all'
    });

    return {
      data,
      error: error && `[Apollo Client] ${error.networkError}: ${error.message}`
    };
  } catch (error: any) {
    logger.error(`[Apollo Client] ${error.networkError}: ${error.message}`);

    return {
      error: criticalError ? JSON.stringify(criticalError, null, 2) : `[Apollo Client] ${error.statusCode}: ${error.message}`,
      data: null
    };
  }
}

export default client;
