import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { Content, JobSearchBox, SearchPage, SitemapPage, BlogPage } from '@components';
import { IPage } from './interfaces';
import styles from 'styles/page.module.scss';
import {
  INTERNAL_SERVER_ERROR_PAGE_SLUG,
  INTERNAL_SERVER_ERROR_PAGE_URL,
  PAGE_NOT_FOUND_SLUG,
} from '@constants';
import { searchVacancies } from '@lib/cloud-search';
import { EContentTypes, IBlogLandingLayout, IBlogLayout, IEventLayout, ISitemapLayout, IEventLandingLayout } from '@contentful';
import { logger } from '@utils';
import { BlogLandingPage } from '../BlogLandingPage';
import { EventPage } from '../EventPage';
import { EventLandingPage } from '../EventLandingPage';


type PageProps = {
  page: IPage | ISitemapLayout
}

const pageComponents: { [key: string]: (page: any) => JSX.Element } = {
  [EContentTypes.searchResultsPage]: (page: IPage) => <SearchPage page={page} onSearch={searchVacancies} />,
  [EContentTypes.sitemapPage]: (page: ISitemapLayout) => <SitemapPage page={page} />,
  [EContentTypes.blogPage]: (page: IBlogLayout) => <BlogPage page={page} />,
  [EContentTypes.blogLandingPage]: (page: IBlogLandingLayout) => <BlogLandingPage page={page} />,
  [EContentTypes.eventPage]: (page: IEventLayout) => <EventPage page={page} />,
  [EContentTypes.eventLandingPage]: (page: IEventLandingLayout) => <EventLandingPage page={page} />
};

const Page = ({ page }: PageProps) => {
  const { seo, pageError, slug, type } = page;
  const router = useRouter();

  const isErrorPage = router.asPath === `/${INTERNAL_SERVER_ERROR_PAGE_SLUG}` ||
    router.asPath === `/${PAGE_NOT_FOUND_SLUG}`;

  useEffect(() => {
    if (pageError && !isErrorPage) {
      router.push(INTERNAL_SERVER_ERROR_PAGE_URL);
    }

    logger.error('logging is ON');
  }, []);

  // TODO: replace with loading screen
  if (pageError) {
    logger.error(`[Page] Critical error occurred on the ${slug} page: ${pageError}`);
  }

  const pageComponent = pageComponents[type];

  if(pageComponent) {
    return pageComponent(page);
  }

  const { content, jobSearchBox, hero, blogGrid } = page as IPage;

  return (
    <div className={styles.page}>
      <Head>
        <title>{seo?.title}</title>
        <meta name="description" content={seo?.description ?? ''}/>
      </Head>
      <main className={styles.main} id="mainContent">
        {hero && (
          <Content content={[hero]} mainHeader={true}/>
        )}
        {jobSearchBox && (
          <JobSearchBox searchBox={jobSearchBox}/>
        )}
        {blogGrid && (
          <Content content={[blogGrid]} />
        )}
        {content && (
          <Content content={content} isOverlapped={!!jobSearchBox?.overlay}/>
        )}
      </main>
    </div>
  );
};

export default Page;
