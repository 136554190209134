import axios from 'axios';
import { logger } from '@utils';


const suggestionsLimit = 3;

const getSuggestHeaders = () => {
  const api_key = process.env.CLOUDSEARCH_API_KEY;
  const headers = {};

  if (api_key) {
    Object.assign(headers, { 'x-api-key': api_key });
  }

  return headers;
};

export async function getSuggestions(
  api: string,
  value: string,
  suggester: string,
): Promise<any> {
  try {
    const { data } = await axios.get(api, {
      params: {
        q: encodeURIComponent(value),
        size: suggestionsLimit,
        suggester: suggester,
      },
      headers: getSuggestHeaders()
    });
    return data;
  } catch (error: any) {
    logger.error(`[Suggestions] Error occurred: ${JSON.stringify(error)}`);
    return { error };
  }
}

