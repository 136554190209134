/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetTextContentQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetTextContentQuery = { __typename?: 'Query', text?: { __typename: 'Text', title?: string | null, backgroundColour?: string | null, text?: { __typename?: 'TextText', json: any } | null, button?: { __typename?: 'InternalLinkWithIcon', linkText?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null } | null } | null };


export const GetTextContentDocument = gql`
    query getTextContent($id: String!, $preview: Boolean) {
  text(id: $id, preview: $preview) {
    __typename
    title
    text {
      json
    }
    backgroundColour
    button {
      ... on UrlWithIcon {
        linkText
        linkUrl
        iconClass
        style
        openLinkInNewBrowserTab
      }
      ... on InternalLinkWithIcon {
        linkText
        linkDestination {
          ... on Page {
            slug
          }
        }
        iconClass
        style
        openLinkInNewBrowserTab
      }
    }
  }
}
    `;
export type GetTextContentQueryResult = Apollo.QueryResult<GetTextContentQuery, GetTextContentQueryVariables>;