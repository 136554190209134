import axios from 'axios';
import { IVacanciesHits, IVacanciesResponse } from './';
import { defaultSearchSize, matchallQueryParams, RELEVANCE, REMOTE_LOCATION, searchableIndexes } from '@constants';
import { ISearchParams } from './interfaces';
import { IArea, ILocation } from '@componentTypes';
import { convertAreaValue, convertSearchValue, logger } from '@utils';



const getSearchParams = (search: ISearchParams) => {
  const { query, area, location, sort, pageNumber = 0, position, size } = search;

  const params: any = query ? (
    {
      q: convertSearchValue(query),
      'q.options': `{fields:${JSON.stringify(searchableIndexes)}}`
    }
  ) : (
    {
      ...matchallQueryParams
    }
  );

  let areaFilterQ = '';
  if (area && area.length > 0) {
    areaFilterQ = `(or ${area.map((elem: IArea) => `area:'${convertAreaValue(elem.id)}'`).join(' ')})`;
  }

  let locationFilterQ = '';
  if (location && location.length > 0) {
    const cities =
      location.map((elem: ILocation) => elem.value === REMOTE_LOCATION ? `city:'${elem.value}'`:`city:'${elem.id}'`);
    const cityFilterQ = `(or ${cities.join(' ')})`;
    const countryFilterQ = `(or ${location.map((elem: ILocation) => `country:'${elem.country}'`).join(' ')})`;
    locationFilterQ = `(and ${cityFilterQ} ${countryFilterQ})`;
  }

  if (position) {
    params['expr.distance'] = `haversin(${position?.latitude},${position?.longitude}, geolocation.latitude, geolocation.longitude)`;
  }


  if (areaFilterQ || locationFilterQ) {
    params.fq = `(and ${areaFilterQ} ${locationFilterQ})`;
  }

  params.sort = sort || RELEVANCE;
  params.start = (pageNumber - 1) * defaultSearchSize;
  params.size = size || defaultSearchSize;

  return params;
};

const getSearchHeaders = () => {
  const api_key = process.env.CLOUDSEARCH_API_KEY;
  const headers = {};

  if (api_key) {
    Object.assign(headers, { 'x-api-key': api_key });
  }

  return headers;
};

export async function searchVacancies(
  api: string,
  search: ISearchParams,
): Promise<IVacanciesHits> {
  try {
    const { data } = await axios.get(api, { params: getSearchParams(search), headers: getSearchHeaders() });
    const { hits, error }: IVacanciesResponse = data;

    if (error) {
      logger.error(`[CloudSearch] Error while searching for vacancies: ${JSON.stringify(error)}`);
      return { hit: [], found: 0, error };
    }

    const { hit = [], found = 0 } = hits || {};
    return { hit, found };
  } catch (error: any) {
    logger.error(`[CloudSearch] Error occurred: ${JSON.stringify(error)}`);
    return { hit: [], found: 0, error };
  }
}