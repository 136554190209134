/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetVideoQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetVideoQuery = { __typename?: 'Query', video?: { __typename: 'Video', title?: string | null, youtubeEmbedUrl?: string | null } | null };


export const GetVideoDocument = gql`
    query getVideo($id: String!, $preview: Boolean) {
  video(id: $id, preview: $preview) {
    __typename
    title
    youtubeEmbedUrl
  }
}
    `;
export type GetVideoQueryResult = Apollo.QueryResult<GetVideoQuery, GetVideoQueryVariables>;