import React from 'react';
import { Icon } from '@components';
import styles from './componentHeading.module.scss';


type Props = {
  title: string,
}

export const ComponentHeading = ({ title }: Props) => {
  return (
    <div className={styles.heading}>
      <Icon
        icon={['fas', 'arrow-down-long']}
        className={styles.heading__icon}
        data-testid="icon"/>
      <h2>{title}</h2>
    </div>
  );
};
