/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetJobSearchBoxQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetJobSearchBoxQuery = { __typename?: 'Query', jobSearchBox?: { __typename?: 'JobSearchBox', placeholderText?: string | null, searchButtonText?: string | null, backgroundColour?: string | null, overlay?: boolean | null, searchRedirect?: { __typename?: 'Page', slug?: string | null } | null } | null };


export const GetJobSearchBoxDocument = gql`
    query getJobSearchBox($id: String!, $preview: Boolean) {
  jobSearchBox(id: $id, preview: $preview) {
    placeholderText
    searchButtonText
    backgroundColour
    overlay
    searchRedirect {
      slug
    }
  }
}
    `;
export type GetJobSearchBoxQueryResult = Apollo.QueryResult<GetJobSearchBoxQuery, GetJobSearchBoxQueryVariables>;