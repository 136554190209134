import { IVacanciesRecord } from 'lib/cloud-search';
import styles from './jobList.module.scss';
import { convertDate } from '@utils';
import { JobItem } from '@components';


type JobListProps = {
  jobs: IVacanciesRecord[] | null;
  moreDetailsButtonText: string;
  athosLabelMappings: any[];
  updateSession?: () => void;
}

export const JobList = (
  {
    jobs,
    moreDetailsButtonText,
    athosLabelMappings,
    updateSession = () => {}
  }: JobListProps
) => {
  return (
    <div className={styles.jobList}
      data-testid="jobList">
      <div className={styles.jobList__wrapper}>
        {jobs && jobs.map(({ id, fields }) => (
          <JobItem
            key={`${id} - ${fields.title}`}
            id={id}
            fields={fields}
            moreDetailsButtonText={moreDetailsButtonText}
            convertDate={convertDate}
            updateSession={updateSession}
          />
        ))}
      </div>
    </div>
  );
};
