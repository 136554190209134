import { Vacancy, VacancyTypes } from '@lib/vacancies-data';
import { fixedJobPrefixes } from '@constants';


const checkJobIdIsAthos = (id: string) => {
  const athosIdRegExp = new RegExp('^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$');
  return athosIdRegExp.test(id);
};

const checkJobIdIsTalkPush = (id: string) => {
  const talkPushRegExp = new RegExp('^(tp|TP)-[0-9]+$');
  return talkPushRegExp.test(id);
};

const checkJobIdIsGreenHouse = (id: string) => {
  const greenHouseRegExp = new RegExp('^(gh|GH)-[0-9]+$');
  return greenHouseRegExp.test(id);
}

// Check vacancy type by id. Defaults to WorkDay.
export const getVacancyTypeById = (id: string): VacancyTypes => {
  if (checkJobIdIsAthos(id)) {
    return VacancyTypes.athos;
  }

  if (checkJobIdIsTalkPush(id)) {
    return VacancyTypes.talkPush;
  }

  if (checkJobIdIsGreenHouse(id)) {
    return VacancyTypes.greenHouse;
  }

  return VacancyTypes.unknown;
};

// Check if vacancy is internal
export const isVacancyInternal = (vacancy: Vacancy): boolean => {
  const vacancyType = getVacancyTypeById(vacancy.id);

  // Collect fixed jobs from Athos & TalkPush only
  if (vacancyType === VacancyTypes.athos || vacancyType === VacancyTypes.talkPush) {
    return fixedJobPrefixes.some((prefix) => vacancy.title.startsWith(prefix));
  }

  return false;
};

// Get vacancy mapped area
export const getVacancyArea = (athosLabelMappings: any, vacancy: Vacancy): string => {
  const mapping = athosLabelMappings.find((label: any) => label.key == vacancy.area);
  return mapping ? mapping.value : vacancy.area;
}
