import { ReactNode } from 'react';
import { TrackingContext } from 'contexts/TrackingContext';

const TrackingProvider = ({ children }: { children: ReactNode, trackingID?: string }) => {
  const logEvent = ({ event = '', category = '', action = '', label = '' }) => {
    if (window && window?.dataLayer) {
      const gtmEvent = { event, category, action, label };
      window.dataLayer.push(gtmEvent);
    }
  };

  return (
    <TrackingContext.Provider value={{ logEvent }}>
      {children}
    </TrackingContext.Provider>
  );
};

export default TrackingProvider;
