import React from 'react';
import Image from 'next/image';

import { BLOCKS, Document } from '@contentful/rich-text-types';
import { isAssetImage } from '@contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { usePrefetchAssets } from '@hooks/usePrefetchAssets';

import styles from './RichText.module.scss';


type Props = {
  richText: Document | any;
  className?: string;
}

export const RichText = ({ richText, className }: Props) => {
  const { content = [] } = richText;
  const assets = usePrefetchAssets(content);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node:any, children:any) => {
        const isEmptyChildren = children?.toString().trim() === '';

        if (isEmptyChildren) return null;

        return <p className={className}>{children}</p>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const { id } = node?.data?.target?.sys || {};
        const asset = assets[id] || null;

        if (asset) {
          if (isAssetImage(asset)) {
            return (
              <p className={styles.richText__embedded} data-testid="rich-text-embedded-asset">
                <span className={styles.richText__embeddedWrapper}>
                  <Image
                    className={styles.richText__embeddedImg}
                    src={asset?.url || ''}
                    alt={asset?.title || ''}
                    layout="responsive"
                    objectFit="contain"
                    objectPosition="center"
                    width={asset.width}
                    height={asset.height}
                    loader={({ width }) => `${asset?.url}?w=${width}&fm=webp`}
                    placeholder="blur"
                    blurDataURL={`${asset?.url}?w=10&q=25`}
                  />
                </span>
                {asset.description && (
                  <span className={styles.richText__embeddedDescription}>{asset.description}</span>
                )}
              </p>
            );
          }
        }

        return null;
      }
    },
  };

  return <>{documentToReactComponents(richText, options)}</>;
};
