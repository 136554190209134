import React from 'react';
import styles from './input.module.scss';
import { Icon } from '@components';
import { IInputProps } from './interfaces';


export const Input = ({
  defaultValue = '',
  type = 'text',
  disabled = false,
  error = false,
  large = false,
  hasIcon = 'none',
  icon = ['fas', 'magnifying-glass'],
  className = '',
  label = '',
  ...props
}: IInputProps) => {
  const isLarge = styles[large ? 'input--large' : ''];
  const isError = styles[error ? 'input--error' : ''];
  const withIcon = styles[`input--icon-${hasIcon}`];

  return (
    <div className={[className, styles.wrapper].join(' ')}>
      {hasIcon !== 'none' && <Icon
        icon={icon}
        className={[styles.icon, styles[`icon--${hasIcon}`]].join(' ')}
      />}
      <input
        className={[styles.input, isLarge, isError, withIcon].join(' ')}
        type={type}
        disabled={disabled}
        aria-label={label}
        {...props}
      />
    </div>
  );
};
