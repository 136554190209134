import { IApplyButtonProps } from './interfaces';
import { ButtonLink } from '@components';
import { InternalLinkWithIcon, UrlWithIcon } from '@graphql/types';
import { ButtonThemeType } from '@componentTypes';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


export const ApplyButton = (
  {
    button,
    applyUrl,
    ...props
  }: IApplyButtonProps) => {

  const linkText = button?.linkText || '';
  const theme = (button?.style?.toLowerCase() || 'accent') as ButtonThemeType;
  const icon = button?.iconClass as IconProp;
  const hasIcon = icon ? 'right' : 'none';
  const openLinkInNewBrowserTab = button?.openLinkInNewBrowserTab || false;

  let linkUrl: string;
  let externalButton = button as UrlWithIcon;
  let internalButton = button as InternalLinkWithIcon;

  if (button?.__typename === 'UrlWithIcon') {
    linkUrl = externalButton?.linkUrl || '';
  } else {
    linkUrl = internalButton?.linkDestination?.slug || '';
  }

  return (
    <ButtonLink
      label={linkText}
      theme={theme}
      icon={icon}
      hasIcon={hasIcon}
      external={openLinkInNewBrowserTab}
      data-testid="applyButton"
      href={applyUrl || linkUrl}
      {...props}
    />
  );
};
