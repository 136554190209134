/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetEventPageQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetEventPageQuery = { __typename?: 'Query', eventPage?: { __typename?: 'EventPage', name?: string | null, topic?: string | null, startDate?: any | null, endDate?: any | null, location?: string | null, locationMapUrl?: string | null, hybrid?: boolean | null, registrationUrl?: string | null, headerSys?: { __typename?: 'Header', type: 'Header', sys: { __typename?: 'Sys', id: string } } | null, heroSys?: { __typename?: 'HeroCarousel', type: 'HeroCarousel', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'HeroImage', type: 'HeroImage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'HeroText', type: 'HeroText' } | { __typename?: 'HeroTitle', type: 'HeroTitle', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'HeroVideo', type: 'HeroVideo', sys: { __typename?: 'Sys', id: string } } | null, contentSys?: { __typename?: 'EventPageContentCollection', items: Array<{ __typename?: 'Accordion', type: 'Accordion', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'CardGrid', type: 'CardGrid', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'Image', type: 'Image', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'InteractiveMap', type: 'InteractiveMap', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'Text', type: 'Text', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'TextImage2Column', type: 'TextImage2Column', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'TextOnly2Column', type: 'TextOnly2Column', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'Video', type: 'Video', sys: { __typename?: 'Sys', id: string } } | null> } | null, footerSys?: { __typename?: 'Footer', type: 'Footer', sys: { __typename?: 'Sys', id: string } } | null } | null };


export const GetEventPageDocument = gql`
    query getEventPage($id: String!, $preview: Boolean) {
  eventPage(id: $id, preview: $preview) {
    name
    headerSys: header {
      type: __typename
      sys {
        id
      }
    }
    heroSys: hero {
      type: __typename
      ... on HeroImage {
        sys {
          id
        }
      }
      ... on HeroCarousel {
        sys {
          id
        }
      }
      ... on HeroVideo {
        type: __typename
        sys {
          id
        }
      }
      ... on HeroTitle {
        type: __typename
        sys {
          id
        }
      }
    }
    topic
    startDate
    endDate
    location
    locationMapUrl
    hybrid
    registrationUrl
    contentSys: contentCollection {
      items {
        type: __typename
        ... on Text {
          sys {
            id
          }
        }
        ... on Accordion {
          sys {
            id
          }
        }
        ... on Image {
          sys {
            id
          }
        }
        ... on TextOnly2Column {
          sys {
            id
          }
        }
        ... on Video {
          sys {
            id
          }
        }
        ... on TextImage2Column {
          sys {
            id
          }
        }
        ... on CardGrid {
          sys {
            id
          }
        }
        ... on TextImage2Column {
          sys {
            id
          }
        }
        ... on InteractiveMap {
          sys {
            id
          }
        }
      }
    }
    footerSys: footer {
      type: __typename
      sys {
        id
      }
    }
  }
}
    `;
export type GetEventPageQueryResult = Apollo.QueryResult<GetEventPageQuery, GetEventPageQueryVariables>;