import React from 'react';
import { EventCard } from '@components';
import styles from './eventGrid.module.scss';
import { IEventCard } from '@componentTypes';


type Props = {
  cards: IEventCard[]
}

export const EventGrid = ({ cards }: Props) => (
  <div className={styles.wrapper}>
    <div
      className={styles.eventGrid}
      data-testid="event-grid">
      {cards.map((item: any, index: number) =>
        <div
          key={index}
          className={[
            styles.block,
          ].join(' ')}>
          <EventCard card={item} featured={false} />
        </div>
      )}
    </div>
  </div>
);

