import React, { FormEvent } from 'react';
import { Button, SearchSuggest } from '@components';
import styles from '@styles/SearchBar.module.scss';
import { JobSearchBox as SearchBoxType } from '@graphql/types';
import { ISuggestion, SearchBarTheme } from '@componentTypes';
import { InputProps, RenderSuggestion, SuggestionsFetchRequestedParams } from 'react-autosuggest';


export type SearchBoxProps = {
  searchBox: SearchBoxType | any;
  suggestions: ISuggestion[];
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onSuggestionsFetchRequested: ({ value }: SuggestionsFetchRequestedParams) => void;
  getSuggestionValue: (suggestion: ISuggestion) => string;
  renderSuggestion: RenderSuggestion<ISuggestion>;
  inputProps: InputProps<ISuggestion>;
  overlay?: boolean;
  theme?: SearchBarTheme;
}

export const SearchBoxSuggester = (
  {
    searchBox,
    suggestions,
    handleSubmit,
    onSuggestionsFetchRequested,
    getSuggestionValue,
    renderSuggestion,
    inputProps,
    overlay = true,
    theme = 'white',
  }: SearchBoxProps) => {

  return (
    <div className={styles[`searchbox--${overlay ? 'overlay' : theme}`]}>
      <form
        className={styles.wrapper}
        onSubmit={handleSubmit}
        data-testid="search-suggest"
        aria-label="search suggest"
      >
        <SearchSuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          className={styles.input}
          hasIcon="left"
        />
        <Button
          label={searchBox.searchButtonText || ''}
          theme="primary"
          large={true}
          type="submit"
          hasIcon="right"
          icon={['fas', 'magnifying-glass']}
          data-testid="search-button"
        />
      </form>
    </div>
  );
};
