import AWS from 'aws-sdk';
import { logger } from '@utils';
import { DataResultType } from '../vacancies-data';


export const getDataFromBucket = async (key: string): Promise<DataResultType> => {
  const s3 = new AWS.S3({
    accessKeyId: process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
    sessionToken: process.env.AWS_SESSION_TOKEN,
    region: process.env.AWS_REGION
  });
  const bucketName: string = process.env.AWS_S3_APP_BUCKET || '';
  const keyName = `${process.env.AWS_S3_APP_FOLDER}/${process.env.OCWS_TMP_FOLDER}/${key}`;
  try {
    if (!bucketName || !key) {
      return { result: [] };
    }

    const { Body: body } = await s3.getObject({ Bucket: bucketName, Key: keyName }).promise();
    const result = body && JSON.parse(body.toString()) || [];
    return { result };
  } catch (error: any) {
    logger.error(`[Bucket] Error occurred while reading jobs file from "${key}": ${JSON.stringify(error)}`);
    return { error: error?.message };
  }
};
