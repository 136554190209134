/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetImageQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetImageQuery = { __typename?: 'Query', image?: { __typename: 'Image', title?: string | null, fullWidth?: boolean | null, imageHeight?: string | null, image?: { __typename?: 'Asset', url?: string | null, title?: string | null, width?: number | null, height?: number | null } | null, mobileImage?: { __typename?: 'Asset', url?: string | null, title?: string | null, width?: number | null, height?: number | null } | null } | null };


export const GetImageDocument = gql`
    query getImage($id: String!, $preview: Boolean) {
  image(id: $id, preview: $preview) {
    __typename
    title
    fullWidth
    imageHeight
    image {
      url
      title
      width
      height
    }
    mobileImage {
      url
      title
      width
      height
    }
  }
}
    `;
export type GetImageQueryResult = Apollo.QueryResult<GetImageQuery, GetImageQueryVariables>;