import React, { useCallback, useRef } from 'react';
import { Button } from 'components';
import styles from './modal.module.scss';


interface ModalProps {
  closable?: boolean;
  children?: JSX.Element | JSX.Element[];
  onClose?: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  header?: string;
  applyButton?: string;
  onApply?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  resetButton?: string;
  onReset?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  visibility?: boolean;
  testID?: string;
}

export const Modal = ({
  closable = true,
  children = <></>,
  onClose = () => {},
  header = '',
  applyButton = '',
  resetButton = '',
  onApply = () => {},
  onReset = () => {},
  visibility = false,
  testID,
}: ModalProps) => {

  const onModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      onClose(event);
    }
  };

  const modelRef = useRef(null);

  const currentModal = modelRef.current as any;
  const modalIsOpen = currentModal?.getAttribute('aria-modal') === 'true';

  const closeModal = useCallback(() => {
    return !visibility && modalIsOpen;
  }, [visibility]);

  const onResetButtonEnterPress = useCallback(
    (e: React.KeyboardEvent<HTMLSpanElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const event = e as React.KeyboardEvent<HTMLSpanElement>;
      if(event.code === 'Enter'){
        onReset(e as React.MouseEvent<HTMLButtonElement, MouseEvent>);
      }
    }, []
  );

  return (
    <div
      className={[
        visibility && styles.animation_active,
        closeModal() && styles.animation_out,
        styles.animation, styles.modal
      ].join(' ')}
      onClick={onModalClick}
      aria-modal={!!visibility}
      ref={modelRef}
      data-testid={testID}
    >
      <div className={styles.modal__wrapper}>
        <div className={styles.modal__wrapper__header}>
          <span className={styles.modal__wrapper__header__text}>
            {header}
          </span>
          {resetButton && (
            <span
              tabIndex={0}
              className={styles.modal__wrapper__header__reset}
              onClick={onReset}
              onKeyPress={onResetButtonEnterPress}>
              {resetButton}
            </span>
          )}
          {applyButton && (
            <Button
              className={styles.applyButton}
              label={applyButton}
              onClick={onApply}
              theme="primary"
            />
          )}
          {closable && (
            <div className={styles.closeButton}>
              <Button
                dataTestId="close-button"
                label="close the modal"
                onClick={onClose}
                theme="light"
                outline={true}
                icon={['fas', 'close']}
                hasIcon="only"
              />
            </div>
          )}
        </div>
        <div className={styles.modal__wrapper__content}>
          {children}
        </div>
      </div>
    </div>
  );
};
