import React, { useEffect, useRef } from 'react';
import { ApplyButton, Icon } from '@components';
import { HeroVideo as IHeroVideo } from '@graphql/types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './heroVideo.module.scss';


type HeroVideoProps = {
  hero: IHeroVideo;
  mainHeader?: boolean;
}

export const HeroVideo = (
  {
    hero: {
      title,
      iconClass,
      subtitle,
      button,
      video,
      videoHeight
    },
    mainHeader
  }: HeroVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const size = videoHeight && videoHeight.replace(/\s/g, '_').toLowerCase();
  const shouldShowOverlay = title || subtitle || button;
  const ButtonComponent = button ? (
    <div className={styles.copy__cta}>
      <ApplyButton
        button={button}
      />
    </div>
  ) : undefined;

  useEffect(() => {
    window && (window.onpageshow = (event: any) => {
      if(event.persisted) {
        const { current } = videoRef;
        current && current.play();
      }
    });
  }, []);

  return (
    <div data-testid="heroVideo"
      className={[
        styles.wrapper,
        styles[size || ''],
        styles['hero--light'],
        styles['hero--black'],
      ].join(' ')}>
      {!!video && (
        <div className={[
          styles.video,
          styles[shouldShowOverlay ? '' : 'video--noOverlay'],
        ].join(' ')}>
          <video
            data-testid="video"
            autoPlay
            loop
            muted
            playsInline
            ref={videoRef}
          >
            <source
              src={video.url}
              type={video.contentType}
            />
          </video>
        </div>
      )}
      <div className={styles.copy}>
        {(title || subtitle) && (
          <div className={styles.copy__heading}>
            {title && (mainHeader ? (
              <h1>{title}</h1>
            ) : (
              <h2>{title}</h2>
            ))}
            {subtitle && (
              <p className={[styles.copy__subtitle, styles['hero--light']].join(' ')}>
                {iconClass && <Icon icon={iconClass as IconProp} />}
                {subtitle}
              </p>
            )}
          </div>
        )}
        {ButtonComponent}
      </div>
    </div>
  );
};
