import React from 'react';
import styles from './feedCard.module.scss';
import { ButtonLink } from '@components';
import { IFeedCard, IFeedSection } from '@contentful';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


type Props = {
  card: IFeedCard
}

export const FeedCard = ({ card }: Props) => {
  const {
    backgroundColor,
    sectionsCollection
  } = card;

  const theme = (
    backgroundColor === 'Blue' ||
    backgroundColor === 'Black'
  ) ? 'dark' : 'light';

  const renderSection = (section: IFeedSection) => (
    <div className={styles.feedCard__section}>
      {section?.title && <h3>{section.title}</h3>}
      {section?.link && <ButtonLink
        label={section.link.linkText}
        theme={theme}
        hasIcon="left"
        icon={(section.link?.iconClass || '') as IconProp}
        external={section.link.openLinkInNewBrowserTab}
        data-testid="feed-card-button"
        href={section.link?.linkDestination?.slug || '/'}
        className={styles.button}
        aria-label={`${section.link.linkText} about ${section?.title}`}
      />}
    </div>
  );

  const sections = sectionsCollection?.items || [];

  return (
    <div className={[styles.feedCard, styles[`feedCard--${backgroundColor?.toLowerCase()}`]].join(' ')}>
      {sections[0] && renderSection(sections[0])}
      {sections[1] &&  <div className={styles.feedCard__divider}/>}
      {sections[1] && renderSection(sections[1])}
    </div>
  );
};
