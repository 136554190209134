import React, { useEffect } from 'react';
import styles from './alert.module.scss';
import { IAlertProps } from './interfaces';


export const Alert = ({
  active,
  dataTestId,
  theme = 'default',
  outline = false,
  large = false,
  className,
  children,
  ...props
}: IAlertProps) => {
  useEffect(() => { }, []);
  return (
    <div
      data-testid={dataTestId}
      className={[
        styles.alert,
        active && styles['alert--active'],
        styles[`alert--${theme}`],
        styles[outline ? 'alert--outline' : ''],
        styles[large ? 'alert--large' : ''],
        className,
      ].join(' ')}
      {...props}
    >
      {children}
    </div>
  );
};
