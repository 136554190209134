import { getHeader, IContentTypeQuery } from '@contentful';
import { GetBlogPageDocument } from '@graphql/queries/getBlogPage.generated';


export type { IBlogLayout, IBlogSys } from './interfaces';

export const blogPage: IContentTypeQuery = {
  query: GetBlogPageDocument,
  returnParam: 'blogPage',
  type: 'BlogPage',
  params: {
    headerSys: {
      returnParam: 'header',
      customTransform: async (data: any) => {
        const { error, header } = await getHeader(data.client, data.sys.id, data.preview, data.slugs, data.env);
        return {
          error,
          data: header
        };
      }
    },
    heroSys: {
      returnParam: 'hero',
    },
    contentSys: {
      returnParam: 'content'
    },
    footerSys: {
      returnParam: 'footer'
    }
  }
};