import React from 'react';
import styles from './image.module.scss';
import { IImageProps } from './interfaces';
import { ComponentHeading } from 'components/molecules/ComponentHeading';


type Props = {
  contentData: IImageProps;
  imageComponent?: JSX.Element;
};

export const Image = ({ contentData, imageComponent }: Props) => {
  const {
    title,
    imageHeight = 'medium',
    fullWidth = false,
  } = contentData;

  return (
    <div data-testid="image"
      className={styles.image}>
      <div className={[
        styles.wrapper,
        styles[`wrapper--${fullWidth ? 'fullwidth' : 'container'}`],
        styles[`wrapper--${title ? 'with-title' : 'no-title'}`],
      ].join(' ')}>
        {title && <div className={styles.heading}><ComponentHeading title={title}/></div>}
        <div
          className={[
            styles.image,
            styles[`${imageHeight?.toLowerCase()}`],
          ].join(' ')}>
          {imageComponent}
        </div>
      </div>
    </div>
  );
};
