import React, { useEffect, useState } from 'react';
import Head from 'next/head';

import {
  replaceContentfulBrackets,
  getArrayFromParams,
  getParamsFromArrayOfObjects,
  getLocationOptionsFromRegions
} from '@utils';
import { parsedParams } from '@hooks/use-query-params';

import { IVacanciesRecord } from '@lib/cloud-search';
import { ISearchParams, IVacanciesHits } from '@lib/cloud-search/interfaces';
import { IPosition } from './interfaces';
import { IPage, IArea, ILocation } from '@componentTypes';
import { JobSearchResultsSortSettings } from '@graphql/types';

import {
  AreaOfWorkFilter,
  Content,
  JobList,
  JobSearchBox,
  LoadMoreButton,
  LocationFilter,
  JobListHeader,
  SortDropdown
} from '@components';

import {
  defaultSearchSize,
  FILTERS_LABEL,
  MORE_DETAILS,
  NEAREST,
  NEWEST,
  NO_RESULTS,
  OLDEST,
  RELEVANCE,
  SCROLL,
  SEARCH_ERROR,
  SEARCH_LINK,
  SIZE,
  SORTING_LABEL
} from '@constants';

import styles from './searchPage.module.scss';
import { useTracking } from 'contexts/TrackingContext';


type SearchResultPageProps = {
  page: IPage;
  onSearch: (api: string, search: ISearchParams) => Promise<IVacanciesHits>
}

const SearchPage = ({ page, onSearch }: SearchResultPageProps) => {
  const {
    content,
    jobSearchBox,
    searchFilterAndSortingSettings,
    searchResultsSettings,
    hero,
    globalSettings,
    seo,
    synonymousSlugs,
    slug
  } = page;

  const searchPageUrl = synonymousSlugs ? `/${synonymousSlugs.join('/')}` : slug;
  const noResultsMessageText = searchResultsSettings?.noResultsFoundText || NO_RESULTS;
  const searchErrorMessageText = SEARCH_ERROR;
  const loadMoreResultsButtonText = searchResultsSettings?.loadMoreResultsButtonText;
  const moreDetailsButtonText = searchResultsSettings?.moreDetailsButtonText || MORE_DETAILS;
  const sorting = searchFilterAndSortingSettings?.sorting || ({} as JobSearchResultsSortSettings);
  const areaOfWorkFilterOptions = searchFilterAndSortingSettings?.areaOfWorkFilter;
  const locationFilterOptions = searchFilterAndSortingSettings?.locationFilter;
  const filtersLabel = searchFilterAndSortingSettings?.filtersLabel || FILTERS_LABEL;
  const sortingLabel = searchFilterAndSortingSettings?.sortingLabel || SORTING_LABEL;
  const errorTextWhenUserDeclinesLocationPermission = sorting?.errorTextWhenUserDeclinesLocationPermission || '';
  const athosLabelMappings = globalSettings?.athosLabelMappings || [];

  const [query, setQuery] = useState<string>('');
  const [areaFilter, setAreaFilter] = useState<IArea[]>([]);
  const [locationFilter, setLocationFilter] = useState<ILocation[]>([]);
  const [selectedSortOption, setSortOption] = useState<{ title: string, sort: string }>({
    title: sorting.relevanceDescendingSortItemText || 'Relevance',
    sort: RELEVANCE,
  });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [size, setSize] = useState<number>(defaultSearchSize);
  const [position, setPosition] = useState<IPosition | undefined>(undefined);
  const [positionError, setPositionError] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { logEvent } = useTracking();

  const [vacancies, setVacancies] = useState<IVacanciesRecord[]>([]);
  const [found, setFound] = useState<Number>(0);
  const [searchError, setSearchError] = useState<any>(null);

  const [isLoadingVacancies, setIsLoadingVacancies] = useState<boolean>(false);

  useEffect(() => {
    //TODO: Add support for filters / sorting
    const { q, areas, sort, locations, size } = parsedParams();
    const arrayOfAreas = getArrayFromParams(areas);
    const arrayOfLocations = getArrayFromParams(locations);
    const selectedAreaFilters = areaOfWorkFilterOptions?.labels.filter(item => arrayOfAreas?.includes(item.id));
    const selectedLocationFilters = locationFilterOptions?.regions
      && getLocationOptionsFromRegions(locationFilterOptions?.regions, arrayOfLocations);
    let decodedSortOption = sort && decodeURIComponent(sort);
    let decodedSize = size && Number(decodeURIComponent(size));

    decodedSize = decodedSize && decodedSize > 0 ? (Math.ceil(decodedSize / 10) * 10) : defaultSearchSize;
    setSize(decodedSize);
    setPageNumber(decodedSize / 10);

    if (selectedAreaFilters && selectedAreaFilters.length) setAreaFilter(selectedAreaFilters);
    if (selectedLocationFilters && selectedLocationFilters.length) setLocationFilter(selectedLocationFilters);

    if (decodedSortOption) {
      const sortOption = sortButtonItems.find(item => item.title.toLowerCase() === decodedSortOption);
      setSortOption(sortOption || {
        title: sorting.relevanceDescendingSortItemText || 'Relevance',
        sort: RELEVANCE,
      });
      decodedSortOption = sortOption?.sort || '';
    }

    if (q) setQuery(q);

    if (decodedSortOption === NEAREST) {
      navigator.geolocation.getCurrentPosition(handleSetPosition, handleSetError);
      return;
    }

    // ToDo: Fix scroll positioning
    // browserEventHandler();

    search({
      query: q || '',
      area: selectedAreaFilters || [],
      location: selectedLocationFilters || [],
      sort: decodedSortOption || selectedSortOption?.sort,
      size: decodedSize || sessionStorage.getItem('size') || defaultSearchSize
    });
  }, []);


  useEffect(() => {
    sessionStorage.setItem(SCROLL, String (window.pageYOffset));
  });

  useEffect(() => {
    if (position && selectedSortOption?.sort === NEAREST) {
      search({
        sort: selectedSortOption?.sort,
        position
      }, true);
    }
  }, [position]);

  useEffect(() => {
    if (selectedSortOption?.sort === NEAREST) {
      navigator.geolocation.getCurrentPosition(handleSetPosition, handleSetError);
    }
  },
  [selectedSortOption?.sort, query]);

  useEffect(() => {
    addQueryParams('size', encodeURIComponent(String(size)));
    sessionStorage.setItem(SIZE, String (size));
  }, [size]);

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(function (permissionStatus) {
        permissionStatus.onchange = function () {
          navigator.geolocation.getCurrentPosition(handleSetPosition, handleSetError);
        };
      });
    }
  }, []);

  /*
    const browserEventHandler = () => {
    window.onpopstate = (_event: any) => {
      window.scrollTo(Number (sessionStorage.getItem(SCROLL)) || 0, 0);
    };

    if (window.performance
      && typeof performance.getEntriesByType === 'function'
      && performance.getEntriesByType(NAVIGATION).length
    ) {
      if (performance.getEntriesByType(NAVIGATION)[0].toJSON().type == RELOAD) {
        window.scrollTo(0, 0);
      }
    }
  };
  */

  const addQueryParams = (key: string, value: string) => {
    let searchParams = new URLSearchParams(window.location.search);
    value === '' ? searchParams.delete(key) : searchParams.set(key, value);
    const newUrl = `${searchPageUrl}?${searchParams.toString()}`;
    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
  };

  const handleQuery = (value?: string) => {
    const cleanValue = value?.trim() || '';
    const valueArea = areaOfWorkFilterOptions?.labels.find(item => {
      if (item.value.toLowerCase() === cleanValue?.toLowerCase()) {
        return item;
      }
    });

    const q = valueArea ? `${value?.trim()} | ${valueArea?.id ?? ''}` : cleanValue;

    selectedSortOption && addQueryParams('sort', encodeURIComponent(selectedSortOption?.title.toLowerCase()));

    addQueryParams('q', cleanValue);
    setQuery(cleanValue);
    search({
      query: q
    }, true);
  };

  const handleArea = (filter: IArea[]) => {
    setAreaFilter(filter);
    addQueryParams('areas', getParamsFromArrayOfObjects(filter));

    if (!position && selectedSortOption?.sort === NEAREST) {
      return;
    }

    addQueryParams('q', query);
    search({
      area: filter,
      size: defaultSearchSize,
    }, true);
  };

  const handleLocation = (filter: ILocation[]) => {
    setLocationFilter(filter);
    addQueryParams('locations', getParamsFromArrayOfObjects(filter));

    if (!position && selectedSortOption?.sort === NEAREST) {
      return;
    }

    addQueryParams('q', query);
    search({
      location: filter,
      size: defaultSearchSize
    }, true);
  };


  const handleSetPosition = (geolocation: GeolocationPosition) => {
    const position = { latitude: geolocation.coords.latitude, longitude: geolocation.coords.longitude };
    setPosition(position);
    setPositionError(false);
  };

  const handleSetError = () => {
    setPositionError(true);
    setPosition(undefined);
  };

  const handleSetSortOption = (sortOption: { title: string, sort: string }) => {
    setSortOption(sortOption);

    if (sortOption.sort !== NEAREST) {
      setPosition(undefined);
      setPositionError(false);
    }

    if (sortOption.sort === NEAREST) {
      addQueryParams('sort', encodeURIComponent(sortOption.title.toLowerCase()));
      setIsOpen(false);
    }

    if (!position && sortOption.sort === NEAREST) {
      setIsOpen(false);
      return;
    }

    if (sortOption.sort === selectedSortOption?.sort) {
      setIsOpen(false);
      return;
    }

    logEvent({
      event: 'Search Sort',
      category: 'Search',
      action: 'search_sort',
      label: sortOption.title
    });

    addQueryParams('sort', encodeURIComponent(sortOption.title.toLowerCase()));
    addQueryParams('q', query);
    setIsOpen(false);
    search({
      sort: sortOption.sort,
      position: undefined,
    }, true);
  };

  const handleLoadMore = async () => {
    const page = pageNumber + 1;
    const storageSize = Number(sessionStorage.getItem(SIZE));
    const updatedSize = (size || storageSize || defaultSearchSize) + defaultSearchSize;
    setSize(updatedSize);
    setPageNumber(page);

    await search({
      pageNumber: page,
      size: defaultSearchSize,
    });
  };

  const search = async (newParams?: any, isClear?: boolean) => {
    setIsLoadingVacancies(true);

    const { hit, found, error } = await onSearch(SEARCH_LINK || '', {
      query,
      area: areaFilter,
      location: locationFilter,
      sort: selectedSortOption?.sort,
      pageNumber: isClear ? 1 : pageNumber,
      size: isClear ? defaultSearchSize : size,
      position,
      ...newParams
    });

    if (error) {
      setSearchError(error);
    }

    setFound(found);
    setIsLoadingVacancies(false);

    if (isClear) {
      setPageNumber(1);
      setVacancies(hit);
      setSize(defaultSearchSize);
      return;
    }

    setVacancies([...vacancies, ...hit]);
  };

  const sortButtonItems = [
    {
      title: sorting.relevanceDescendingSortItemText || 'Relevance',
      sort: RELEVANCE,
    },
    {
      title: sorting.datePostedDescendingSortItemText || 'Newest',
      sort: NEWEST,
    },
    {
      title: sorting.datePostedAscendingSortItemText || 'Oldest',
      sort: OLDEST,
    },
    {
      title: sorting.distanceClosestSortItemText || 'Nearest',
      sort: NEAREST,
    }
  ];

  const deleteItem = (id: string) => {
    const areaUpdate = [...areaFilter.filter(({ id: itemId }: IArea) => itemId !== id)];
    if (areaUpdate.length !== areaFilter.length) {
      setAreaFilter(areaUpdate);
      addQueryParams('areas', getParamsFromArrayOfObjects(areaUpdate));
      if (!position && selectedSortOption?.sort === NEAREST) {
        return;
      }
      search({
        area: areaUpdate
      }, true);
      return;
    }

    const locationUpdate = [...locationFilter.filter(({ id: itemId }: ILocation) => itemId !== id)];
    setLocationFilter(locationUpdate);
    addQueryParams('locations', getParamsFromArrayOfObjects(locationUpdate));
    if (!position && selectedSortOption?.sort === NEAREST) {
      return;
    }
    search({
      location: locationUpdate
    }, true);
  };

  const jobListHeaderText = areaFilter.length > 0 || locationFilter.length > 0
    ? searchFilterAndSortingSettings?.selectedFiltersTitle
    : searchFilterAndSortingSettings?.numberOfJobsFoundText;

  const jobListHeaderTitle = jobListHeaderText && found > 0
    ? replaceContentfulBrackets(jobListHeaderText, found)
    : noResultsMessageText;

  const hideListOfVacancies = positionError && selectedSortOption?.sort === NEAREST || searchError;

  return (
    <main data-testid="searchResultPage"
      className={styles.container}
      id="mainContent">
      <Head>
        <title>{seo?.title}</title>
        <meta name="description" content={seo?.description ?? ''} />
      </Head>
      <div className={styles.header}>
        {hero &&
          // TODO: change mainHeader to load from contentful with hero
          <Content content={[hero]} mainHeader={true}/>
        }
        {jobSearchBox && (
          <JobSearchBox
            searchBox={jobSearchBox}
            onSearch={handleQuery}
            onValueChange={(value) => setQuery(value || '')}
          />
        )}
        <div className={styles.controls}>
          <div className={styles.control__wrapper}>
            <div className={styles.control__label}>{filtersLabel}</div>
            {searchFilterAndSortingSettings && (
              <>
                <AreaOfWorkFilter
                  data={areaOfWorkFilterOptions}
                  className={styles.control__item}
                  defaultFilter={areaFilter}
                  onFilterChange={handleArea}
                />
                <LocationFilter
                  data={locationFilterOptions}
                  className={styles.control__item}
                  defaultFilter={locationFilter}
                  onFilterChange={handleLocation}
                />
              </>
            )}
          </div>

          <div className={styles.control__wrapper}>
            <div className={styles.control__label}>
              {sortingLabel}
            </div>
            <SortDropdown
              selectedSortOption={selectedSortOption}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              sortButtonItems={sortButtonItems}
              handleSort={handleSetSortOption}
            />
          </div>
        </div>
      </div>
      <JobListHeader
        isLoading={isLoadingVacancies}
        items={[
          ...locationFilter,
          ...areaFilter
        ]}
        title={jobListHeaderTitle || ''}
        showTitle={!hideListOfVacancies}
        deleteItem={deleteItem}
      />
      {positionError && selectedSortOption?.sort === NEAREST && (
        <div className={styles.errorLocationPermission}>{errorTextWhenUserDeclinesLocationPermission}</div>
      )}
      {searchError && (
        <div className={styles.errorLocationPermission}>{searchErrorMessageText}</div>
      )}
      {!hideListOfVacancies && <>
        <JobList
          jobs={vacancies}
          athosLabelMappings={athosLabelMappings}
          moreDetailsButtonText={moreDetailsButtonText}
        />
        {pageNumber * defaultSearchSize < found && (
          <LoadMoreButton
            label={loadMoreResultsButtonText}
            onLoadMore={handleLoadMore}
          />
        )}
      </>}
      {content && (
        <Content content={content}/>
      )}
    </main>
  );
};

export default SearchPage;
