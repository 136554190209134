import React from 'react';
import { ICategoryFilterProps, ICategory } from './interfaces';
import styles from './categoryFilter.module.scss';
import { BROWSE_BY } from '@constants';
import { Button } from '../Button';
import { useRouter } from 'next/router';

// @ToDo: Transfer to Generic
import { getLandingBlogPageNumber } from '@contentful';


export const CategoryFilter = ({ items, filterLabel = BROWSE_BY }: ICategoryFilterProps) => {
  const router = useRouter();
  const currentPath = router.asPath.replace(/\?.*/g, '').split('/');
  const pageNumber = getLandingBlogPageNumber(currentPath || []);
  pageNumber > 1 && currentPath.splice(-1, 1);
  const pathWithoutPageNumber = currentPath.join('/');

  const renderCategory = ({ title, slug }: ICategory) => {
    const slugStr = `/${slug.join('/')}`;
    const theme = pathWithoutPageNumber === slugStr ? 'secondary' : 'default';
    return (
      <a
        key={title}
        className={styles.container__category}
        href={slugStr}
      >
        <Button
          label={title}
          theme={theme}
          outline
        />
      </a>
    );
  };

  return (
    <div className={styles.categoryFilter}>
      <div className={styles.container}>
        <span className={styles.container__title}>{filterLabel}</span>
        {items && items.map(renderCategory)}
      </div>
    </div>
  );
};