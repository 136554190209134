/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetBlogGridQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetBlogGridQuery = { __typename?: 'Query', blogGrid?: { __typename: 'BlogGrid', cardsCollection?: { __typename?: 'BlogGridCardsCollection', items: Array<{ __typename?: 'BlogCard', title?: string | null, description?: string | null, category?: string | null, duration?: number | null, publishedDate?: any | null, page?: { __typename?: 'Page', slug?: string | null } | null, image?: { __typename?: 'Asset', title?: string | null, url?: string | null } | null } | null> } | null } | null };


export const GetBlogGridDocument = gql`
    query getBlogGrid($id: String!, $preview: Boolean) {
  blogGrid(id: $id, preview: $preview) {
    __typename
    cardsCollection {
      items {
        ... on BlogCard {
          title
          description
          category
          duration
          publishedDate: published
          page {
            ... on Page {
              slug
            }
          }
          image {
            ... on Asset {
              title
              url
            }
          }
        }
      }
    }
  }
}
    `;
export type GetBlogGridQueryResult = Apollo.QueryResult<GetBlogGridQuery, GetBlogGridQueryVariables>;