import React, { useCallback } from 'react';
import { ButtonLink, RichText, ComponentHeading } from '@components';
import styles from './textBlock.module.scss';
import { ITextBlock } from './interfaces';


type Props = {
  contentData: ITextBlock;
  className?: string;
  usedAsAccordionContent?: boolean;
}

const darkBgColors = {
  blue: 'Blue',
  black: 'Black',
};

export const TextBlock = (
  {
    contentData: {
      text,
      title,
      button,
      backgroundColour,
    },
    className,
    usedAsAccordionContent
  }: Props
) => {
  const btnIcon = button?.iconClass;
  const hasIcon = btnIcon ? 'right' : 'none';
  const linkUrl = (button)?.linkUrl || (button)?.linkDestination?.slug;
  const hasTextContent = text?.json.content.some((item: any) => item?.content[0]?.value?.trim() !== '');
  const isEmpty = !(title || hasTextContent || button?.linkText);
  const getTextColor = useCallback(() => {
    if (backgroundColour === darkBgColors.black || backgroundColour === darkBgColors.blue) {
      return styles.color_white;
    }
    return styles.color_black;
  }, []);

  const destinationName = title ? title : `${button?.linkDestination?.slug?.split('/').pop()}`;
  const destinationPageName = destinationName ? `on the ${destinationName} page` : '';

  return (
    <div className={[
      styles[className || ''],
      styles[isEmpty ? 'empty' : ''],
      styles.textBlock,
      getTextColor(),
      styles[`bg--${backgroundColour?.toLowerCase()}`],
    ].join(' ')}>
      <div className={[styles.wrapper, !usedAsAccordionContent && styles.content].join(' ')}>
        {title && <ComponentHeading title={title}/>}
        {(hasTextContent || button) && (<div className={styles.copy}>
          <div className={styles.text}>
            <RichText richText={text?.json || ''} />
          </div>
          {button && (
            <ButtonLink
              label={button?.linkText || ''}
              aria-label={`${button?.linkText} ${destinationPageName}`}
              hasIcon={hasIcon}
              icon={btnIcon || ''}
              href={linkUrl}
              theme={button?.style?.toLowerCase() || 'default'}
              external={button?.openLinkInNewBrowserTab}
            />
          )}
        </div>)}
      </div>
    </div>
  );
};
