import { LOCATIONS, TEAMS } from '@constants';
import { getHeader, IContentTypeQuery, IPageSlug, getFullSlugs } from '@contentful';
import { GetSitemapPageDocument } from '@graphql/queries/getSitemapPage.generated';
import { GetSlugsDocument } from '@graphql/queries/getSlugs.generated';
import { safeQuery } from '@lib/apollo-client';
import { ISitemapPage } from './interfaces';


export type { ISitemapLayout, ISitemapPage, ISytemapSys } from './interfaces';

export const sitemapPage: IContentTypeQuery = {
  query: GetSitemapPageDocument,
  returnParam: 'sitemapPage',
  type: 'SitemapPage',
  params: {
    headerSys: {
      returnParam: 'header',
      customTransform: async (data: any) => {
        const { error, header } = await getHeader(data.client, data.sys.id, data.preview, data.slugs, data.env);
        return {
          error,
          data: header
        };
      }
    },
    contentTopSys: {
      returnParam: 'contentTop'
    },
    placeholder: {
      returnParam: 'pages',
      customTransform: async (params: any) => {
        const { data, error } = await safeQuery(params.client, GetSlugsDocument, {
          exclude: ['404', '500'],
          preview: params.preview
        }, params.env);

        if (error) {
          return {
            error,
            data: {
              homePage: [],
              locations: [],
              teams: []
            }
          };
        }

        const { items } = data.pageCollection;
        const pages: ISitemapPage[] = [];
        items.forEach((item: IPageSlug) => {
          getFullSlugs(item, items, []).forEach((slug) => {
            if(
              slug[0] === 'job-description'
              || item?.seo?.disableInSitemap
            ) {
              return;
            }
            pages.push({
              title: item.title,
              slug: slug[0] === '/' ? [''] : slug
            });
          });
        });

        const homePage: ISitemapPage[] = [];
        const teams: ISitemapPage[] = [];
        const locations: ISitemapPage[] = [];

        const sortPages = (a: ISitemapPage, b: ISitemapPage) => a.title.localeCompare(b.title);

        const filterSubpages = (
          { slug }: ISitemapPage,
          subPages: ISitemapPage[]
        ) => subPages.filter((subpage) => (
          subpage.title &&
          subpage.slug.length === 3 &&
          subpage.slug[0] === slug[0] &&
          subpage.slug[1] === slug[1]
        ));

        pages.forEach((page) => {
          const { slug, title } = page;

          if (!title) {
            return;
          }

          if (slug.length === 1) {
            homePage.push(page);
            return;
          }

          if(slug[0] === LOCATIONS && slug.length === 2) {
            locations.push({
              title,
              slug,
              subpages: filterSubpages(page, pages).sort(sortPages)
            });
          }

          if (slug[0] === TEAMS && slug.length === 2) {
            teams.push({
              title,
              slug,
              subpages: filterSubpages(page, pages).sort(sortPages)
            });
          }
        });

        return {
          data: {
            homePage: homePage.sort(sortPages),
            locations: locations.sort(sortPages),
            teams: teams.sort(sortPages)
          }
        };
      }
    },
    contentBottomSys: {
      returnParam: 'contentBottom'
    },
    footerSys: {
      returnParam: 'footer'
    }
  }
};