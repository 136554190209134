import { ApolloClient, InMemoryCache } from '@apollo/client';
import { IBlogCard } from '@componentTypes';
import { ALL_CATEGORY, DISPLAY_FEED_ON_ALL_PAGES, ROWS_PER_PAGE } from '@constants';
import { ISlugItem, transformLinkUrl } from '@contentful';
import { GetBlogLandingPageBlogsDocument } from '@graphql/queries/getBlogLandingPageBlogs.generated';
import { GetBlogLandingPagesDocument } from '@graphql/queries/getBlogLandingPages.generated';
import { safeQuery } from '@lib/apollo-client';
import { convertToSlug, getArrayItemFromEnd } from '@utils';
import { IFeedCard, IFeedSection } from 'contentful/page/interfaces';
import { IBlogLandingContent } from './interfaces';

export const sortBlogCards = (cards: IBlogCard[]) => {
  cards.sort((a, b) => {
    return (a.publishedDate || '') < (b.publishedDate || '') ? 1 :
      ((a.publishedDate || '') > (b.publishedDate || '') ? -1 : 0);
  });

  return cards;
};

export const getLandingPageSlug = (slugs: ISlugItem[], id: string, pageSlug: string[]) => {
  const landingPageSlugData = slugs.find((slugData: ISlugItem) => (
    slugData.id === id &&
    getArrayItemFromEnd(slugData.params.slug, 1) === getArrayItemFromEnd(pageSlug, 1) &&
    getArrayItemFromEnd(slugData.params.slug, 2) === getArrayItemFromEnd(pageSlug, 2) //additional check for pages with category, so that they will not be mistaken for base blog landing
  ));

  if (!landingPageSlugData) {
    return [];
  }

  return landingPageSlugData.params.slug;
};

export const getLandingBlogPageNumber = (slug: string[]) => {
  const suffix = parseInt(getArrayItemFromEnd(slug, 1) || ''); //get last slug part to check if its a number
  if (isNaN(suffix)) {
    return 1;
  }

  return suffix;
};

export const prepareCardPagination = (
  page: number,
  cards: IBlogCard[],
  featured: IBlogCard[],
  feedCard: IFeedCard | null
) => {
  const featuredWithPagination: IBlogCard[] = [];
  const cardsWithPagination: (IBlogCard | IFeedCard)[] = [];
  const perPage = ROWS_PER_PAGE * 3;
  let paginationStart = (page - 1) * perPage;
  let paginationEnd = page * perPage;
  let currentPosition = 0;

  const maxFeaturedPosition = featured.length * 3;
  if (paginationStart <= maxFeaturedPosition) {
    featured.forEach((card) => {
      if (currentPosition < paginationStart) {
        currentPosition += 3;
        return;
      }

      if (currentPosition + 3 <= paginationEnd) {
        featuredWithPagination.push(card);
        currentPosition += 3;
      }
    });
  } else {
    currentPosition = maxFeaturedPosition;
  }

  let feedCardSkipped = 0;
  if (feedCard && DISPLAY_FEED_ON_ALL_PAGES) {
    const blogsAvailableSpace = paginationStart - currentPosition;
    if(blogsAvailableSpace > 0) {
      feedCardSkipped += Math.floor(blogsAvailableSpace / (ROWS_PER_PAGE * 3));
      const blogsOnLastPage = blogsAvailableSpace % (ROWS_PER_PAGE * 3);
      blogsOnLastPage > 2 && (feedCardSkipped += 1);
    }
  }
  let cardsAddedToPage = 0;
  paginationStart -= feedCardSkipped;
  paginationEnd -= feedCardSkipped;

  cards.forEach((card) => {
    if (currentPosition < paginationStart) {
      currentPosition += 1;
      return;
    }

    if (
      currentPosition + 1 <= paginationEnd &&
      feedCard && cardsAddedToPage === 3 &&
      (page === 1 || DISPLAY_FEED_ON_ALL_PAGES)
    ) {
      cardsWithPagination.push(feedCard);
      currentPosition += 1;
    }

    if (currentPosition + 1 <= paginationEnd) {
      cardsWithPagination.push(card);
      currentPosition += 1;
      cardsAddedToPage += 1;
    }
  });

  return {
    featured: featuredWithPagination,
    cards: cardsWithPagination
  };
};

export const getFeedCard = (slugs: ISlugItem[], feedCard?: IFeedCard) => {
  if (!feedCard) {
    return null;
  }

  const sectionsCollection = (feedCard.sectionsCollection?.items || []).filter((section: IFeedSection) => !!section);
  if (!sectionsCollection.length) {
    return null;
  }

  const sectionItems = sectionsCollection.map((section: IFeedSection) => {
    const transformedLink = transformLinkUrl(section, 'link', slugs);
    return {
      ...section,
      link: transformedLink
    };
  });

  return {
    ...feedCard,
    sectionsCollection: { items: sectionItems }
  };
};

export const getBlogLandingCards = (pageData: any) => {
  const categories: { [key: string]: string[] } = {};
  const cards: IBlogCard[] = [];
  const featured: IBlogCard[] = [];
  const landingPageSlug = getLandingPageSlug(pageData.slugs, pageData.sys.id, pageData.synonymousSlugs || []);
  const pageNumber = getLandingBlogPageNumber(landingPageSlug);
  pageNumber > 1 && landingPageSlug.splice(-1, 1); //remove page number from slug if its present

  const feedCard = getFeedCard(pageData.slugs, pageData?.pageLayout?.feedCard);

  (pageData.items || []).forEach((blog: any) => {
    if (!blog) {
      return;
    }
    const blogSlug = pageData.slugs.find((slug: ISlugItem) => (
      slug.contentId === blog.sys.id &&
      slug.params.slug.slice(0, -2).every((slugPart: string) => landingPageSlug.includes(slugPart))
    ));

    // Removing cards without pages or cards not connected to this landing page
    if (!blogSlug || blogSlug.params.slug.length === 1) {
      return;
    }

    if (!categories[blog.category]) {
      categories[blog.category] = blogSlug.params.slug.slice(0, -1);
    }

    // Filter for specific category of blogs
    if (
      getArrayItemFromEnd(landingPageSlug, 1) !== getArrayItemFromEnd(blogSlug.params.slug, 3) &&
      getArrayItemFromEnd(landingPageSlug, 1) !== convertToSlug(blog.category || '')
    ) {
      return;
    }

    const isCardFeatured = landingPageSlug.includes(convertToSlug(blog.category || '')) ?
      blog.categoryFeatured : blog.landingFeatured;

    const card: IBlogCard = {
      page: {
        slug: `/${blogSlug.params.slug.join('/')}`
      },
      ...(blog?.card || {}),
      category: blog.category,
      publishedDate: blog.publishedDate,
      duration: blog.duration,
      featured: isCardFeatured
    };

    isCardFeatured ? featured.push(card) : cards.push(card);
  });

  const total = calculateBlogLandingPagesTotal(featured.length, cards.length, !!feedCard);

  const categoryKeys = Object.keys(categories);
  const filterAll = {
    title: ALL_CATEGORY,
    slug: categoryKeys[0] ? categories[categoryKeys[0]].slice(0, -1) : ['/']
  };

  return {
    data: {
      ...prepareCardPagination(pageNumber, sortBlogCards(cards), sortBlogCards(featured), feedCard),
      filter: [
        filterAll,
        ...categoryKeys.map((key) => ({
          title: key,
          slug: categories[key]
        }))
      ],
      pagination: {
        currentPage: pageNumber,
        total,
        baseSlug: landingPageSlug
      }
    }
  };
};

export const getBlogLandingPagesContent = async (
  client: ApolloClient<InMemoryCache>,
  preview: boolean,
  env?: string
): Promise<IBlogLandingContent[]> => {
  const { data, error } = await safeQuery(client, GetBlogLandingPagesDocument, {
    preview
  }, env);

  if (error) {
    return [];
  }

  return (
    await Promise.all(
      data.blogLandingPageCollection.items.map(async (page: any) => {
        if(!page?.sys?.id) {
          return null;
        }

        const { data: blogPageData, error: blogPageError } = await safeQuery(client, GetBlogLandingPageBlogsDocument, {
          id: page.sys.id,
          preview
        }, env);

        if (blogPageError) {
          return null;
        }

        return blogPageData.blogLandingPage;
      })
    )
  ).filter((blogPage) => !!blogPage) as IBlogLandingContent[];
};

export const calculateBlogLandingPagesTotal = (featuredTotal: number, blogsTotal: number, isFeedCardExist: boolean) => {
  if (blogsTotal < 3 || !isFeedCardExist) {
    return Math.ceil((featuredTotal + blogsTotal / 3) / ROWS_PER_PAGE);
  }

  let feedCardsTotal = 0;
  let availableSpaceOnPage = (ROWS_PER_PAGE - featuredTotal) * 3; //Calculate available space for blog cards
  if (DISPLAY_FEED_ON_ALL_PAGES) {
    while (availableSpaceOnPage < 0) { //Move to the first page not fully filled with featured cards
      availableSpaceOnPage += ROWS_PER_PAGE * 3;
    }

    const blogs = blogsTotal - availableSpaceOnPage; // Calculate how many blogs left after first page
    if (blogs >= 0 && availableSpaceOnPage > 3) {
      feedCardsTotal += 1; // Add 1 feed card if it fits on first availbalbe page after featured cards
    }

    feedCardsTotal += Math.floor(blogs / (ROWS_PER_PAGE * 3));
    const blogsOnLastPage = blogs % (ROWS_PER_PAGE * 3);
    if (blogsOnLastPage > 2) {
      feedCardsTotal += 1;
    }
  } else if (availableSpaceOnPage > 3 && blogsTotal > 2) {
    feedCardsTotal = 1;
  }

  return Math.ceil((featuredTotal + (blogsTotal + feedCardsTotal) / 3) / ROWS_PER_PAGE);
};