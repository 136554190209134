import { useEffect, useState } from 'react';
import { IArea } from './interfaces';
import { Button, CheckBox, Modal } from '@components';
import styles from './areaOfWorkFilter.module.scss';
import { IAreaOfWorkFilter } from '@contentful';
import { useTracking } from 'contexts/TrackingContext';


type AreaOfWorkFilterProps = {
  data?: IAreaOfWorkFilter;
  onFilterChange?: (filter: IArea[]) => void;
  className?: string;
  defaultFilter: IArea[];
}

const AreaOfWorkFilter = ({
  data,
  className,
  defaultFilter,
  onFilterChange = () => {}
}: AreaOfWorkFilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<IArea[]>(defaultFilter);
  const [filterApplied, setFilterApplied] = useState<IArea[]>(defaultFilter);
  const { logEvent } = useTracking();

  const btnLabel = data?.buttonText || '';
  const modalLabel = data?.title || '';
  const areas: IArea[] = data?.labels || [];

  useEffect(() => {
    setFilter(defaultFilter);
    setFilterApplied(defaultFilter);
  }, [defaultFilter]);

  useEffect(() => {
    if(isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);


  const updateFilter = (area: IArea) => {
    const idx = filter.findIndex((item: IArea) => item.id === area.id);

    if (idx === -1) {
      setFilter([
        ...filter,
        area
      ]);
      return;
    }

    filter.splice(idx, 1);
    setFilter([
      ...filter
    ]);
  };

  const isChecked = (area: IArea) => {
    return filter.some((item: IArea) => item.id === area.id);
  };

  const getLabel = () => {
    const filterLength = filterApplied.length;
    const areasLength = areas.length;
    if (filterLength) {
      return `${btnLabel} (${filterLength === areasLength ? 'ALL' : filterLength})`;
    }

    return btnLabel;
  };

  const onClose = () => {
    setFilter([...filterApplied]);
    setIsOpen(false);
  };

  const onApply = () => {
    filter.length === 0 && filterApplied.length > 0 ? (
      logEvent({
        event: 'Filters',
        category: 'Search',
        action: 'area_of_work_filter_reset'
      })
    ) : (
      logEvent({
        event: 'Filters',
        category: 'Search',
        action: 'area_of_work_filter_apply',
        label: filter.map(item => item.value).join(',')
      })
    );

    setFilterApplied([...filter]);

    setIsOpen(false);
    onFilterChange(filter);
  };

  const onReset = () => {
    setFilter([]);
  };

  const onButtonClick = () => {
    logEvent({
      event: 'Filters',
      category: 'Search',
      action: 'area_of_work_filter_open'
    });

    setIsOpen(true);
  };

  return (
    <div className={[styles.areaOfWorkFilter, className].join(' ')}>
      <Button
        label={getLabel()}
        onClick={onButtonClick}
        theme="light"
        outline={true}
        icon={['fas', 'caret-down']}
        hasIcon="right"
        data-testid="area-filter"
      />
      <Modal
        header={modalLabel}
        onClose={onClose}
        applyButton="Apply"
        resetButton="Clear All"
        onApply={onApply}
        onReset={onReset}
        visibility={isOpen}
        testID="area-modal"
      >
        <div className={styles.areaOfWorkFilter__wrapper}>
          {areas
            .sort((a,b) => a.value.localeCompare(b.value))
            .map((area: IArea) => (
              <CheckBox
                key={area.id}
                label={area.value}
                checked={isChecked(area)}
                onChange={() => updateFilter(area)}
              />
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default AreaOfWorkFilter;
