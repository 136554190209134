import React from 'react';
import { ContentBlock } from '@contentful';
import { contentComponents, unknownComponent } from './ContentComponents';


type ContentProps = {
  content: ContentBlock[];
  mainHeader?: boolean;
  isOverlapped?: boolean;
}

const getComponents = (content: ContentBlock[], mainHeader?: boolean) => {
  const items = content || [];
  return items.map((item, idx) => {
    return (contentComponents[item?.__typename || ''] || unknownComponent)(item, idx, mainHeader);
  });
};

const Content = ({ content, mainHeader, isOverlapped }: ContentProps) => {
  return (
    <div className={isOverlapped ? 'isOverlapped' : ''}>
      {getComponents(content, mainHeader)}
    </div>
  );
};

export default Content;
