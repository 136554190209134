import * as React from 'react';
import styles from './jobListHeader.module.scss';
import { Icon, Loading } from '@components';
import { REMOTE_LOCATION } from '@constants';
import { ILocation, IArea } from '@componentTypes';


type JobListHeaderProps = {
  isLoading?: boolean;
  items?: any[];
  title: string;
  deleteItem: (id: string) => void;
  showTitle: boolean;
}

export const JobListHeader = ({ isLoading, items, title, deleteItem, showTitle }: JobListHeaderProps) => {

  const getValue = (item: ILocation|IArea) => {
    // Is Location filter item and Remote
    if ('country' in item && item.country && item.value === REMOTE_LOCATION) {
      return `${item.value}, ${item.country}`;
    }

    return item.value;
  };

  return (
    <div data-testid="jobListHeader">
      <div className={styles.wrapper}>
        {isLoading ? (
          <div className={styles.loading}>
            <Loading />
          </div>
        ) : (
          <>
            {showTitle && <span>{title}</span>}
            {items && items?.length > 0 &&
              (
                <div>
                  {items.map((item, index) =>
                    <div
                      className={styles.chip}
                      key={index}
                      tabIndex={0}
                      onKeyPress={(e) => e.code === 'Enter' && deleteItem(item.id)}
                    >
                      {getValue(item)}
                      <span className={styles.chip__close} onClick={() => deleteItem(item.id)}>
                        <Icon icon={['far', 'circle-xmark']}/>
                      </span>
                    </div>
                  )}
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};
