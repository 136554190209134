/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCardGridQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetCardGridQuery = { __typename?: 'Query', cardGrid?: { __typename: 'CardGrid', title?: string | null, numberOfColumns?: number | null, cardsCollection?: { __typename?: 'CardGridCardsCollection', items: Array<{ __typename?: 'Card', title?: string | null, text?: string | null, textColour?: string | null, backgroundColour?: string | null, iconClass?: string | null, link?: { __typename?: 'InternalLinkWithIcon', linkText?: string | null, openLinkInNewBrowserTab?: boolean | null, iconClass?: string | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, openLinkInNewBrowserTab?: boolean | null, iconClass?: string | null } | null, backgroundImage?: { __typename?: 'Asset', title?: string | null, url?: string | null } | null, icon?: { __typename?: 'Asset', title?: string | null, url?: string | null } | null } | null> } | null } | null };


export const GetCardGridDocument = gql`
    query getCardGrid($id: String!, $preview: Boolean) {
  cardGrid(id: $id, preview: $preview) {
    __typename
    title
    numberOfColumns
    cardsCollection {
      items {
        ... on Card {
          title
          text
          textColour
          backgroundColour
          link {
            ... on UrlWithIcon {
              linkText
              linkUrl
              openLinkInNewBrowserTab
              iconClass
            }
            ... on InternalLinkWithIcon {
              linkText
              linkDestination {
                slug
              }
              openLinkInNewBrowserTab
              iconClass
            }
          }
          backgroundImage {
            ... on Asset {
              title
              url
            }
          }
          icon {
            ... on Asset {
              title
              url
            }
          }
          iconClass
        }
      }
    }
  }
}
    `;
export type GetCardGridQueryResult = Apollo.QueryResult<GetCardGridQuery, GetCardGridQueryVariables>;