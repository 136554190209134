import { getHeader, IContentTypeQuery } from '@contentful';
import { GetJobDescriptionPageDocument } from '@graphql/queries/getJobDescription.generated';


export type { IJobDescriptionLayout, IJobDescriptionSys } from './interfaces';

export const jobDescriptionPage: IContentTypeQuery = {
  query: GetJobDescriptionPageDocument,
  returnParam: 'jobDescriptionPage',
  type: 'JobDescriptionPage',
  params: {
    headerSys: {
      returnParam: 'header',
      customTransform: async (data: any) => {
        const { error, header } = await getHeader(data.client, data.sys.id, data.preview, data.slugs, data.env);
        return {
          error,
          data: header
        };
      }
    },
    heroImageSys: {
      returnParam: 'heroImage',
    },
    jobDescriptionSettings: {
      returnParam: 'applyButton',
      customTransform: async (data: any) => ({
        data: data?.applyButtonText || ''
      })
    },
    companyInformationSys: {
      returnParam: 'companyInformation'
    },
    contentTopSys: {
      returnParam: 'contentTop'
    },
    contentBottomSys: {
      returnParam: 'contentBottom'
    },
    footerSys: {
      returnParam: 'footer'
    }
  }
};