// @ts-nocheck
import { IRegion } from '@componentTypes';


export const getLocationOptionsFromRegions = (regions?: IRegion[], arrayOfLocationIds: string[] | null) => {
  return (regions || [])
    .reduce((prev, curr) =>
      [...prev, ...curr.countries
        .reduce((prev, curr) =>
          [...prev, ...curr.locations], [])], [])
    .filter(item => arrayOfLocationIds?.includes(item.id));
};