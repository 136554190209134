import { useEffect, useState } from 'react';
import Head from 'next/head';
import { motion } from 'framer-motion';

import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

import { authCheck } from '@utils';

import { config, dom } from '@fortawesome/fontawesome-svg-core';

import {
  Layout,
  ErrorBoundary,
  GoogleTagManager,
  FacebookPixel,
  LinkedInTag,
  TwitterPixel
} from 'components';


import 'styles/globals.scss';
import TrackingProvider from 'providers/TrackingProvider';
import useUtmTags from 'shared/hooks/useUtmTags';
import { addSearchUrlParams, combineUrlParams, toBoolean } from 'shared/utils';
import { isEmpty } from 'lodash';
import { IGlobalSettings } from '@contentful';
import { Seo } from '@graphql/types';


type CareersAppProps = AppProps & {
  pageProps?: {
    page?: {
      globalSettings?: IGlobalSettings,
      seo?: Seo
    }
  }
}

const preview = toBoolean(process.env.PREVIEW);

function MyApp({ Component, pageProps }: CareersAppProps) {
  const router = useRouter();
  const [authorized, setAuthorized] = useState<Boolean>(!preview);

  const { setSessionStorageItems, getItems } = useUtmTags();

  const trackingID = pageProps?.page?.globalSettings?.googleAnalyticsId;
  const oneTrustDomainID = pageProps?.page?.globalSettings?.oneTrustDomainId;
  const oneTrustAutoBlock = `https://cdn-ukwest.onetrust.com/consent/${oneTrustDomainID}/OtAutoBlock.js`;
  const oneTrustSDKStub= 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';
  const hubSpotId = pageProps?.page?.globalSettings?.hubSpotId;
  const hubSpotScript = `//js-eu1.hs-scripts.com/${hubSpotId}.js`;

  const twitterPixelId = pageProps?.page?.globalSettings?.twitterPixelId;
  const facebookPixelId = pageProps?.page?.globalSettings?.facebookPixelId;
  const linkedInTag = pageProps?.page?.globalSettings?.linkedInTag;

  const sentryUIDsn = preview ? process.env.SENTRY_PREVIEW_DSN : process.env.SENTRY_STATIC_DSN;
  const sentryDsn = sentryUIDsn || process.env.SENTRY_DSN; // TODO: Remove SENTRY_DSN after other varialbes added
  const sentryEnv = process.env.SENTRY_ENVIRONMENT;

  sentryDsn && Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: sentryEnv,
  });

  const metaRobots = [];
  const seo = pageProps?.page?.seo || undefined;
  seo?.noIndex && metaRobots.push('noindex');
  seo?.noFollow && metaRobots.push('nofollow');
  const { keywords = [] } = seo || {};

  const checkPreviewAccess = () => {
    if(authCheck(router)) {
      setAuthorized(true);
      return false;
    }

    setAuthorized(false);
    router.push('/404');
  };

  useEffect(() => {
    setSessionStorageItems();
    let params = '';
    const items = getItems();
    const { search, pathname } = window.location;
    const urlParams = Object.fromEntries(new URLSearchParams(search));

    let url = pathname;
    const analytics: { [key: string]: string } = {
      ...urlParams,
      ...(typeof items === 'boolean' ? {} : items)
    };

    if (!isEmpty(analytics)) {
      params = combineUrlParams(analytics);
    }

    if (params) {
      url = addSearchUrlParams(pathname, params);
      router.replace(url, url);
    }
  }, []);

  useEffect(() => {
    if (window.Optanon) {
      window.Optanon.initializeCookiePolicyHtml();
    }
    if (preview) {
      setAuthorized(false);
      checkPreviewAccess();
    }
  }, [router.asPath]);

  useEffect(() => {
    // Disable FontAwesome css auto adding as it fails from time to time
    config.autoAddCss = false;
  }, []);

  return (
    authorized ? (
      <>
        <Head>
          {metaRobots.length && (
            <meta name="robots" content={metaRobots.join(',')} />
          )}
          {keywords && <meta name="keywords" content={keywords.join(',')} />}
          <style id="fa-global-styles">{dom.css()}</style>
        </Head>
        <Script
          type="text/javascript"
          src={oneTrustAutoBlock}
        />
        <Script
          src={oneTrustSDKStub}
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script={oneTrustDomainID}
        />
        <Script
          id="OptanonWrapper"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            function OptanonWrapper() {}
          `
          }}
        />
        <div id="fb-root"></div>
        <Script
          async
          defer
          crossOrigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v16.0"
          nonce="Q8KVxu1E"
        />
        {twitterPixelId && (<TwitterPixel trackingId={twitterPixelId} />)}
        {facebookPixelId && (<FacebookPixel trackingId={facebookPixelId} />)}
        {linkedInTag && (<LinkedInTag trackingId={linkedInTag} />)}
        {hubSpotId && (
          <Script
            type="text/javascript"
            id="hs-script-loader"
            src={hubSpotScript}
            async
            defer
          />
        )}
        {trackingID && (<GoogleTagManager trackingId={trackingID} />)}
        <TrackingProvider trackingID={trackingID}>
          <motion.div
            key={router.route}
            initial="initial"
            animate="animate"
            variants={{
              initial: {
                opacity: 0,
              },
              animate: {
                opacity: 1,
              },
            }}
          >
            <ErrorBoundary>
              <Layout {...pageProps}>
                <Component {...pageProps} key={router.pathname}/>
              </Layout>
            </ErrorBoundary>
          </motion.div>
        </TrackingProvider>
      </>
    ) : (
      <></>
    )
  );
}

export default MyApp;
