/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetText2ColumnQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetText2ColumnQuery = { __typename?: 'Query', textOnly2Column?: { __typename: 'TextOnly2Column', textLeft?: { __typename?: 'Text', title?: string | null, backgroundColour?: string | null, text?: { __typename?: 'TextText', json: any } | null, button?: { __typename?: 'InternalLinkWithIcon', linkText?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null } | null } | null, textRight?: { __typename?: 'Text', title?: string | null, backgroundColour?: string | null, text?: { __typename?: 'TextText', json: any } | null, button?: { __typename?: 'InternalLinkWithIcon', linkText?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, iconClass?: string | null, style?: string | null, openLinkInNewBrowserTab?: boolean | null } | null } | null } | null };


export const GetText2ColumnDocument = gql`
    query getText2Column($id: String!, $preview: Boolean) {
  textOnly2Column(id: $id, preview: $preview) {
    __typename
    textLeft {
      title
      text {
        json
      }
      backgroundColour
      button {
        ... on UrlWithIcon {
          linkText
          linkUrl
          iconClass
          style
          openLinkInNewBrowserTab
        }
        ... on InternalLinkWithIcon {
          linkText
          linkDestination {
            ... on Page {
              slug
            }
          }
          iconClass
          style
          openLinkInNewBrowserTab
        }
      }
    }
    textRight {
      title
      text {
        json
      }
      backgroundColour
      button {
        ... on UrlWithIcon {
          linkText
          linkUrl
          iconClass
          style
          openLinkInNewBrowserTab
        }
        ... on InternalLinkWithIcon {
          linkText
          linkDestination {
            ... on Page {
              slug
            }
          }
          iconClass
          style
          openLinkInNewBrowserTab
        }
      }
    }
  }
}
    `;
export type GetText2ColumnQueryResult = Apollo.QueryResult<GetText2ColumnQuery, GetText2ColumnQueryVariables>;