import { IFooter, ILogo } from './interfaces';
import Image from 'next/image';
import { Icon, ToTopButton } from '@components';
import styles from './footer.module.scss';
import { replaceContentfulBrackets } from '@utils';
import { HOME_PAGE_URL } from '@constants';
import { filterDraftItems } from '@utils';


const Footer = ({ footer }: IFooter) => {
  let logo = {} as ILogo,
    logoLinkDestination,
    socialLinksTitle = '',
    socialLinks: any[] = [],
    blockLinks: any[] = [],
    allSocialChannelsLink: any = {};

  const footerBlocks = filterDraftItems(footer?.footerBlocksCollection?.items);
  footerBlocks.map((block) => {
    if (block?.__typename === 'LogoBlock') {
      logoLinkDestination = block.logoLinkDestination?.slug;
      logo.url = block.image?.url || '';
      logo.title = block.screenReaderText || '';
      logo.alt = block.image?.title || '';
    } else if (block?.__typename === 'SocialBlock') {
      socialLinksTitle = block.title || '';
      socialLinks = filterDraftItems(block.socialLinksCollection?.items);
      allSocialChannelsLink = block?.allSocialChannelsLink;
    } else if (block?.__typename === 'LinkBlock') {
      blockLinks.push(block);
    }
  });

  const secondaryLinks = filterDraftItems(footer?.footerLinksCollection?.items);
  const currentYear = new Date().getFullYear().toString();
  const copy = replaceContentfulBrackets(footer?.copyrightNotice || '', currentYear);

  return (
    <footer data-testid={'footer'} className={styles.footer}>
      <div className={styles.buttonBlock}>
        <ToTopButton/>
      </div>
      <div className={styles.primary_wrapper}>
        <div className={styles.container}>
          <div className={styles.logo}>
            {logo.url.length && (
              <a
                className={styles.logo__image}
                title={logo.title}
                href={logoLinkDestination ? (logoLinkDestination === '/' ? '/' : `/${logoLinkDestination}`) : HOME_PAGE_URL}
              >
                <Image
                  src={logo.url}
                  alt={logo.alt}
                  layout="fill"
                  objectFit="contain"
                  loader={({ width }) => `${logo.url}?w=${width}`}
                />
              </a>
            )}
          </div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.heading}>
                {socialLinksTitle}
              </div>
              {
                socialLinks.length > 0 &&
                socialLinks.map((block, idx) => (
                  <a
                    className={[styles.social_link, styles.link].join(' ')}
                    href={block.linkUrl}
                    target={block.openLinkInNewBrowserTab ? '_blank' : '_self'}
                    rel="noreferrer"
                    key={idx}
                  >
                    <Icon icon={block.iconClass}/>
                    {block.linkText}
                  </a>
                ))}
              {
                allSocialChannelsLink && <a
                  className={[styles.social_link, styles.link].join(' ')}
                  href={allSocialChannelsLink.linkUrl}
                  target={allSocialChannelsLink.openLinkInNewBrowserTab ? '_blank' : '_self'}
                  rel="noreferrer"
                >
                  {allSocialChannelsLink.linkText}
                </a>
              }
            </div>
            {blockLinks.length && (blockLinks.map((block, idx) => (
              <div className={styles.item} key={idx}
              >
                <div className={styles.heading}>
                  {block?.title}
                </div>
                <a
                  className={[styles.block_link, styles.link].join(' ')}
                  href={block.linkUrl}
                  title={block.linkScreenReaderText}
                  target={block.openLinkInNewBrowserTab ? '_blank' : '_self'}
                  rel="noreferrer"
                >
                  {block?.text}
                  <Icon icon={['fas', 'arrow-right']}/>
                </a>
              </div>
            )))}
          </div>
        </div>
      </div>
      <div className={styles.secondary_wrapper}>
        <div className={styles.container}>
          <div className={styles.copy}>
            {copy}
          </div>
          <div className={styles.secondary_list}>
            {secondaryLinks.length > 0 && secondaryLinks.map((block: any, idx) => (
              <a
                className={[styles.secondary_link, styles.link].join(' ')}
                href={block.linkUrl || block.linkDestination?.slug}
                target={block.openLinkInNewBrowserTab ? '_blank' : '_self'}
                rel="noreferrer"
                key={idx}
              >
                {block.linkText}
              </a>
            ))}
            <button id="ot-sdk-btn" className={['ot-sdk-show-settings', 'one_trust_custom'].join(' ')}>Cookie Settings</button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
