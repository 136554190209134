import { useEffect, useState } from 'react';
import { ICountry, ILocation, IRegion } from './interfaces';
import { Button, CheckBox, Nav, Modal } from '@components';
import styles from './locationFilter.module.scss';
import { ILocationFilter } from '@contentful';
import { useTracking } from 'contexts/TrackingContext';


type LocationFilterProps = {
  data?: ILocationFilter;
  defaultFilter: ILocation[];
  onFilterChange?: (filter: ILocation[]) => void;
  className?: string;
}

const LocationFilter = ({
  data,
  className,
  defaultFilter,
  onFilterChange = () => {}
}: LocationFilterProps) => {
  const btnLabel = data?.buttonText || '';
  const modalLabel = data?.title || '';
  const allTab = data?.allText || '';
  const regions = data?.regions || [];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<ILocation[]>([]);
  const [filterApplied, setFilterApplied] = useState<ILocation[]>([]);
  const [currentRegion, setCurrentRegion] = useState<string>(allTab);
  const [navItems, setNavItems] = useState<string[]>([]);
  const { logEvent } = useTracking();

  useEffect(() => {
    const filteredRegions = regions
      .filter(({ countries }) => countries.length > 0)
      .map(({ name }) => name);
    setNavItems([allTab, ...filteredRegions]);
  }, []);

  useEffect(() => {
    setFilter(defaultFilter);
    setFilterApplied(defaultFilter);
  }, [defaultFilter]);

  useEffect(() => {
    if(isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);

  const updateFilter = (location: ILocation) => {
    const idx = filter.findIndex((item: ILocation) => item.id === location.id);

    if (idx === -1) {
      setFilter([
        ...filter,
        location
      ]);
      return;
    }

    filter.splice(idx, 1);
    setFilter([
      ...filter
    ]);
  };

  const isChecked = (location: ILocation) => {
    return filter.some((item: ILocation) => item.id === location.id);
  };

  const getLabel = () => {
    const filterLength = filterApplied.length;
    let regionsLength = 0;
    regions.forEach(region => {
      region.countries.forEach(country => {
        regionsLength += country.locations.length;
      });
    });

    if (filterLength) {
      return `${btnLabel} (${filterLength === regionsLength ? 'ALL' : filterLength})`;
    }

    return btnLabel;
  };

  const onClose = () => {
    setFilter([...filterApplied]);
    setIsOpen(false);
  };

  const onApply = () => {
    filter.length === 0 && filterApplied.length > 0 ? (
      logEvent({
        event: 'Filters',
        category: 'Search',
        action: 'location_filter_reset'
      })
    ) : (
      logEvent({
        event: 'Filters',
        category: 'Search',
        action: 'location_filter_apply',
        label: filter.map(item => item.value).join(', ')
      })
    );

    setFilterApplied([...filter]);

    setIsOpen(false);
    onFilterChange(filter);
  };

  const onReset = () => {
    setFilter([]);
  };

  const getRegion = (region?: IRegion) => {
    if (!region) {
      return <></>;
    }

    const { name, countries } = region;
    return (
      <div key={name} className={styles.locationFilter__wrapper__region}>
        {
          countries
            .map((country: ICountry) => (
              <div key={country.name} className={styles.locationFilter__wrapper__region__country}>
                <div className={styles.locationFilter__wrapper__region__country__name}>{country.name}</div>
                <div className={styles.locationFilter__wrapper__region__country__locations}>
                  {country.locations
                    .filter((location: ILocation) => location.value)
                    .map((location: ILocation, idx: number) => (
                      <CheckBox
                        key={`${idx}`}
                        label={location.value}
                        checked={isChecked(location)}
                        onChange={() => updateFilter(location)}
                      />
                    ))}
                </div>
              </div>
            ))
        }
      </div>
    );
  };

  const onButtonClick = () => {
    logEvent({
      event: 'Filters',
      category: 'Search',
      action: 'location_filter_open'
    });

    setIsOpen(true);
  };

  return (
    <div className={[styles.locationFilter, className].join(' ')}>
      <Button
        label={getLabel()}
        onClick={onButtonClick}
        theme="light"
        outline={true}
        icon={['fas', 'caret-down']}
        hasIcon="right"
        data-testid="location-filter"
      />
      <Modal
        header={modalLabel}
        onClose={onClose}
        applyButton="Apply"
        resetButton="Clear All"
        onApply={onApply}
        onReset={onReset}
        visibility={isOpen}
        testID="location-modal"
      >
        <Nav
          size="large"
          items={navItems}
          selected={currentRegion}
          onChange={(region: string) => setCurrentRegion(region)}
          style={styles.locationFilter__nav}
        />
        <div className={styles.locationFilter__wrapper}>
          {currentRegion === allTab ? (
            regions.map((region: IRegion) => getRegion(region))
          ) : (
            getRegion(regions.find((elem) => elem.name === currentRegion))
          )}
        </div>
      </Modal>
    </div>
  );
};

export default LocationFilter;
