const MIN_META_LENGTH = 1024;


// Fetch paragraphs from the description
export const getParagraphs = (description: string) => {
  // Find all paragraphs within description
  const paragraphRegex = /<p>(.*?)<\/p>/g;
  const paragraphs = description.match(paragraphRegex);

  // Replace HTML tags and filter empty lines
  if (paragraphs && paragraphs.length) {
    const htmlTagsRegex = /(<([^>]+)>)/ig;
    return paragraphs
      .map(paragraph => {
        const text = paragraph.replace(htmlTagsRegex, '');
        return text.replace('&nbsp;', '');
      })
      .filter(line => line.length > 0);
  }

  return [];
}

// Get meta description from job description content
export const getMetaDescription = (description: string) => {
  if (!description || !description.length) {
    return '';
  }

  // Append line to meta description until it's length meets required minimum
  let metaDescription = '';
  for (const paragraph of getParagraphs(description)) {
    metaDescription = `${metaDescription} ${paragraph}`;
    if (metaDescription.length > MIN_META_LENGTH) {
      break;
    }
  }

  return metaDescription.trim();
};
