import React from 'react';
import styles from './TextOnly2Column.module.scss';
import { TextBlock } from '@components';
import { ITextBlock } from '@componentTypes';


type Props = {
  textLeft: ITextBlock,
  textRight: ITextBlock,
  usedAsAccordionContent?: boolean,
}

export const TextOnly2Column = ({ textLeft, textRight, usedAsAccordionContent = false }: Props) => {
  return (
    <div className={styles.contentBlock}>
      {textLeft  && <TextBlock
        contentData={textLeft}
        className="TextOnly2Column"
        usedAsAccordionContent={usedAsAccordionContent}
      />}
      {textRight && <TextBlock
        contentData={textRight}
        className="TextOnly2Column"
        usedAsAccordionContent={usedAsAccordionContent}
      />}
    </div>
  );
};
