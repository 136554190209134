import styles from './headerMenuMobile.module.scss';
import { Icon } from '@components';
import { useEffect, useState } from 'react';
import { IHeaderLink } from '@contentful';


type Props = {
  data: IHeaderLink[];
  className?: any;
  onClose: () => void;
}

const HeaderMenuMobile = ({ data, className = '', onClose }: Props) => {
  const [selectedMenu, setSelectedMenu] = useState<IHeaderLink[]>([]);

  useEffect(() => {
    setSelectedMenu([{
      type: '',
      linkText: '',
      subLinks: data,
      linkDestination: {
        slug: ''
      }
    }]);
  }, []);

  const moveToMenu = (link?: IHeaderLink) => {
    if(link) {
      setSelectedMenu([
        ...selectedMenu,
        link
      ]);

      return;
    }

    selectedMenu.pop();
    setSelectedMenu([...selectedMenu]);
  };

  const getClass = (link: IHeaderLink, className: string[] = []) => {
    const slug = (link?.linkDestination?.slug || '').replace('/', '');
    if (slug.length > 0 && (window?.location?.pathname || '').includes(slug)) {
      className.push(styles.selected);
    }

    return className.join(' ');
  };

  const menuHeader = (link: IHeaderLink) => {
    return (
      <>
        <div className={getClass(link, [styles.link, styles.menu__header])} onClick={() => moveToMenu()}>
          <Icon icon={['fas', 'chevron-left']} />
          {link.linkText || ''}
        </div>
        {link.linkDestination && (
          <a
            title={link.linkText || ''}
            className={styles.link}
            onClick={onClose}
            href={link.linkDestination?.slug || '/'}
          >
            <span className={styles.link__discover}>discover</span> {link.linkText || ''}
          </a>
        )}
      </>
    );
  };

  const subLink = (link: IHeaderLink) => {
    if (
      link.type === 'NavigationLinkWithSubMenu'
      || link.type === 'NavigationLinkGroup'
    ) {
      return (
        <span
          key={link.linkText}
          className={getClass(link, [styles.link])}
          onClick={() => moveToMenu(link)}
        >
          <span className={styles.link__container}>
            {link.linkText || ''}
            <Icon icon={['fas', 'chevron-right']} />
          </span>
        </span>
      );
    }

    return (
      <a
        className={getClass(link, [styles.link])}
        title={link.linkText || ''}
        onClick={onClose}
        key={link.linkText}
        href={link?.linkDestination?.slug || '/'}
      >
        {link.linkText || ''}
      </a>
    );
  };

  const displayMenu = () => {
    const { length } = selectedMenu;
    const link = selectedMenu[length - 1];
    if(!link) {
      return <></>;
    }


    const isTopMenuLevel = length === 1;
    const backgroundStyle = isTopMenuLevel ? styles.top__menu : styles.sub__menu;

    return (
      <div className={[styles.menu, backgroundStyle].join(' ')}>
        {!isTopMenuLevel && menuHeader(link)}
        {(link?.subLinks || []).map((subLinkData) => subLink(subLinkData))}
      </div>
    );
  };

  return (
    <div className={[styles.headerMenuMobile, className].join(' ')}>
      {displayMenu()}
    </div>
  );
};

export default HeaderMenuMobile;
