import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon } from '@components';
import { IEventCard } from './interfaces';
import styles from './eventCard.module.scss';
import { convertDate } from '@utils';
import { InfoCard } from '../InfoCard';
import { HYBRID_TEXT } from 'constants/event';


type Props = {
  card: IEventCard;
  featured: boolean;
};

export const EventCard = ({ card, featured }: Props) => {
  const {
    title = '',
    description,
    image,
    topic,
    startDate,
    locationCity,
    hybrid,
    page,
  } = card;

  return (
    <InfoCard
      title={title}
      description={description}
      image={image}
      chipText={topic}
      link={page}
      featured={featured}
    >
      <>
        {startDate && (
          <span className={styles.start}>
            <Icon icon={['fas', 'calendar-day']} />
            {convertDate(startDate, {
              month: 'short'
            })}
          </span>
        )}
        {locationCity && (
          <span className={styles.location}>
            <Icon icon={['fas', 'map-marker-alt']} />
            {locationCity}
          </span>
        )}
        {hybrid && (
          <span className={styles.hybrid}>
            <Icon icon={'fa house-building' as IconProp} />
            {HYBRID_TEXT}
          </span>
        )}
      </>
    </InfoCard>
  );
};
