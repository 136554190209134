/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetAssetCollectionQueryVariables = Types.Exact<{
  ids: Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>;
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetAssetCollectionQuery = { __typename?: 'Query', assetCollection?: { __typename?: 'AssetCollection', items: Array<{ __typename?: 'Asset', title?: string | null, description?: string | null, contentType?: string | null, url?: string | null, width?: number | null, height?: number | null, sys: { __typename?: 'Sys', id: string } } | null> } | null };


export const GetAssetCollectionDocument = gql`
    query getAssetCollection($ids: [String]!, $preview: Boolean) {
  assetCollection(preview: $preview, where: {sys: {id_in: $ids}}) {
    items {
      sys {
        id
      }
      title
      description
      contentType
      url
      width
      height
    }
  }
}
    `;
export type GetAssetCollectionQueryResult = Apollo.QueryResult<GetAssetCollectionQuery, GetAssetCollectionQueryVariables>;