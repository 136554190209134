import React from 'react';
import Autosuggest, {
  GetSuggestionValue,
  InputProps,
  RenderSuggestion,
  SuggestionsFetchRequested
} from 'react-autosuggest';
import { Icon } from '@components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ISuggestion } from './interfaces';
import styles from './searchSuggestion.module.scss';


interface ISearchSuggestProps {
  suggestions: ISuggestion[];
  onSuggestionsFetchRequested: SuggestionsFetchRequested;
  getSuggestionValue: GetSuggestionValue<ISuggestion>;
  renderSuggestion: RenderSuggestion<ISuggestion>;
  inputProps: InputProps<ISuggestion>;
  hasIcon?: 'none' | 'left' | 'right';
  icon?: IconProp;
  className?: string,
}


export const SearchSuggest = ({
  suggestions,
  onSuggestionsFetchRequested,
  getSuggestionValue,
  renderSuggestion,
  inputProps,
  hasIcon = 'none',
  icon = ['fas', 'magnifying-glass'],
  className = '',
}: ISearchSuggestProps) => {
  return (
    <div className={[className, styles.wrapper].join(' ')}>
      {hasIcon !== 'none' && <Icon
        icon={icon}
        className={[styles.icon, styles[`icon--${hasIcon}`]].join(' ')}
      />}
      <Autosuggest
        theme={styles}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          className: [styles.input, styles[`input--icon-${hasIcon}`]].join(' '),
          id: 'search-input',
          ...inputProps }}
        onSuggestionsClearRequested={()=>{}}
      />
    </div>
  );
};
