import React from 'react';
import Head from 'next/head';

import {
  Content,
} from '@components';
import styles from './sitemapPage.module.scss';
import { ISitemapLayout, ISitemapPage } from '@contentful';


type PageProps = {
  page: ISitemapLayout
}


const SitemapPage = ({ page }: PageProps) => {
  const { contentTop, contentBottom, pages, seo } = page;
  const { homePage, locations, teams } = pages;

  const getRow = (rowData: ISitemapPage[], title: string) => {
    const linkStyle = [styles.content__row__link];
    if(rowData.some((row) => row.subpages)) {
      linkStyle.push(styles.content__row__link__bold);
    }
    return (
      <div className={styles.content__row}>
        <h3 className={styles.content__row__title}>
          {title}
        </h3>
        {rowData.map(({ slug, title, subpages }: ISitemapPage) => (
          <>
            <div key={title} className={linkStyle.join(' ')} >
              <a title={title || ''} href={`/${slug.join('/')}`}>
                {title || ''}
              </a>
            </div>
            {subpages && subpages.map((subpage) => (
              <div className={styles.content__row__link} key={subpage.title}>
                <a title={subpage.title || ''} href={`/${subpage.slug.join('/')}`}>
                  {subpage.title || ''}
                </a>
              </div>
            ))}
          </>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.sitemap}>
      <Head>
        <title>{seo?.title}</title>
        <meta name="description" content={seo?.description ?? ''} />
      </Head>
      <main className={styles.main} id="mainContent">
        {contentTop && (
          <Content content={contentTop} />
        )}
        <div className={styles.content}>
          {homePage && getRow(homePage, 'Home')}
          {teams && getRow(teams, 'Teams')}
          {locations && getRow(locations, 'Locations')}
        </div>
        {contentBottom && (
          <Content content={contentBottom} />
        )}
      </main>
    </div>
  );
};

export default SitemapPage;
