import Head from 'next/head';
import { Header, CanonicalTag, Footer } from '@components';
import styles from './layout.module.scss';
import { getNewRelicScript, logger } from '@utils';


type LayoutProps = {
  children: JSX.Element;
}

const Layout = ({ children }: LayoutProps) => {
  const { props } = children;
  const header = props?.page?.header;
  const footer = props?.page?.footer;
  const crazyEggId = props?.page?.globalSettings?.crazyEggId;
  const crazyEggScript = `//script.crazyegg.com/pages/scripts/${crazyEggId}.js`;

  const newRelicAppVars = props?.page?.globalSettings?.newRelicAppId;
  const newRelicScript = newRelicAppVars && getNewRelicScript(newRelicAppVars);

  if (!newRelicAppVars) {
    logger.error('[NewRelic] There is no NewRelicAppId in Contentful Global Settings');
  }

  return (
    <div className={styles.wrapper}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
        {newRelicScript && (<script async type="text/javascript"
          id="newRelicScript"
          dangerouslySetInnerHTML={{
            __html: newRelicScript
          }}
        />)}
        {crazyEggId && (
          <script
            type="text/javascript"
            src={crazyEggScript}
            async
          />
        )}
      </Head>
      <CanonicalTag/>
      {header && (
        <Header header={header} />
      )}
      {children}
      {footer && (
        <Footer footer={footer} />
      )}
    </div>
  );
};

export default Layout;
