/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetGlobalSettingsQueryVariables = Types.Exact<{
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetGlobalSettingsQuery = { __typename?: 'Query', globalSettings?: { __typename?: 'GlobalSettings', name?: string | null, googleAnalyticsId?: string | null, utmTagsPersistencePeriod?: number | null, oneTrustDomainId?: string | null, twitterPixelId?: string | null, facebookPixelId?: string | null, linkedInTag?: string | null, hubSpotId?: string | null, crazyEggId?: string | null, newRelicAppId?: string | null, sentryEnvironment?: string | null, sentryAllowedUrLs?: string | null, talkPushUrl?: string | null, talkPushEnabled?: boolean | null, greenhouseBoardToken?: string | null, greenhouseEnabled?: boolean | null } | null, jobSearchResultsFilterAndSortSettings?: { __typename?: 'JobSearchResultsFilterAndSortSettings', areaOfWorkFilter?: { __typename?: 'JobSearchResultsAreaOfWorkFilterSettings', labelMappings?: any | null } | null } | null };


export const GetGlobalSettingsDocument = gql`
    query getGlobalSettings($preview: Boolean) {
  globalSettings(id: "global-settings", preview: $preview) {
    name
    googleAnalyticsId
    utmTagsPersistencePeriod
    oneTrustDomainId
    twitterPixelId
    facebookPixelId
    linkedInTag
    hubSpotId
    crazyEggId
    newRelicAppId
    sentryEnvironment
    sentryAllowedUrLs
    talkPushUrl
    talkPushEnabled
    greenhouseBoardToken
    greenhouseEnabled
  }
  jobSearchResultsFilterAndSortSettings(
    id: "job-search-results-filter-and-sort-settings"
    preview: $preview
  ) {
    areaOfWorkFilter {
      labelMappings
    }
  }
}
    `;
export type GetGlobalSettingsQueryResult = Apollo.QueryResult<GetGlobalSettingsQuery, GetGlobalSettingsQueryVariables>;