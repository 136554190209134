export const MINUTES_READ = 'min read';
export const BROWSE_BY = 'BROWSE BY';
export const ALL_CATEGORY = 'All';
export const ROWS_PER_PAGE = 5;
export const DISPLAY_FEED_ON_ALL_PAGES = true;
export const SHARE_TEXT = 'SHARE';
export const COPY_TEXT = 'Copy link';
export const FACEBOOK_TEXT = 'Facebook';
export const LINKEDIN_TEXT = 'Linkedin';
export const TWITTER_TEXT = 'Twitter';
export const COPPIED_TEXT = 'Link has been copied';
export const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php?u=';
export const LINKEDIN_SHARE_URL = 'http://www.linkedin.com/shareArticle?mini=true&url=';
export const TWITTER_SHARE_URL = 'http://twitter.com/share?url=';