import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconProp, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import HouseBuilding from 'shared/media/house-building.svg';
import ArrowUpFromSquare from 'shared/media/arrow-up-from-square.svg';
import styles from './icon.module.scss';


library.add(fas, far, fab);

export const Icon = (props: FontAwesomeIconProps) => {
  const transformIcons = (icon: IconProp | string) => {
    if(typeof icon !== 'string') {
      return icon;
    }

    return (icon.includes('fa-') ? (
      icon.replace('fa-fab', 'fa-brands')
    ) : (
      icon.split(' ')
    )) as IconProp;
  };
  switch (props.icon) {
  case 'fa house-building':
    return <span className={styles.importedSvg}><HouseBuilding /></span>;
  case 'fa arrow-up-from-square':
    return <span className={styles.importedSvg}><ArrowUpFromSquare /></span>;
  default:
    return (
      <FontAwesomeIcon
        {...props}
        icon={transformIcons(props.icon)}
      />
    );
  }
};
