export const schemaGreenHouse = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    title: { type: 'string' },
    city: { type: 'string' },
    district: { type: ['string', 'null'] },
    country: { type: 'string' },
    geolocation: { type: ['string', 'null'] },
    url: { type: 'string' },
    area: { type: ['string', 'null'] }, // ToDo: should be marked as 'string' only after CWD-1924
    date: { type: 'string' },
    applyUrl: { type: 'string' },
    description: { type: ['string', 'null'] }
  }
};
