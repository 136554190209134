/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetInteractiveMapQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetInteractiveMapQuery = { __typename?: 'Query', interactiveMap?: { __typename: 'InteractiveMap', title?: string | null, fullWidth?: boolean | null, pinsCollection?: { __typename?: 'InteractiveMapPinsCollection', items: Array<{ __typename?: 'Pin', lat?: number | null, lng?: number | null, text?: { __typename?: 'PinText', json: any } | null } | null> } | null } | null };


export const GetInteractiveMapDocument = gql`
    query getInteractiveMap($id: String!, $preview: Boolean) {
  interactiveMap(id: $id, preview: $preview) {
    __typename
    title
    pinsCollection {
      items {
        text {
          json
        }
        lat
        lng
      }
    }
    fullWidth
  }
}
    `;
export type GetInteractiveMapQueryResult = Apollo.QueryResult<GetInteractiveMapQuery, GetInteractiveMapQueryVariables>;