/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetHeroImageQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetHeroImageQuery = { __typename?: 'Query', heroImage?: { __typename?: 'HeroImage', title?: string | null, subtitle?: string | null, textIconPrefixClass?: string | null, imageHeight?: string | null, image?: { __typename?: 'Asset', url?: string | null, title?: string | null, width?: number | null, height?: number | null } | null, mobileImage?: { __typename?: 'Asset', url?: string | null, title?: string | null, width?: number | null, height?: number | null } | null, button?: { __typename?: 'InternalLinkWithIcon', linkText?: string | null, openLinkInNewBrowserTab?: boolean | null, iconClass?: string | null, style?: string | null, linkDestination?: { __typename?: 'Page', slug?: string | null } | null } | { __typename?: 'UrlWithIcon', linkText?: string | null, linkUrl?: string | null, openLinkInNewBrowserTab?: boolean | null, iconClass?: string | null, style?: string | null } | null } | null };


export const GetHeroImageDocument = gql`
    query getHeroImage($id: String!, $preview: Boolean) {
  heroImage(id: $id, preview: $preview) {
    title
    subtitle
    textIconPrefixClass
    image {
      url
      title
      width
      height
    }
    mobileImage {
      url
      title
      width
      height
    }
    imageHeight
    button {
      ... on UrlWithIcon {
        linkText
        linkUrl
        openLinkInNewBrowserTab
        iconClass
        style
      }
      ... on InternalLinkWithIcon {
        linkText
        openLinkInNewBrowserTab
        iconClass
        style
        linkDestination {
          slug
        }
      }
    }
  }
}
    `;
export type GetHeroImageQueryResult = Apollo.QueryResult<GetHeroImageQuery, GetHeroImageQueryVariables>;