import React, { useCallback, useEffect, useRef } from 'react';
import styles from './dropdown.module.scss';
import { IDropdownProps } from './interfaces';


export const Dropdown = ({
  items,
  onItemSelect,
  setIsOpen,
  children,
  className
}: IDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dropdownRef.current?.focus();
  }, []);

  const handleChange = (_e: any, item: any) => {
    onItemSelect && onItemSelect(item);
  };

  const handleBlur = useCallback((e: React.FocusEvent) => {
    const currentTarget = e.currentTarget;
    // Give browser time to focus the next element
    requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        // Do blur logic here!
        setIsOpen(false);
      }
    });
  }, []);

  const options = () => {
    return (items || []).map(
      (item, idx) =>
        <div
          key={idx}
          id={item.title}
          onClick={(e) => handleChange(e, item)}
          tabIndex={0}
          onKeyPress={(e) => e.key === 'Enter' && handleChange(e, item)}
        >
          {item.title}
        </div>
    );
  };

  return (
    <div
      id="dropdown"
      data-testid={'dropdown'}
      role={'dropdown'}
      ref={dropdownRef}
      className={[styles.dropdownContent, className].join(' ')}
      onBlur={(e) => handleBlur(e)}
      tabIndex={0}
    >
      {children}
      {options()}
    </div>
  );
};
