import React from 'react';
import Head from 'next/head';

import {
  EventInfoPanel,
  Content,
} from '@components';
import { IEventLayout } from '@contentful';


type PageProps = {
  page: IEventLayout
}

export const EventPage = ({ page }: PageProps) => {
  const { content, hero, seo, startDate, endDate, location, locationMapUrl, hybrid, registrationUrl } = page;

  return (
    <div>
      <Head>
        <title>{seo?.title}</title>
        <meta name="description" content={seo?.description ?? ''} />
      </Head>
      <main id="mainContent">
        {hero && (
          <Content content={[hero]} mainHeader={true} />
        )}
        <EventInfoPanel
          title={hero?.title || ''}
          startDate={startDate}
          endDate={endDate}
          location={location}
          locationMapUrl={locationMapUrl}
          hybrid={hybrid}
          registrationUrl={registrationUrl}
        />
        {content && (
          <Content content={content} />
        )}
      </main>
    </div>
  );
};
