/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetEventLandingPageEventsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetEventLandingPageEventsQuery = { __typename?: 'Query', eventLandingPage?: { __typename?: 'EventLandingPage', eventsCollection?: { __typename?: 'EventLandingPageEventsCollection', items: Array<{ __typename?: 'EventPage', topic?: string | null, startDate?: any | null } | null> } | null, sys: { __typename?: 'Sys', id: string } } | null };


export const GetEventLandingPageEventsDocument = gql`
    query getEventLandingPageEvents($id: String!, $preview: Boolean) {
  eventLandingPage(id: $id, preview: $preview) {
    eventsCollection(limit: 1000) {
      items {
        topic
        startDate
      }
    }
    sys {
      id
    }
  }
}
    `;
export type GetEventLandingPageEventsQueryResult = Apollo.QueryResult<GetEventLandingPageEventsQuery, GetEventLandingPageEventsQueryVariables>;