import { useRef, useEffect, useState } from 'react';
import styles from './header.module.scss';
import { Icon, HeaderMenu, HeaderMenuMobile } from '@components';
import { useRouter } from 'next/router';
import { IHeaderLink, IHeader } from '@contentful';
import { useClickOutside } from './useClickOutSide';


type Props = {
  header: IHeader
}

const Header = ({ header }: Props) => {
  const router = useRouter();
  const { logoLinkDestination, logoScreenReaderText, skipToMainContentLinkText = '' } = header;
  const logoLinkHref = logoLinkDestination?.slug ? (logoLinkDestination.slug === '/' ? '/' : `/${logoLinkDestination.slug}`) : '';
  const logoImageUrl = header?.logoImage?.url || '';
  const logoImageTitle = header?.logoImage?.title || '';
  const width = header?.logoImage?.width || 0;
  const height = header?.logoImage?.height || 0;
  const links = header.subLinks || [];
  const [selectedData, setSelectedData] = useState<IHeaderLink>();
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState<boolean>(false);

  const onResize = () => {
    setSelectedData(undefined);
    setIsMobileMenuOpened(false);
  };
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setSelectedData(undefined));

  useEffect(() => {
    window && window.addEventListener('resize', onResize);

    return () => {
      window && window.removeEventListener('resize', onResize);
    };
  }, []);

  const getClass = (link: IHeaderLink) => {
    const className = [styles.link];
    const slug = (link?.linkDestination?.slug || '').replace('/', '');
    const { slug: routerSlugs = [] } = router.query;

    if (slug.length > 0 && routerSlugs.includes(slug)) {
      className.push(styles.selected);
    }

    return className.join(' ');
  };

  const setData = (data: IHeaderLink) => {
    if(data === selectedData) {
      setSelectedData(undefined);
      return false;
    }

    setSelectedData(data);
  };

  return (
    <>
      {/* Anchor link required; Link tag breaks default keyboard navigation */}
      <a className={styles.skip_link} href={'#mainContent'}>{skipToMainContentLinkText}</a>
      <header data-testid={'header'} className={styles.wrapper}>
        <div className={[styles.container, isMobileMenuOpened ? styles.mobile : ''].join(' ')}>
          <a
            className={styles.logo}
            title={logoScreenReaderText || ''}
            href={logoLinkHref}
          >
            <img
              className={styles.logo__img}
              src={logoImageUrl}
              alt={logoImageTitle}
              width={width}
              height={height}
            />
          </a>
          {links.map((link: IHeaderLink) => (
            <div key={link.linkText} className={getClass(link)}>
              {link.type === 'NavigationLinkWithSubMenu' ? (
                <span
                  data-testid="nav-link-1-level"
                  tabIndex={0}
                  onClick={() => setData(link)}
                  onKeyPress={(e) => e.key === 'Enter' && setData(link)}
                  className={'dropdown-link'}>
                  {link.linkText || ''}
                  <Icon icon={['fas', 'chevron-down']} />
                </span>
              ) : (
                <a
                  data-testid="nav-link-1-level"
                  title={link.linkText || ''}
                  onClick={() => setSelectedData(undefined)}
                  href={link?.linkDestination?.slug || '/'}
                >
                  {link.linkText || ''}
                </a>
              )}
            </div>
          ))}
          {links.length > 0 && (
            <span
              tabIndex={0}
              onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
              onKeyPress={(e) => e.key === 'Enter' && setIsMobileMenuOpened(!isMobileMenuOpened)}
              className={[styles['link-icon'], styles['mobile-menu-icon']].join(' ')}
            >
              <Icon
                icon={['fas', isMobileMenuOpened ? 'close' : 'bars']}
              />
            </span>
          )}
        </div>
        {selectedData && (
          <div ref={wrapperRef}>
            <HeaderMenu
              className={styles.menu}
              data={selectedData}
              onClose={() => setSelectedData(undefined)}
            />
          </div>
        )}
        {links.length > 0 && isMobileMenuOpened && (
          <HeaderMenuMobile
            className={styles.mobileMenu}
            data={links}
            onClose={() => setIsMobileMenuOpened(false)}
          />
        )}
      </header>
    </>
  );
};

export default Header;
