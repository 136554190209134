import { ApolloClient, DocumentNode, InMemoryCache } from '@apollo/client';
import { safeQuery } from '@lib/apollo-client';
import { GetTextContentDocument } from '@graphql/queries/getTextContent.generated';
import { ContentBlock, IContentBlocksResponse, IDataResponse } from './interfaces';
import { GetText2ColumnDocument } from '@graphql/queries/getText2Column.generated';
import { GetTextImage2ColumnDocument } from '@graphql/queries/getTextImage2Column.generated';
import { GetHeroImageDocument } from '@graphql/queries/getHeroImage.generated';
import { GetVideoDocument } from '@graphql/queries/getVideo.generated';
import { GetImageDocument } from '@graphql/queries/getImage.generated';
import { GetCardGridDocument } from '@graphql/queries/getCardGrid.generated';
import { GetHeroCarouselDocument } from '@graphql/queries/getHeroCarousel.generated';
import { GetAccordionDocument } from '@graphql/queries/getAccordion.generated';
import { GetHeaderDocument } from '@graphql/queries/getHeader.generated';
import { GetFooterDocument } from '@graphql/queries/getFooter.generated';
import { GetJobSearchBoxDocument } from '@graphql/queries/getJobSearchBox.generated';
import { GetJobSearchResultsSettingsDocument } from '@graphql/queries/getSearchResultsSettings.generated';
import { logger } from '@utils';
import { ISlugItem } from '@contentful';
import { GetInteractiveMapDocument } from '@graphql/queries/getInteractiveMap.generated';
import { GetHeroVideoDocument } from '@graphql/queries/getHeroVideo.generated';
import { GetBlogGridDocument } from '@graphql/queries/getBlogGrid.generated';
import { Page } from '@graphql/types';
import { GetHeroTitleDocument } from '@graphql/queries/getHeroTitle.generated';
import { filterDraftItems } from '@utils';


const contentTypes: Record<string, DocumentNode> = {
  Text: GetTextContentDocument,
  TextOnly2Column: GetText2ColumnDocument,
  Video: GetVideoDocument,
  Image: GetImageDocument,
  TextImage2Column: GetTextImage2ColumnDocument,
  HeroImage: GetHeroImageDocument,
  CardGrid: GetCardGridDocument,
  HeroCarousel: GetHeroCarouselDocument,
  Accordion: GetAccordionDocument,
  Header: GetHeaderDocument,
  Footer: GetFooterDocument,
  JobSearchBox: GetJobSearchBoxDocument,
  SearchResultsSettings: GetJobSearchResultsSettingsDocument,
  InteractiveMap: GetInteractiveMapDocument,
  HeroVideo: GetHeroVideoDocument,
  HeroTitle: GetHeroTitleDocument,
  BlogGrid: GetBlogGridDocument
};

const contentResponses: Record<string, string> = {
  Text: 'text',
  TextOnly2Column: 'textOnly2Column',
  Video: 'video',
  Image: 'image',
  TextImage2Column: 'textImage2Column',
  CardGrid: 'cardGrid',
  HeroImage: 'heroImage',
  HeroCarousel: 'heroCarousel',
  Accordion: 'accordion',
  Header: 'header',
  Footer: 'footer',
  JobSearchBox: 'jobSearchBox',
  SearchResultsSettings: 'searchResultsSettings',
  InteractiveMap: 'interactiveMap',
  HeroVideo: 'heroVideo',
  HeroTitle: 'heroTitle',
  BlogGrid: 'blogGrid'
};

export const transformLinkUrl = (objectWithLink: any, linkName: string, slugs: ISlugItem[]) => {
  if (!objectWithLink) return null;
  const attribute = objectWithLink[linkName];
  if (!attribute) return null;
  if (!attribute.linkDestination) return attribute;
  if (attribute.linkDestination.slug === '/') return attribute;
  const fullSlug = slugs
    .find(({ params: { slug } }: ISlugItem) => slug[slug.length - 1] === attribute.linkDestination.slug);
  return {
    ...attribute,
    linkDestination: {
      slug: fullSlug ? `/${fullSlug.params.slug.join('/')}` : '/404'
    }
  };
};

export const transformPageSlug = (page: Page, slugs: ISlugItem[]) => {
  if (!page) return null;
  const { slug: pageSlug } = page;
  if (pageSlug === '/') return page;
  const fullSlug = slugs
    .find(({ params: { slug } }: ISlugItem) => slug[slug.length - 1] === pageSlug);
  return {
    ...page,
    slug: fullSlug ? `/${fullSlug.params.slug.join('/')}` : '/404'
  };
};

const transformers: Record<string, Function> = {
  Text: (queryResult: any, slugs: ISlugItem[]) => {
    const button = transformLinkUrl(queryResult, 'button', slugs);
    return {
      ...queryResult,
      button
    };
  },
  CardGrid: (queryResult: any, slugs: ISlugItem[]) => {
    const cards = filterDraftItems(queryResult.cardsCollection.items);
    const transformedCards = cards.map((card: any) => {
      const link = card.link;
      const transformedLink = transformLinkUrl({ link }, 'link', slugs);
      return {
        ...card,
        link: transformedLink
      };
    });

    return {
      ...queryResult,
      cardsCollection: {
        items: transformedCards
      }
    };
  },
  TextOnly2Column: (queryResult: any, slugs: ISlugItem[]) => {
    const { textLeft, textRight } = queryResult;
    const leftButton = transformLinkUrl(textLeft, 'button', slugs);
    const rightButton = transformLinkUrl(textRight, 'button', slugs);
    return {
      ...queryResult,
      textLeft: {
        ...textLeft,
        button: leftButton
      },
      textRight: {
        ...textRight,
        button: rightButton
      }
    };
  },
  TextImage2Column: (queryResult: any, slugs: ISlugItem[]) => {
    const { text } = queryResult;
    const button = transformLinkUrl(text, 'button', slugs);
    return {
      ...queryResult,
      text: {
        ...text,
        button,
      }
    };
  },
  HeroImage: (queryResult: any, slugs: ISlugItem[]) => {
    const button = transformLinkUrl(queryResult, 'button', slugs);
    return {
      ...queryResult,
      button
    };
  },
  HeroCarousel: (queryResult: any, slugs: ISlugItem[]) => {
    const slides = filterDraftItems(queryResult.slidesCollection.items);
    const transformedSlides = slides.map((slide: any) => {
      const button = transformLinkUrl(slide, 'button', slugs);
      return {
        ...slide,
        button
      };
    });

    return {
      ...queryResult,
      slidesCollection: {
        items: transformedSlides
      }
    };
  },
  Footer: (queryResult: any, slugs: ISlugItem[]) => {
    const footerLinks = filterDraftItems(queryResult.footerLinksCollection.items);
    const transformedFooterLinks = footerLinks.map((footerLink: any) => {
      return transformLinkUrl({ footerLink }, 'footerLink', slugs);
    });

    return {
      ...queryResult,
      footerLinksCollection: {
        items: transformedFooterLinks
      }
    };
  },
  HeroVideo: (queryResult: any, slugs: ISlugItem[]) => {
    const button = transformLinkUrl(queryResult, 'button', slugs);
    return {
      ...queryResult,
      button
    };
  },
  HeroTitle: (queryResult: any, slugs: ISlugItem[]) => {
    const button = transformLinkUrl(queryResult, 'button', slugs);
    return {
      ...queryResult,
      button
    };
  },
  BlogGrid: (queryResult: any, slugs: ISlugItem[]) => {
    const blogCards = filterDraftItems(queryResult.cardsCollection.items);
    const transformedCards = blogCards.map((card: any) => {
      const page = transformPageSlug(card.page, slugs);
      return {
        ...card,
        page
      };
    });

    return {
      ...queryResult,
      cardsCollection: {
        items: transformedCards
      }
    };
  },
  JobSearchBox: (queryResult: any, slugs: ISlugItem[]) => {
    const searchRedirect = transformPageSlug(queryResult.searchRedirect, slugs);
    return {
      ...queryResult,
      searchRedirect
    };
  },
};

export async function getData(
  client: ApolloClient<InMemoryCache>,
  id: string,
  preview: boolean,
  type: string,
  slugs: ISlugItem[],
  env?: string,
): Promise<IDataResponse> {
  const query = contentTypes[type];
  if (!query) {
    logger.error(`[ContentBlocks] Unknown component request ${type}`);
    return {
      queryResult: null
    };
  }

  const { data, error } = await safeQuery(client, query, {
    id: id,
    preview
  }, env);

  if (error || !data) {
    return {
      queryResult: null,
      error
    };
  }

  const queryResult = data[contentResponses[type]];

  const transformer = transformers[type];
  if (transformer) {
    return {
      queryResult: transformer(queryResult, slugs)
    };
  }

  return {
    queryResult
  };
}

export async function getContentBlocks(
  client: ApolloClient<InMemoryCache>,
  items: any,
  preview: boolean,
  slugs: ISlugItem[],
  env?: string,
): Promise<IContentBlocksResponse> {
  let blockError: string | null = null;
  const blocks: ContentBlock[] = await Promise.all(
    items
      .filter((item: any) => !!item)
      .map(async ({ type, sys }: any) => {
        if (!sys) {
          return null;
        }

        const { queryResult, error } = await getData(client, sys.id, preview, type, slugs, env);

        if (error) {
          blockError = error;
        }

        return queryResult;
      })
      .filter((item: any) => !!item)
  );

  return {
    blocks,
    error: blockError
  };
}
