import { UTM_KEYS } from '@constants';
import { isEmpty } from 'lodash';

import { ReturnGetItems, ReturnValueUseUtmTags } from './interfaces';


const useUtmTags = (): ReturnValueUseUtmTags => {
  const utmParamsRegExp = new RegExp('(^utm_.*)|source');
  const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')();

  const getSessionStorageItems = (): ReturnGetItems => {
    if (!isBrowser || isEmpty(sessionStorage)) {
      return false;
    }

    return Object.keys(sessionStorage).reduce((acc, key) => {
      if (!utmParamsRegExp.test(key)) {
        return acc;
      }

      return { ...acc, [key]: sessionStorage.getItem(key) };
    }, {});
  };

  const setSessionStorageItems = (): boolean => {
    if (!isBrowser) {
      return false;
    }

    const { search } = window.location;
    const urlParams = Object.fromEntries(new URLSearchParams(search));

    Object.entries(urlParams)
      .filter(([key]) => utmParamsRegExp.test(key))
      .filter(([key]) => UTM_KEYS.includes(key))
      .forEach(([key, value]) => sessionStorage.setItem(key, value));

    return true;
  };

  const getItems = () => {
    if (getSessionStorageItems()) {
      return getSessionStorageItems();
    }
    return false;
  };

  return {
    getItems,
    getSessionStorageItems,
    setSessionStorageItems,
  };
};

export default useUtmTags;
