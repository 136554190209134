/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetJobSearchResultsSettingsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetJobSearchResultsSettingsQuery = { __typename?: 'Query', searchResultsSettings?: { __typename?: 'SearchResultsSettings', noResultsFoundText?: string | null, loadMoreResultsButtonText?: string | null, moreDetailsButtonText?: string | null } | null };


export const GetJobSearchResultsSettingsDocument = gql`
    query getJobSearchResultsSettings($id: String!, $preview: Boolean) {
  searchResultsSettings(id: $id, preview: $preview) {
    noResultsFoundText
    loadMoreResultsButtonText
    moreDetailsButtonText
  }
}
    `;
export type GetJobSearchResultsSettingsQueryResult = Apollo.QueryResult<GetJobSearchResultsSettingsQuery, GetJobSearchResultsSettingsQueryVariables>;