import React from 'react';
import { Icon } from '@components';
import { HeroImage as IHeroImage } from '@graphql/types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './heroImage.module.scss';


type HeroImageProps = {
  hero: IHeroImage;
  buttonComponent?: JSX.Element;
  imageComponent?: JSX.Element;
  mainHeader?: boolean;
  backgroundColor?: string;
}

export const HeroImage = (
  {
    hero: {
      title,
      subtitle,
      textIconPrefixClass,
      button,
      image,
      imageHeight
    },
    buttonComponent,
    imageComponent,
    mainHeader,
    backgroundColor = 'black'
  }: HeroImageProps) => {
  const size = imageHeight && imageHeight.replace(/\s/g, '_').toLowerCase();

  return (
    <div data-testid="heroImage"
      className={[
        styles.wrapper,
        styles[size || ''],
        styles[`hero--${backgroundColor}`],
      ].join(' ')}>
      {!!image && <div className={styles.image}>
        {imageComponent}
      </div>}
      <div className={styles.copy}>
        <div className={styles.copy__heading}>
          {title && (mainHeader ? (
            <h1>{title}</h1>
          ) : (
            <h2>{title}</h2>
          ))}
          {subtitle && (<p className={[styles.copy__subtitle].join(' ')}>
            {textIconPrefixClass && <Icon icon={textIconPrefixClass as IconProp} />}
            {subtitle}
          </p>
          )}
        </div>
        {button && (
          <div className={styles.copy__cta}>
            <div>{buttonComponent}</div>
          </div>
        )}
      </div>
    </div>
  );
};
