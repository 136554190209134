import { useState } from 'react';
import { Button, Loading } from 'components';
import styles from './loadMoreButton.module.scss';
import { LOAD_MORE_BUTTON_LABEL_DEFAULT } from '@constants';


type LoadMoreButtonProps = {
  onLoadMore: () => void;
  label?: string | null;
};

const LoadMoreButton = ({ label, onLoadMore }: LoadMoreButtonProps) => {
  const [ isLoadingMore, setIsLoadingMore ] = useState<boolean>(false);

  const handleLoadMore = async () => {
    if (typeof onLoadMore === 'function') {
      setIsLoadingMore(true);
      await onLoadMore();
      setIsLoadingMore(false);
    }
  };

  return (
    <div className={styles.container}>
      {isLoadingMore ? (
        <Loading />
      ) : (
        <Button
          onClick={handleLoadMore}
          label={label || LOAD_MORE_BUTTON_LABEL_DEFAULT}
          data-testid="load-more-button"
        />
      )}
    </div>
  );
};

export default LoadMoreButton;
