/* eslint-disable */
import * as Types from 'graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetErrorPageQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetErrorPageQuery = { __typename?: 'Query', errorPage?: { __typename?: 'ErrorPage', name?: string | null, headerSys?: { __typename?: 'Header', type: 'Header', sys: { __typename?: 'Sys', id: string } } | null, contentSys?: { __typename?: 'ErrorPageContentCollection', items: Array<{ __typename?: 'Accordion', type: 'Accordion', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'CardGrid', type: 'CardGrid' } | { __typename?: 'HeroCarousel', type: 'HeroCarousel', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'HeroImage', type: 'HeroImage', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'HeroText', type: 'HeroText', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'HeroTitle', type: 'HeroTitle', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'HeroVideo', type: 'HeroVideo', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'Image', type: 'Image', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'InteractiveMap', type: 'InteractiveMap', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'Text', type: 'Text', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'TextImage2Column', type: 'TextImage2Column', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'TextOnly2Column', type: 'TextOnly2Column', sys: { __typename?: 'Sys', id: string } } | { __typename?: 'Video', type: 'Video', sys: { __typename?: 'Sys', id: string } } | null> } | null, footerSys?: { __typename?: 'Footer', type: 'Footer', sys: { __typename?: 'Sys', id: string } } | null } | null };


export const GetErrorPageDocument = gql`
    query getErrorPage($id: String!, $preview: Boolean) {
  errorPage(id: $id, preview: $preview) {
    name
    headerSys: header {
      type: __typename
      sys {
        id
      }
    }
    contentSys: contentCollection {
      items {
        type: __typename
        ... on Text {
          sys {
            id
          }
        }
        ... on Accordion {
          sys {
            id
          }
        }
        ... on HeroCarousel {
          sys {
            id
          }
        }
        ... on HeroImage {
          sys {
            id
          }
        }
        ... on HeroText {
          sys {
            id
          }
        }
        ... on Image {
          sys {
            id
          }
        }
        ... on TextOnly2Column {
          sys {
            id
          }
        }
        ... on Video {
          sys {
            id
          }
        }
        ... on TextImage2Column {
          sys {
            id
          }
        }
        ... on InteractiveMap {
          sys {
            id
          }
        }
        ... on HeroVideo {
          type: __typename
          sys {
            id
          }
        }
        ... on HeroTitle {
          type: __typename
          sys {
            id
          }
        }
      }
    }
    footerSys: footer {
      type: __typename
      sys {
        id
      }
    }
  }
}
    `;
export type GetErrorPageQueryResult = Apollo.QueryResult<GetErrorPageQuery, GetErrorPageQueryVariables>;