import React from 'react';
import { convertDateTime, convertTime } from '@utils';
import { IDateRangeProps } from './interfaces';
import styles from './dateRange.module.scss';


export const DateRange = ({ startDate, endDate }: IDateRangeProps) => {
  const startDateLocal =  convertDateTime(startDate, {
    month: 'short',
    hour: '2-digit',
    minute: '2-digit'
  });

  let endDateLocal = convertDateTime(endDate, {
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  });

  if (startDateLocal.substr(0, 8) === endDateLocal.substr(0, 8)) { // check if only hour should be different
    endDateLocal = convertTime(endDate, {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    });

    return (
      <div className={styles.dateRange}>
        <span>{startDateLocal}</span>
        <span className={styles.divider}> - </span>
        <span>{endDateLocal}</span>
      </div>
    );
  }

  return (
    <div className={styles.dateRange}>
      <div className={styles.dateRange__wrapper}>
        <span>{startDateLocal}</span>
        <span className={styles.divider}> - </span>
        <span>{endDateLocal}</span>
      </div>
    </div>
  );
};